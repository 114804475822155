import React, { useCallback, useEffect, useState, useContext } from "react";
import Button from "../common/button/button";
import Divider from "../common/divider/divider";
import styles from "./filter-mobile-categories.module.scss";
import { StaticContext } from "../../context/static-context/static-context";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../pages/inventory/InventoryPage";
import BadgeMobile from "../common/badge-mobile/badge-mobile";
import Link from "next/link";

interface FilterMobileCategorieChildrensProps {
	ele?: any;
	addArray?: any;
	deleteArray?: any;
	array?: any;
	seo?: boolean;
}

function FilterMobileCategorieChildrens({
	ele,
	addArray,
	deleteArray,
	array,
	seo = false,
}: FilterMobileCategorieChildrensProps): JSX.Element {
	const contextData = useContext(StaticContext);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const [all, setAll] = useState<boolean>(true);

	const renderShildCategories = (id: string, ele: any) => {
		let result = [];
		for (let i in contextData.categories) {
			if (contextData.categories[i].parent?.id === id) {
				result.push(contextData.categories[i]);
			}
		}

		return result;
	};

	useEffect(() => {
		setAll(queryParameters.category.join(" ").includes(ele?.id));
	}, []);

	return (
		<div>
			<div
				className={styles.childrenConteinerAll}
				onClick={() => {
					!seo ? setAll(!all) : console.log("test");
				}}
			>
				{!seo ? (
					<BadgeMobile
						title={`All ${ele.name || ele.title}`}
						isVisible={true}
						addArray={addArray}
						deleteArray={deleteArray}
						ele={ele}
						id={ele.id}
						array={array}
					/>
				) : (
					<div className={styles.linkItem}>
						<Link href={`/inventory/categories/${ele.title}`}>
							<a className={styles.link}>{`All ${ele.name || ele.title}`}</a>
						</Link>
					</div>
				)}
			</div>
			<div className={styles.childrenConteiner}>
				{true &&
					renderShildCategories(ele.id, ele).map((e) => (
						<div key={e.id}>
							{!seo ? (
								<BadgeMobile
									title={`${e.title}`}
									isVisible={true}
									addArray={addArray}
									deleteArray={deleteArray}
									ele={e}
									id={e.id}
									array={array}
								/>
							) : (
								<div className={styles.linkItem}>
									<Link href={`/inventory/categories/${e.title}`}>
										<a className={styles.link}>{e.title}</a>
									</Link>
								</div>
							)}
						</div>
					))}
			</div>
		</div>
	);
}

export default FilterMobileCategorieChildrens;
