import React, { useState, ReactNode, useEffect } from "react";
import styles from "./form-container.module.scss";
import { UseValidation } from "../../../hooks/useValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../../common/button/button";
import gql from "graphql-tag";
import { useScrollLock } from "../../../hooks/useScroll";
import { useForm } from "react-hook-form";
import { ReCaptcha } from "./ReCaptcha";

export const CREATE_ITEM = gql`
	mutation CreateItem($itemData: CreateItemDTO!) {
		createItem(itemData: $itemData) {
			data {
				availableForExport
				availableLoadsQuantity
				description
				retailer
				estimatedRetailValue
				wholeSalesValue
				id
				manifestFile
				gallery {
					path
					isActualImage
				}
				inventoryLoadCondition
				inventoryLoadSize
				inventoryLoadFOB {
					city
					state
					street
					zipCode
				}
				inventoryLoadCategory
				deliveryType
				listingStatus
				cost
				estimatedPieceCount
				title
				canBeSoldByThirdParty
				palletSpace
				notesField
				leadTimeField
				palletHeight
			}
			error {
				code
			}
			success
		}
	}
`;

interface FormContainerPoprs {
	title?: string;
	subTitle?: string;
	children?: ReactNode;
	submitTitle?: string;
	formInitialValues?: any;
	className?: string;
	formHeaderClassName?: string;
	formContentClassName?: string;
	showSubmitButton?: boolean;
	onResetContext?: ({}: any) => void;
	closeForm?: any;
	onSubmit?: () => void;
	submitted?: boolean;
	getInTouchPopup?: boolean;
	valideForm?: boolean;
}

export const FormContext = React.createContext({
	form: {},
	errors: {},
	handleFormChange: (e: any, inputType: string) => {},
});

export default function FormContainer({
	title,
	subTitle,
	submitTitle = "Submit Request",
	children,
	formInitialValues = {},
	className,
	formHeaderClassName,
	formContentClassName = "",
	showSubmitButton = true,
	onResetContext,
	closeForm,
	onSubmit,
	submitted = false,
	getInTouchPopup = false,
	valideForm,
}: FormContainerPoprs): JSX.Element {
	const { errors, validate } = UseValidation();
	const [formValues, setFormValues] = useState(formInitialValues);
	const [formErrors, setFormErrors] = useState({});
	const { lockScroll, unlockScroll } = useScrollLock();

	// useEffect(() => {
	// 	onResetContext && onResetContext({ setFormValues, setFormErrors });
	// }, [onResetContext]);

	// console.log("FormContainer formValues :: ", formValues);
	// console.log("FormContainer formErrors :: ", formErrors);

	useEffect(() => {
		setFormErrors(errors);
	}, [errors]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		onSubmit && onSubmit();
	};

	const handleFormChange = (event: any, inputType: string) => {
		const { name, value } = event.target;

		validate(name, value, inputType);

		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	// const onVerifyCaptcha = (token: any) => {
	// 	setValue("captchaToken", token);
	// };

	// useEffect(() => {
	// 	register("captchaToken", { required: true });
	// });

	const onVerifyCaptcha = (token: any) => {
		console.log("captchaToken", token);
	};

	return (
		<FormContext.Provider
			value={{
				form: formValues,
				errors: formErrors,
				handleFormChange,
			}}
		>
			<div className={`${styles.formContainer} ${className ? className : ""}`}>
				<div
					className={`${styles.formHeader} ${
						formHeaderClassName ? formHeaderClassName : ""
					}`}
				>
					<div
						className={`${styles.titleWrapper}`}
						onClick={() => (closeForm(), unlockScroll())}
					>
						<FontAwesomeIcon className={styles.closeBtn} icon={faTimes} />
						{title && <h3>{title}</h3>}
						{subTitle && <p>{subTitle}</p>}
					</div>
				</div>
				<div className={[styles.formContent, formContentClassName].join(" ")}>
					<form onSubmit={handleSubmit}>
						{children}
						{showSubmitButton && (
							<div className={styles.submitBtnSucces}>
								<Button
									type="submit"
									className={styles.submitBtn}
									title={submitTitle}
									onClick={handleSubmit}
									disabled={submitted}
								>
									{getInTouchPopup && (
										<ReCaptcha onVerifyCaptcha={onVerifyCaptcha} />
									)}
								</Button>
								{submitted && (
									<Button
										className={styles.submitBtnSuccesMessage}
										onClick={() => (closeForm(), unlockScroll())}
									>
										Thank you! Your Message has been delivered.
									</Button>
								)}
							</div>
						)}
					</form>
				</div>
			</div>
		</FormContext.Provider>
	);
}
