import React from "react";
import styles from "./filter-item.module.scss";
import Divider from "../common/divider/divider";
export interface FilterItemProps {
	title?: string;
	mobile?: boolean;
	values: {
		title: string;
		value: string;
	}[];
	onChangeValue(value: string[]): void;
	selectedValues: string[];
}

function FilterCheckboxes({
	title,
	mobile = false,
	onChangeValue,
	selectedValues,
	values,
}: FilterItemProps) {
	return (
		<>
			{!mobile && (
				<div
					className={`${styles.filterItem} ${
						mobile ? styles.filterItemMobile : ""
					}`}
				>
					<div className={styles.checkboxesconteiner}>
						<div className={styles.checkboxTitle}>
							{title}
							{mobile && <Divider />}
						</div>
						<div className={styles.checkboxes}>
							{values.map((val, index) => {
								const isSelected = !!selectedValues.find((selectedValue) => {
									return selectedValue == val.value;
								});

								return (
									<label key={index} className={styles.checkboxesitem}>
										<input
											type="checkbox"
											name={`name ${val.value}`}
											value={val.value}
											onChange={() => {
												if (isSelected) {
													onChangeValue(
														selectedValues.filter((selectedValue) => {
															return val.value != selectedValue;
														}),
													);
												} else {
													onChangeValue([...selectedValues, val.value]);
												}
											}}
											checked={isSelected}
										/>{" "}
										{val.title}
									</label>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default FilterCheckboxes;
