import React, { ReactNode, useContext, useEffect, useState } from "react";
import styles from "./filter-modal-state.module.scss";
import { useScrollLock } from "../../../hooks/useScroll";
import { StaticContext } from "../../../context/static-context/static-context";
import BadgeMobile from "../../common/badge-mobile/badge-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import IconComponent from "../../common/icon/icon";
import { IconData } from "../../common/icon/icon-data";

interface FilterModalStateProps {
	chekedToggelState?: any;
	statesOpen?: any;
	addArrayState?: any;
	deleteArrayState?: any;
	arrayState?: any;
	onFormSubmit?: any;
}

export default function FilterModalState({
	chekedToggelState,
	statesOpen,
	addArrayState,
	deleteArrayState,
	arrayState,
	onFormSubmit,
}: FilterModalStateProps): JSX.Element {
	const { lockScroll, unlockScroll } = useScrollLock();
	const [searchArray, setSearchArray] = useState<string[]>([]);
	const [searchArrayFilter, setSearchArrayFilter] = useState<any[]>([]);
	const contextData = useContext(StaticContext);

	useEffect(() => {
		let result: any = [];
		result = [...contextData.states];

		setSearchArrayFilter(result);
	}, [contextData]);

	useEffect(() => {
		statesOpen ? lockScroll() : unlockScroll();
	}, [statesOpen, lockScroll, unlockScroll]);

	const addArray = (e: any) => {
		const arrayID = arrayState.map((ele: any) => (ele = ele.id));
		if (arrayID.includes(e.id)) return;
		addArrayState(e);
	};

	const deleteArray = (e: any) => {
		deleteArrayState(e);
	};

	return (
		<div className={styles.statesWindowsConteiner}>
			<div className={styles.statesWindowsModal}>
				<div className={styles.statesWindowsModalHeader}>
					<p>Select State</p>
					<div onClick={() => (chekedToggelState(), unlockScroll())}>
						<FontAwesomeIcon className={styles.closeBtn} icon={faTimes} />
					</div>
				</div>
				<Scrollbars>
					<div className={styles.badgeContainer}>
						{searchArrayFilter.map((ele: any) => (
							<BadgeMobile
								key={ele?.id}
								title={`${ele?.name || ele?.title}`}
								isVisible={true}
								addArray={addArray}
								deleteArray={deleteArray}
								ele={ele}
								id={ele?.id}
								array={arrayState}
							/>
						))}
					</div>
				</Scrollbars>
				<div
					className={styles.statesWindowsModalFooter}
					onClick={() => (chekedToggelState(), unlockScroll())}
				>
					<div onClick={onFormSubmit} className={styles.onFormSubmit}>
						<IconComponent
							className={styles.iconSearch}
							name={IconData.SEARCH}
						/>
						<div>Show Results</div>
					</div>
				</div>
			</div>
		</div>
	);
}
