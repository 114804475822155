import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import React from "react";
import styles from "./form-container.module.scss";

const CaptchaButton = ({ onVerifyCaptcha }: { onVerifyCaptcha: any }) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const clickHandler = async () => {
		if (!executeRecaptcha) {
			return;
		}

		const token = await executeRecaptcha("name");
		onVerifyCaptcha(token);
	};

	return (
		<button onClick={clickHandler} className={styles.reCaptchaV3}>
			X
		</button>
	);
};

export const ReCaptcha = ({ onVerifyCaptcha }: { onVerifyCaptcha: any }) => (
	<GoogleReCaptchaProvider reCaptchaKey="6LdPTSIfAAAAAJJOYO1n-U7Qdn0Y7yj0QSD6nckJ">
		<CaptchaButton onVerifyCaptcha={onVerifyCaptcha} />
	</GoogleReCaptchaProvider>
);
