import React, {
	Children,
	ReactNode,
	useCallback,
	useEffect,
	useState,
	useContext,
} from "react";
import styles from ".././filter-item.module.scss";
import Badge from "../../common/badge/badge";
import useHover from "../../../hooks/useHover";
import { StaticContext } from "../../../context/static-context/static-context";
import { useScrollLock } from "../../../hooks/useScroll";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../../pages/inventory/InventoryPage";
import nextId from "react-id-generator";
import { deliveryTypes } from "../../translations/delivery-types";
import FilterModalDelivery from "./filter-modal-delivery";

export interface FilterDeliveryTypeProps
	extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	handleChange?: (value: string) => void;
	handleValueDeleteState?: (value: string) => void;
	onFormSubmit?: any;
}

function FilterDeliveryType({
	title,
	handleChange,
	handleValueDeleteState,
	onFormSubmit,
}: FilterDeliveryTypeProps) {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [hoverRef, isHovered] = useHover<HTMLDivElement>();
	const [hoverRefSubmenu, isHoveredSubmenu] = useHover<HTMLDivElement>();
	const [searchArray, setSearchArray] = useState<string[]>([]);
	const [searchArrayFilter, setSearchArrayFilter] = useState<any[]>([]);
	const { lockScroll, unlockScroll } = useScrollLock();
	const contextData = useContext(StaticContext);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const [statesOpen, setStatesOpen] = useState<boolean>(false);

	const addArray = (e: any) => {
		handleChange && handleChange(e.value);
		const arrayID = searchArray.map((ele: any) => (ele = ele.id));
		if (arrayID.includes(e.id)) return;
		setSearchArray([e]);
	};

	const deleteArray = (e: any) => {
		handleChange && handleChange("");
		let filter = searchArray.filter((ele: any) => ele.id !== e.id);
		setSearchArray(filter);
	};

	useEffect(() => {
		let result: any = [];
		result = deliveryTypes.filter(
			(ele) => ele.value === queryParameters.deliveryType,
		);

		setSearchArray(result);
	}, [router]);

	const chekedToggelState = () => {
		setStatesOpen(false);
	};

	return (
		<>
			<div className={styles.filterItem}>
				<div className={styles.title} onClick={() => setStatesOpen(true)}>
					{title}
				</div>

				<div className={styles.chekedCategoti}>
					{searchArray.map((ele: any) => (
						<Badge
							key={nextId()}
							title={ele?.title}
							selfClosing={true}
							onClick={() => deleteArray(ele)}
						/>
					))}
				</div>

				{statesOpen && (
					<FilterModalDelivery
						chekedToggelState={chekedToggelState}
						statesOpen={statesOpen}
						addArrayState={addArray}
						deleteArrayState={deleteArray}
						arrayState={searchArray}
						onFormSubmit={onFormSubmit}
					/>
				)}
			</div>
		</>
	);
}

export default FilterDeliveryType;
