import React, { useCallback, useEffect, useState, useContext } from "react";
import styles from ".././filter-item.module.scss";
import Badge from "../../common/badge/badge";
import { StaticContext } from "../../../context/static-context/static-context";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../../pages/inventory/InventoryPage";
import FilterModalCategories from "./filter-modal-categories";

interface SearchObject {
	arrayCategoriesChildren: any[];
}

let searchObject: SearchObject = {
	arrayCategoriesChildren: [],
};

export interface FilterItemProps extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	mapArray?: any;
	typeFilter?: "categories";
	category?: boolean;
	other?: boolean;
	categoriesSearch?: boolean;
	handleInventoryLoadCategoryChange?: (value: string) => void;
	handleDeleteCategory?: (value: string[]) => void;
	onFormSubmit?: any;
}

function FilterCategories({
	title = "Title",
	mapArray: mapArray,
	typeFilter,
	category: categori = false,
	other = false,
	categoriesSearch = false,
	handleInventoryLoadCategoryChange,
	handleDeleteCategory,
	onFormSubmit,
}: FilterItemProps) {
	const [array, setArray] = useState<any[]>([]);
	const [resultArray, setResultArray] = useState<any[]>([]);
	const contextData = useContext(StaticContext);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const [statesOpen, setStatesOpen] = useState<boolean>(false);

	const deleteArray = (e: any) => {
		handleDeleteCategory && handleDeleteCategory(e.id);
		let result = array.filter((ele: any) => ele.id !== e.id);
		setArray(result);
	};

	const addArray = (e: any): void => {
		const result = array.map((ele: any) => (ele = ele.id));
		if (result.includes(e.id)) return;

		if (e.parent.id === "0e46c6e5-b0cc-4cfe-b3ff-08e7b9af7aa3") {
			handleInventoryLoadCategoryChange &&
				handleInventoryLoadCategoryChange(e.id);

			const child = contextData.categories
				.filter((cat) => !cat.retailerId)
				.filter((ele) => ele.parent?.id === e.id);
			const childId = child.map((ele: any) => (ele = ele.id));

			handleDeleteCategory && handleDeleteCategory(childId);
			return;
		}

		handleInventoryLoadCategoryChange &&
			handleInventoryLoadCategoryChange(e.id);
		setArray((prev: any) => [...prev, e]);
	};

	let result: any = [];

	useEffect(() => {
		queryParameters?.category.forEach((ele: any) => {
			result.push(contextData.getCategory(ele));
		});

		setArray(result);
	}, [router, contextData]);

	const chekedToggelState = () => {
		setStatesOpen(false);
	};

	return (
		<>
			<div className={styles.filterItem}>
				<div className={styles.title} onClick={() => setStatesOpen(true)}>
					{title}
				</div>

				<div className={styles.chekedCategoti}>
					{array.map((ele, index) => (
						<div key={ele?.id || index}>
							<Badge
								title={ele?.title}
								selfClosing={true}
								onClick={() => deleteArray(ele)}
								ele={ele}
							/>
						</div>
					))}
				</div>
			</div>

			{statesOpen && (
				<FilterModalCategories
					checkedToggleState={chekedToggelState}
					statesOpen={statesOpen}
					addArray={addArray}
					deleteArray={deleteArray}
					array={array}
					onFormSubmit={onFormSubmit}
				/>
			)}
		</>
	);
}

export default FilterCategories;
