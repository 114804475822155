import React, {
	Children,
	ReactNode,
	useCallback,
	useEffect,
	useState,
	useContext,
} from "react";
import styles from ".././filter-item.module.scss";
import Badge from "../../common/badge/badge";
import useHover from "../../../hooks/useHover";
import { StaticContext } from "../../../context/static-context/static-context";
import { useScrollLock } from "../../../hooks/useScroll";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../../pages/inventory/InventoryPage";
import nextId from "react-id-generator";
import FilterModalCondition from "./filter-modal-condition";

export interface FilterLoadConditionProps
	extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	handleConditionChange?: (value: string) => void;
	handleDeleteOfCondition?: (value: string[]) => void;
	onFormSubmit?: any;
}

function FilterLoadCondition({
	title,
	handleConditionChange,
	handleDeleteOfCondition,
	onFormSubmit,
}: FilterLoadConditionProps) {
	const [statesOpen, setStatesOpen] = useState<boolean>(false);

	const [searchArray, setSearchArray] = useState<string[]>([]);

	const contextData = useContext(StaticContext);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const addArray = (e: any) => {
		handleConditionChange && handleConditionChange(e.id);
		const arrayID = searchArray.map((ele: any) => (ele = ele.id));
		if (arrayID.includes(e.id)) return;
		setSearchArray((prev) => [...prev, e]);
	};

	const deleteArray = (e: any) => {
		handleDeleteOfCondition && handleDeleteOfCondition(e.id);
		let filter = searchArray.filter((ele: any) => ele.id !== e.id);
		setSearchArray(filter);
	};

	useEffect(() => {
		let result: any = contextData.vocabularyEntries.filter(
			(ele: any) => ele.id === queryParameters.loadCondition,
		);
		for (let i in queryParameters.loadCondition) {
			let target = contextData.vocabularyEntries.filter(
				(ele) => ele.id === queryParameters.loadCondition[i],
			);
			result.push(target[0]);
		}

		setSearchArray(result);
	}, [router, contextData.vocabularyEntries]);

	const chekedToggelState = () => {
		setStatesOpen(false);
	};

	return (
		<>
			<div className={styles.filterItem}>
				<div className={styles.title} onClick={() => setStatesOpen(true)}>
					{title}
				</div>

				<div className={styles.chekedCategoti}>
					{searchArray.map((ele: any) => (
						<Badge
							key={nextId()}
							title={ele?.title}
							selfClosing={true}
							onClick={() => deleteArray(ele)}
						/>
					))}
				</div>

				{statesOpen && (
					<FilterModalCondition
						chekedToggelState={chekedToggelState}
						statesOpen={statesOpen}
						addArrayState={addArray}
						deleteArrayState={deleteArray}
						arrayState={searchArray}
						onFormSubmit={onFormSubmit}
					/>
				)}
			</div>
		</>
	);
}

export default FilterLoadCondition;
