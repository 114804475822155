import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import React, { useRef } from "react";
import { useMutation } from "urql";
import Button, { ButtonColour } from "../button/button";
import {
	UploadFilesMutation,
	UploadFilesMutationVariables,
} from "./upload-manifest.graphql.module";
import styles from "./upload-manifest.module.scss";

interface UploadManifestProps {
	// TODO: extend props - onChange, onClick name, current value, selected...
	title?: string;
	manifestFile?: string;
	onFileUpload: (path: string) => void;
	removeFile?: () => void;
}

export const UPLOAD_FILES = gql`
	mutation uploadFiles($file: Upload!) {
		uploadFiles(file: $file) {
			success
			error {
				code
				message
				status
			}
			data {
				id
				path
			}
		}
	}
`;

export default function UploadManifest({
	title = "Upload Manifest",
	manifestFile,
	onFileUpload,
	removeFile,
}: UploadManifestProps) {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const fileServerURL = `${process.env.FILE_SERVER_DOMAIN}/`;

	const [uploadFilesMutationResult, uploadFilesMutation] = useMutation<
		UploadFilesMutation,
		UploadFilesMutationVariables
	>(UPLOAD_FILES);

	const onClick = () => fileInputRef.current?.click();

	const upload = async (files: FileList | null) => {
		if (!files?.length) return;

		const file = files.item(0);

		const resp = await uploadFilesMutation({ file });

		if (resp.error) {
			return alert("error!! something went wrong");
		}

		if (resp.data?.uploadFiles.data?.path) {
			onFileUpload(resp.data.uploadFiles.data?.path);
		}
	};

	return (
		<div className={styles.uploadManifest}>
			<input
				type="file"
				accept=".csv,.xlsx,.xls"
				onChange={(e) => upload(e.target.files)}
				ref={fileInputRef}
			/>
			<div className={styles.buttonWrapper} onClick={onClick}>
				<div className={styles.iconPlusWrapper}>
					<div className={styles.border}>
						<FontAwesomeIcon className={styles.iconPlus} icon={faPlus} />
					</div>
				</div>
				<Button color={ButtonColour.GREY} title={title} />
			</div>
			{manifestFile ? (
				<div className={styles.manifestFile}>
					<a href={fileServerURL + manifestFile} download>
						Download Manifest
					</a>
					<FontAwesomeIcon
						icon={faTimes}
						className={styles.iconTimes}
						onClick={(e) => {
							e.stopPropagation();
							fileInputRef.current!.value = "";
							removeFile && removeFile();
						}}
					/>
				</div>
			) : null}
		</div>
	);
}
