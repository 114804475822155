import React, { useEffect } from "react";
import Badge from "../common/badge/badge";
import { BadgeColour } from "../common/badge/badge";
import styles from "./price-info.module.scss";

export const numberWithCommas = (num: number) => {
	if (num === 0 || num === undefined) return undefined;
	if (num === null) return null;
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export interface PriceInfoProps {
	price: number;
	oldPrice?: number;
	save?: number;
	design?: "primary" | "secondary" | "mob";
	className?: string;
	effectAnimation?: boolean;
}

function PriceInfo({
	price,
	oldPrice,
	save,
	design = "primary",
	className,
	effectAnimation = false,
}: PriceInfoProps): JSX.Element {
	return (
		<div
			className={`
      ${styles.priceInfoBase}
      ${className ? className : ""}
      ${design === "primary" ? styles.priceInfoPrimary : ""}
      ${design === "secondary" ? styles.priceInfoSecondary : ""}
	  ${design === "mob" ? styles.priceInfoMob : ""}
    `}
		>
			<div className={styles.price}>
				{`$${numberWithCommas(price) || 0}`}{" "}
				{!!oldPrice && oldPrice !== price && (
					<span className={styles.oldPrice}>{`$${numberWithCommas(
						oldPrice,
					)}`}</span>
				)}
			</div>
			{!!save && save !== 0 && (
				<div
					className={`${styles.saleContainer} ${
						effectAnimation ? styles.isError : ""
					}`}
				>
					<Badge
						className={styles.save}
						title={`Save ${save} %`}
						color={BadgeColour.RED}
					/>
				</div>
			)}
		</div>
	);
}

export default PriceInfo;
