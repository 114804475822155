import React, { useEffect, useState } from "react";
import { Category_FragmentFragment } from "../../../context/static-context/static-context.query.graphql.module";
import styles from "./badge.module.scss";

export interface BadgeMobileProps {
	title: string;
	isVisible: boolean;
	addArray?: any;
	ele?: any;
	deleteArray?: any;
	id?: string;
	array?: any;
	all?: boolean;
	type?: boolean;
}

function BadgeMobile({
	title,
	isVisible,
	addArray,
	ele,
	deleteArray,
	id,
	array,
	type = false,
}: BadgeMobileProps): JSX.Element {
	const [toggle, setToggle] = useState<boolean>(false);

	useEffect(() => {
		let result = array.map((ele: any) => (ele = ele?.id));

		result.includes(id) ? setToggle(true) : setToggle(false);
	}, [array, id]);

	const click = (ele: any) => {
		setToggle(!toggle), !toggle ? addArray(ele) : deleteArray(ele);
	};

	return (
		<>
			{isVisible && (
				<div>
					<div
						className={`${styles.badgeMobile} ${toggle ? styles.active : ""}`}
						onClick={() => click(ele)}
					>
						{true && (
							<div
								className={`${styles.closeBadge} ${
									toggle ? styles.active : ""
								}`}
							>
								{" "}
							</div>
						)}
						{title}
					</div>
				</div>
			)}
		</>
	);
}

export default BadgeMobile;
