import React, { useState } from "react";
import styles from "./banner.module.scss";
import Image from "next/image";

interface BannerProps {
	header: string;
	inventory?: boolean;
	type?: "inventory" | "third_party";
	text?: string;
}

function Banner({ header, inventory = false, text }: BannerProps): JSX.Element {
	return (
		<div className={styles.banner}>
			<div className={`container ${styles.bannerContainer}`}>
				<div className={styles.bannerContent}>
					<h2>{header}</h2>
					<p className={styles.description}>{text}</p>
				</div>
			</div>
			<Image
				src={"/img/banner.jpg"}
				layout="fill"
				blurDataURL={"/img/banner.jpg"}
				loading="lazy"
				placeholder="blur"
				objectFit="cover"
				alt={"hero"}
				className={styles.image}
			/>
			<div className={styles.background}></div>
		</div>
	);
}

export default Banner;
