import React from "react";
import styles from "./divider.module.scss";

function Divider({ className }: { className?: string }): JSX.Element {
	return (
		<span className={`${styles.divider} ${className ? className : ""}`}></span>
	);
}

export default Divider;
