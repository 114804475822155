import { CircularProgress } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useQuery } from "urql";
import {
	ItemDetail_GetItemQuery,
	ItemDetail_GetItemQueryVariables,
} from "../../../pages/inventory/[id].graphql.module";
import { GET_ITEM } from "../../../pages/inventory/[id]";
import NewLoadForm, { ValuesToSet } from "../forms/new-load-form/new-load-form";

type EditFormProps = {
	id: string;
	onClose: () => void;
};

const EditForm: FC<EditFormProps> = ({ id, onClose }) => {
	const [valuesToEdit, setValuesToEdit] = useState<any>();

	const [itemDetailResult, getItemDetail] = useQuery<
		ItemDetail_GetItemQuery,
		ItemDetail_GetItemQueryVariables
	>({
		query: GET_ITEM,
		requestPolicy: "cache-and-network",
		variables: {
			id: id as string,
		},
	});

	useEffect(() => {
		setValuesToEdit(itemDetailResult.data?.getItem.data);
	}, [itemDetailResult.data]);

	if (!valuesToEdit) {
		return (
			<div style={{ position: "fixed", top: "50%", left: "50%" }}>
				<CircularProgress />
			</div>
		);
	}

	return <NewLoadForm values={valuesToEdit} closeForm={onClose} />;
};

export default EditForm;
