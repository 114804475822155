import { style } from "@mui/system";
import * as React from "react";
import styles from "./multiple-select-box.module.scss";

interface IMultipleSelectBoxProps {
	handleChange(value: string): void;
	title?: string;
	values: {
		value: string;
		title: string;
	}[];
	chosenValues: string[];
	handleValueDelete: (value: string) => void;
	error?: boolean;
	placeholder?: string;
	label?: string;
}

export function MultipleSelectBox({
	handleChange,
	title,
	values,
	chosenValues,
	handleValueDelete,
	error,
	placeholder,
	label,
}: IMultipleSelectBoxProps) {
	const getSelectBoxValues = () => {
		return values
			.filter((value) => {
				return !chosenValues.find((chosenVal: string) => {
					return chosenVal == value.value;
				});
			})
			.map((value) => {
				return (
					<option key={value.value} value={value.value}>
						{value.title}
					</option>
				);
			});
	};

	const getSelectedItems = () => {
		return values
			.filter((val) => {
				return chosenValues.find((chosenValue) => {
					return val.value === chosenValue;
				});
			})
			.map((val, index) => {
				return (
					<span key={val.value} className={styles.selectedItem}>
						<svg
							className={styles.itemRemoveButton}
							width="12"
							height="12"
							viewBox="0 0 12 12"
							onClick={() => handleValueDelete(val.value)}
						>
							<circle cx="6" cy="6" r="6" fill="white" />
							<path
								d="M8.80004 3.49775C8.79441 3.48293 8.7881 3.46811 8.78299 3.45295C8.765 3.39531 8.73287 3.34309 8.68953 3.30104C8.64618 3.25898 8.593 3.22843 8.53482 3.21216C8.47665 3.19589 8.41533 3.19442 8.35643 3.20788C8.29754 3.22134 8.24296 3.24931 8.19765 3.28924C8.1806 3.30355 8.16577 3.31922 8.15026 3.33455C7.45082 4.03377 6.75139 4.73328 6.05195 5.43306C6.03618 5.45077 6.02144 5.46937 6.0078 5.48877C5.98428 5.46628 5.9686 5.45214 5.9536 5.43766C5.24905 4.73356 4.54449 4.02912 3.83994 3.32433C3.72386 3.20816 3.58938 3.17102 3.43614 3.22502C3.19971 3.31019 3.12488 3.60507 3.2875 3.7962C3.30454 3.81561 3.32159 3.83333 3.34034 3.85139C4.03989 4.55061 4.73955 5.24971 5.43933 5.9487C5.45953 5.96623 5.4807 5.98261 5.50274 5.99776C5.47941 6.01383 5.45699 6.03118 5.43558 6.04972C4.73194 6.75234 4.02841 7.45531 3.325 8.15862C3.15028 8.33339 3.15915 8.58806 3.35074 8.72247C3.40511 8.76062 3.47636 8.77476 3.53977 8.7998H3.58358C3.71671 8.78362 3.80875 8.70237 3.89926 8.61157C4.58347 7.92484 5.26893 7.23937 5.95564 6.55514C5.96945 6.54117 5.98411 6.52805 6.01104 6.50233C6.02233 6.52217 6.03533 6.54098 6.0499 6.55855C6.73377 7.24277 7.4177 7.92706 8.10168 8.6114C8.19236 8.70237 8.28424 8.78379 8.41753 8.7998H8.46117C8.52731 8.77289 8.59754 8.7533 8.65822 8.71702C8.73952 8.66796 8.77259 8.58074 8.80021 8.49386V8.3736C8.77344 8.26287 8.70219 8.18281 8.62379 8.10462C7.9348 7.41834 7.24656 6.73128 6.55905 6.04342C6.5396 6.02839 6.51864 6.01543 6.4965 6.00475C6.53059 5.97647 6.54627 5.96455 6.56042 5.95041C7.24963 5.26107 7.93895 4.5719 8.62839 3.8829C8.70595 3.80591 8.77464 3.72635 8.80004 3.61716V3.49775Z"
								fill="#4B9AD4"
							/>
						</svg>
						{val.title}
					</span>

					// 	<div key={val.value}>
					// 		<span className={styles.idealUse}>

					// <button onClick={() => {
					// 				handleValueDelete(val.value);
					// 			}}>
					// <svg
					// 	className={styles.button}
					// 	width="12"
					// 	height="12"
					// 	viewBox="0 0 12 12"
					// 	fill="none"
					// 	xmlns="http://www.w3.org/2000/svg"
					// >
					// 	<path
					// 		opacity="0.3"
					// 		d="M11.9993 11.3587C11.9869 11.3908 11.9733 11.4225 11.9628 11.455C11.9242 11.5785 11.8553 11.6904 11.7625 11.7805C11.6696 11.8707 11.5556 11.9361 11.431 11.971C11.3063 12.0059 11.1749 12.009 11.0487 11.9802C10.9225 11.9513 10.8056 11.8914 10.7085 11.8058C10.6719 11.7751 10.6405 11.7416 10.6073 11.7083C9.10779 10.2103 7.60877 8.712 6.11025 7.21346C6.07654 7.17544 6.04496 7.13559 6.01565 7.0941C5.96525 7.14191 5.93164 7.17258 5.89986 7.2036C4.39013 8.71236 2.88041 10.2219 1.37068 11.7321C1.12195 11.981 0.83376 12.0606 0.505395 11.9449C-0.000849729 11.7624 -0.161561 11.1302 0.186893 10.7206C0.223419 10.6793 0.261774 10.641 0.300491 10.6027C1.7995 9.10416 3.29875 7.6061 4.79825 6.10854C4.84146 6.07093 4.8867 6.03571 4.93376 6.00304C4.88385 5.96932 4.83593 5.93275 4.79022 5.89354C3.2822 4.38794 1.77466 2.88161 0.267613 1.37455C-0.10714 1.00004 -0.087781 0.454333 0.322401 0.16633C0.438918 0.0845645 0.591598 0.0542675 0.727474 0.000244141H0.821347C1.10661 0.0367464 1.30385 0.209037 1.49817 0.403959C2.96431 1.87549 4.433 3.34434 5.90425 4.81051C5.9342 4.84008 5.96524 4.86855 6.02332 4.92331C6.04735 4.8809 6.0751 4.84071 6.10623 4.80321C7.57164 3.33704 9.03729 1.87062 10.5032 0.403959C10.6971 0.209402 10.894 0.0349213 11.1796 0.000244141H11.2735C11.4149 0.0582828 11.5657 0.0998952 11.6957 0.17801C11.8696 0.282772 11.9405 0.470028 12 0.65619V0.913896C11.9427 1.15116 11.7896 1.32236 11.6216 1.4899C10.1452 2.9607 8.67045 4.43296 7.19725 5.90668C7.15556 5.93887 7.11064 5.96664 7.0632 5.98954C7.13625 6.0505 7.16986 6.07605 7.20017 6.10635C8.67678 7.58055 10.1539 9.05561 11.6315 10.5315C11.7973 10.6969 11.9445 10.8673 11.9993 11.101V11.3587Z"
					// 		fill="black"
					// 	/>
					// </svg>
					// </button>

					// 			{val.title}{" "}</span>
					// 		{/* <button
					// 			onClick={() => {
					// 				handleValueDelete(val.value);
					// 			}}
					// 			className={styles.close}
					// 		>
					// 			X
					// 		</button> */}

					// 	</div>
				);
			});
	};

	return (
		<div>
			<div className={styles.idealUseLabel}>{label} </div>
			<select
				className={`${styles.select} ${error ? styles.isError : ""}`}
				onChange={(e: any) => {
					if (!e.target.value) {
						return;
					}

					handleChange(e.target.value);
				}}
			>
				<option value="None" selected={true}>
					{placeholder || title || ""}
				</option>
				{getSelectBoxValues()}
			</select>
			<div className={styles.selectedItemWrapper}>{getSelectedItems()}</div>
		</div>
	);
}
