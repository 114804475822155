import React, { useEffect } from "react";
import styles from "./underlayer.module.scss";
import { useScrollLock } from "../../../hooks/useScroll";

interface UnderlayerProps {
	isHidden: boolean;
	className?: string;
	isScrollLock?: boolean;
}

export default function Underlayer({
	isHidden,
	className,
	isScrollLock = true,
}: UnderlayerProps): JSX.Element {
	const { lockScroll, unlockScroll } = useScrollLock();

	useEffect(() => {
		if (!isScrollLock) return;
		!isHidden ? lockScroll() : unlockScroll();
	}, [isHidden, isScrollLock, lockScroll, unlockScroll]);

	return (
		<div
			className={`
        ${styles.underlayer} ${isHidden ? styles.hidden : ""} ${
				className ? className : ""
			}
      `}
		></div>
	);
}
