import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Fab } from "@mui/material";
import * as Compressor from "compressorjs";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "urql";
import { GalleryType } from "../../graphql/types";
import {
	UploadFileMutation,
	UploadFileMutationVariables,
} from "./gallery.graphql.module";
import styles from "./gallery.module.scss";
import ImageCropper from "./image-cropper";

export const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!) {
		uploadFile(file: $file) {
			success
			error {
				code
				message
				status
			}
			data {
				id
				path
				isActualImage
				thumbnail
			}
		}
	}
`;
interface GalleryProps {
	onFileUpload(imageLink: GalleryType[]): void;
	onFileRemove(path: string): void;
	images: GalleryType[];
	onUploadError: any;
	maxImages?: number;
	useThumbnail?: boolean;
	cropImage?: boolean;
}

interface IImageToUpload extends File {
	uploadedImage?: GalleryType;
	path?: any;
}

export default function Gallery({
	onFileUpload,
	onFileRemove,
	images,
	onUploadError,
	maxImages = 5,
	useThumbnail = true,
	cropImage = false,
}: GalleryProps) {
	const [isRemoving, setIsRemoving] = useState<boolean>(false);
	const [imagesToCrop, setImagesToCrop] = useState<string[]>([]);
	const [imagesToUpload, setImagesToUpload] = useState<IImageToUpload[]>([]);
	const [fileOverDragZone, setFileOverDragZone] = useState<boolean>(false);
	const [uploadFileMutationResult, uploadFileMutation] = useMutation<
		UploadFileMutation,
		UploadFileMutationVariables
	>(UPLOAD_FILE);

	useEffect(() => {
		const compressImageToSize = async (
			image: any,
			compression: number,
			maxSize: number,
		): Promise<any> => {
			const newCompression = compression - 0.1;
			const newImage: any = await compressImage(image, newCompression);
			if (newImage.size <= maxSize) {
				return newImage;
			}
			if (compression < 0.5) {
				return newImage;
			}
			return await compressImageToSize(image, newCompression, maxSize);
		};

		const compressImage = async (image: any, compression: number) => {
			const resizedImage = await new Promise((resolve, reject) => {
				// a little cheat so ts could understand Compressor but it was not exported as default ;)
				const CompressorClass: any = Compressor;
				new CompressorClass(image, {
					quality: compression,
					convertSize: 200000,
					success(result: any) {
						if (!result) {
							reject();
						}
						resolve(result);
					},
					error(err: any) {
						reject(err);
					},
				});
			});
			return resizedImage;
		};

		const uploadFile = async (file: IImageToUpload) => {
			try {
				// console.log("UPLOAD STARTED! " + i)
				const resizedImage = await compressImageToSize(file, 1, 200000);
				const resp = await uploadFileMutation({
					file: resizedImage,
				});
				if (resp.data?.uploadFile.success === false) {
					onUploadError(resp.data?.uploadFile.error?.code);
					const newImagesToUpload = imagesToUpload.filter((img) => {
						return img != file;
					});
					setImagesToUpload(newImagesToUpload);
				}
				if (resp.data?.uploadFile.success === true) {
					file.uploadedImage = resp.data?.uploadFile.data!;
					// onFileUpload(resp.data?.uploadFile.data!);
					setImagesToUpload([...imagesToUpload]);
				}
			} catch (e) {
				onUploadError("Error during the upload!");
			}
		};

		if (imagesToUpload.length > 0) {
			const imagesToUploadLeft = imagesToUpload.filter((img) => {
				return !img.uploadedImage;
			});
			if (imagesToUploadLeft[0]) {
				uploadFile(imagesToUploadLeft[0]);
			} else {
				onFileUpload(
					imagesToUpload.map((img) => {
						return img.uploadedImage!;
					}),
				);
				setImagesToUpload([]);
			}
		}
	}, [
		imagesToUpload,
		imagesToCrop,
		// onFileUpload,
		// onUploadError,
		uploadFileMutation,
	]);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		maxFiles: maxImages,
		disabled: images.length >= maxImages,
		//@ts-ignore
		accept: "image/jpeg, image/png",
		onDragEnter: () => {
			setFileOverDragZone(true);
		},
		onDragLeave: () => {
			setFileOverDragZone(false);
		},
		onDrop: async (files) => {
			setFileOverDragZone(false);

			if (
				files.length +
					images.length +
					imagesToCrop.length +
					imagesToUpload.length <=
				maxImages
			) {
				if (!cropImage) {
					setImagesToUpload([...imagesToUpload, ...files]);
				} else {
					const fileBase64s: any = [];
					let readFiles = 0;
					files.forEach((file: File, index: number) => {
						const reader = new FileReader();
						reader.addEventListener("load", () => {
							fileBase64s[index] = reader.result;
							readFiles++;
							if (readFiles >= files.length) {
								setImagesToCrop(fileBase64s);
							}
						});
						reader.readAsDataURL(file);
					});
				}
			} else {
				onUploadError("Maximum number of files");
			}
		},
	});

	const cancelFile = () => {
		setImagesToCrop(imagesToCrop.slice(1));
	};

	const getImageCropper = () => {
		if (imagesToCrop && imagesToCrop.length > 0) {
			return (
				<ImageCropper
					imageToCrop={imagesToCrop[0]}
					onCrop={(file: IImageToUpload) => {
						setImagesToCrop(imagesToCrop.slice(1));
						setImagesToUpload([...imagesToUpload, file]);
					}}
					onCancel={cancelFile}
				/>
			);
		}
		return null;
	};

	const onFileRemoveError = (imgLink: string) => {
		const images = imagesToUpload.filter((image: any) => {
			return image.path != imgLink;
		});

		setImagesToUpload(images);
	};

	return (
		<div className={styles.addPhotoComponent}>
			<div className={styles.galleryArea}>
				{getImageCropper()}
				{images.map((image: GalleryType) => {
					return (
						<div
							key={image.id}
							className={styles.photo}
							style={{
								backgroundImage: `url(${process.env.FILE_SERVER_DOMAIN}/${
									image.path
								}${useThumbnail ? "/thumbnail" : ""})`,
							}}
						>
							<Fab
								color="secondary"
								aria-label="edit"
								size="small"
								className={styles.removeIcon}
								onClick={() => {
									onFileRemove(image.path);
								}}
							>
								<ClearIcon />
							</Fab>
						</div>
					);
				})}

				{imagesToUpload.map((img, i) => {
					if (img.uploadedImage) {
						return (
							<div
								key={"uploaded" + img.uploadedImage.id}
								className={styles.photo}
								style={{
									backgroundImage: `url(${process.env.FILE_SERVER_DOMAIN}/${
										img.uploadedImage.path
									}${useThumbnail ? "/thumbnail" : ""})`,
								}}
							/>
						);
					}

					return (
						<div key={`uploader${i}`} className={styles.addButton}>
							<CircularProgress />

							<Fab
								color="secondary"
								aria-label="edit"
								size="small"
								className={styles.removeIcon}
								onClick={() => {
									onFileRemoveError(imagesToUpload[i].path);
								}}
							>
								<ClearIcon />
							</Fab>
						</div>
					);
				})}
			</div>
			<section
				className={`${styles.draggableZone} ${
					fileOverDragZone ? styles.draggableZoneCanDropFile : ""
				} ${images.length >= maxImages ? styles.draggableZoneDisabled : ""}`}
			>
				<div {...getRootProps()}>
					<input {...getInputProps({})} />
					<p>Drop some files here, or click to select files</p>
				</div>
			</section>
		</div>
	);
}
