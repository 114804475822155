import React from "react";

import styles from "./loader.module.scss";

function Loader({}): JSX.Element {
	return (
		<div className={styles.book__box}>
			<div className={styles.book}>
				<div className={styles.book__page}></div>
				<div className={styles.book__page}></div>
				<div className={styles.book__page}></div>
			</div>
		</div>
	);
}

export default Loader;
