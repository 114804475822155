import gql from "graphql-tag";
import { useRouter } from "next/router";
import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
	useRef,
} from "react";
import Toggle from "react-toggle";
import { useMutation } from "urql";
import { StaticContext } from "../../../context/static-context/static-context";
import { VocabularyEntry_FragmentFragment } from "../../../context/static-context/static-context.query.graphql.module";
import {
	DeliveryTypeEnum,
	GalleryType,
	InventoryLoadFobType,
	ListingIdealUseEnum,
	ListingStatusEnum,
} from "../../../graphql/types";
import useWindowSize from "../../../hooks/useWindowSize";
import CategoryDropdown, {
	CategoryItem,
} from "../../common/category-dropdown/category-dropdown";
import Checkbox from "../../common/form/checkbox/checkbox";
import FormGroup from "../../common/form/form-group/form-group";
import Input from "../../common/form/input/input";
import RadioGroup from "../../common/form/radio-group/radio-group";
import Popup from "../../common/popup/popup";
import UploadManifest from "../../common/upload-manifest/upload-manifest";
import Gallery from "../../gallery/gallery";
import FormContainer from "../form-container/form-container";
import { FormValidations, validateForm } from "./form-validation";
import {
	deliveryTypeOptions,
	dimensionsTitle,
	loadSizeOptions,
	palletHeightAverageEnum,
	uploadManifestOptions,
} from "./initial-values";
import {
	CreateItemWithGrantMutation,
	CreateItemWithGrantMutationVariables,
	UpdateItemWithGrantMutation,
	UpdateItemWithGrantMutationVariables,
} from "./new-load-form.graphql.module";
import styles from "./new-load-form.module.scss";

import { polyfill } from "seamless-scroll-polyfill";
import { setMonth } from "date-fns";
import { idealUseTypes } from "../../translations/idealUse-types";
import { MultipleSelectBox } from "../../common/form/multiple-select-box/multiple-select-box";

export const CREATE_ITEM_WITH_GRANT = gql`
	mutation CreateItemWithGrant(
		$permissionGrantKey: String!
		$itemData: CreateItemDTO!
	) {
		createItemWithGrant(
			permissionGrantKey: $permissionGrantKey
			itemData: $itemData
		) {
			error {
				code
				message
				status
			}
		}
	}
`;

export const UPDATE_ITEM_WITH_GRAND = gql`
	mutation UpdateItemWithGrant(
		$itemData: UpdateItemDTO!
		$permissionGrantKey: String!
	) {
		updateItemWithGrant(
			itemData: $itemData
			permissionGrantKey: $permissionGrantKey
		) {
			error {
				code
				message
				status
			}
			success
		}
	}
`;

type INVENTORY_LOAD_SIZE =
	| ""
	| "SIZE_TRUCKLOAD"
	| "SIZE_BOX_LOT"
	| "SIZE_PALLET";

export interface ValuesToSet {
	id?: string;
	inventoryLoadSize: string;
	availableLoadsQuantity: number;
	deliveryType: DeliveryTypeEnum;
	listingIdealUse: ListingIdealUseEnum[];
	commission: number;
	availableForExport: boolean;
	inventoryLoadFOB: {
		city: string;
		street: string;
		state: string;
		zipCode: string;
	};
	width: number;
	height: number;
	length: number;
	weight: number;
	palletCount: string;
	palletSpace: string;
	cost: number;
	discount: number;
	retailer: string[];
	inventoryLoadCondition: string[];
	inventoryLoadCategory: string[];
	gallery: GalleryType[];
	title: string;
	description: string;
	manifestFile: string | undefined;
	estimatedRetailValue: number;
	wholeSalesValue: number;
	estimatedPieceCount: string;
	sellingRestrictions: string;
	notesField: string;
	canBeSoldByThirdParty: boolean;
	leadTimeField: number;
	palletHeight: string;
}
interface NewLoadFormProps {
	closeForm: () => void;
	values?: ValuesToSet;
}

const LOAD_SIZE_MAP = {
	SIZE_TRUCKLOAD: "37bdbdd7-3458-401e-a626-499f96af81fd",
	SIZE_BOX_LOT: "538f5f49-b7d6-4653-9cf3-b31bf39cd249",
	SIZE_PALLET: "9bdff72c-5b1c-4819-93f9-aa8b1ee50648",
};

const LOAD_SIZE_MAP_INVERSE: { [key: string]: INVENTORY_LOAD_SIZE } = {
	"37bdbdd7-3458-401e-a626-499f96af81fd": "SIZE_TRUCKLOAD",
	"538f5f49-b7d6-4653-9cf3-b31bf39cd249": "SIZE_BOX_LOT",
	"9bdff72c-5b1c-4819-93f9-aa8b1ee50648": "SIZE_PALLET",
};

function NewLoadForm({
	closeForm,
	values = {
		inventoryLoadSize: "",
		availableLoadsQuantity: 1,
		deliveryType: DeliveryTypeEnum.IWillDeliver,
		listingIdealUse: [],
		commission: 0,
		availableForExport: false,
		inventoryLoadFOB: {
			city: "",
			street: "",
			state: "",
			zipCode: "",
		},
		width: 0,
		height: 0,
		length: 0,
		weight: 0,
		palletCount: "",
		palletSpace: "",
		cost: 0,
		discount: 0,
		retailer: [],
		inventoryLoadCondition: [],
		inventoryLoadCategory: [],
		gallery: [],
		title: "",
		description: "",
		manifestFile: undefined,
		estimatedRetailValue: 0,
		wholeSalesValue: 0,
		estimatedPieceCount: "0",
		sellingRestrictions: "None",
		notesField: "",
		canBeSoldByThirdParty: true,
		leadTimeField: 0,
		palletHeight: "",
	},
}: NewLoadFormProps): JSX.Element {
	const windowSize = useWindowSize();
	const { query } = useRouter();
	const permissionGrantKey = query.permissionGrantKey as string;

	const contextValue = useContext(StaticContext);
	const { categories, retailers, states, vocabularyEntries } = contextValue;

	const [isMobile, setIsMobile] = useState(false);
	const [categoryTree, setCategoryTree] = useState<CategoryItem[]>([]);
	const [categoryOpen, setCategoryOpen] = useState(false);

	const [
		inventoryLoadSize,
		setInventoryLoadSize,
	] = useState<INVENTORY_LOAD_SIZE>("SIZE_TRUCKLOAD");

	// const [inventoryLoadSize, setInventoryLoadSize] =
	// useState<INVENTORY_LOAD_SIZE>(
	// 	LOAD_SIZE_MAP_INVERSE[values.inventoryLoadSize] || "SIZE_TRUCKLOAD",
	// );

	const [availableLoadsQuantity, setAvailableLoadsQuantity] = useState<number>(
		values.availableLoadsQuantity || 1,
	);
	const [deliveryType, setDeliveryType] = useState<DeliveryTypeEnum>(
		values.deliveryType,
	);

	const [listingIdealUse, setListingIdealUse] = useState<ListingIdealUseEnum[]>(
		values.listingIdealUse || [],
	);

	const [commission, setCommission] = React.useState<number>(
		values?.commission || 0,
	);

	//const [retailer, setRetailer] = useState<string[]>(values.retailer || []);
	const [availableForExport, setAvailableForExport] = useState(
		values.availableForExport || false,
	);

	const [
		inventoryLoadFOB,
		setInventoryLoadFOB,
	] = useState<InventoryLoadFobType>({
		city: values.inventoryLoadFOB.city,
		street: values.inventoryLoadFOB.street,
		state: values.inventoryLoadFOB.state,
		zipCode: values.inventoryLoadFOB.zipCode,
	});

	const [width, setWidth] = useState<number>(values.width);
	const [height, setHeight] = useState<number>(values.height);
	const [length, setLength] = useState<number>(values.length);
	const [weight, setWeight] = useState<number>(values.weight);
	const [palletCount, setPalletCount] = useState<string>(
		values.palletCount || "",
	);
	const [palletSpace, setPalletSpace] = useState<string>(
		values.palletSpace || "",
	);

	const [leadTime, setLeadTime] = useState<number>(values.leadTimeField);

	const [cost, setCost] = useState<number>(values.cost);
	const [discount, setDiscount] = useState<number>(values.discount);

	const [retailer, setRetailer] = useState<string[]>(values.retailer || []);
	const [inventoryLoadCondition, setInventoryLoadCondition] = useState<
		string[]
	>(values.inventoryLoadCondition || []);
	const [inventoryLoadCategory, setInventoryLoadCategory] = useState<string[]>(
		values.inventoryLoadCategory || [],
	);

	const [gallery, setGallery] = useState<GalleryType[]>(
		values.gallery.map((g) => {
			const { id, isActualImage, path, thumbnail } = g;
			return { id, isActualImage, path, thumbnail };
		}),
	);

	const handleDeleteIdealUse = (value: string) => {
		const newVal = listingIdealUse.filter((listingIdealUse: string) => {
			return value != listingIdealUse;
		});
		setListingIdealUse(newVal);
	};

	const [title, setTitle] = useState<string>(values.title || "");
	const [description, setDescription] = useState<string>(
		values.description || "",
	);

	const [manifestFile, setManifestFile] = useState(values.manifestFile || "");
	const [hasManifestFile, setHasManifestFile] = useState<boolean | undefined>(
		typeof values.manifestFile === "undefined"
			? undefined
			: Boolean(values.manifestFile),
	);

	const [
		estimatedRetailValueRemember,
		setEstimatedRetailValueRemember,
	] = useState<number>(values.estimatedRetailValue);

	const [
		wholeSalesValueRemember,
		setWholeSalesValueRemember,
	] = useState<number>(values.wholeSalesValue);

	const [estimatedRetailValue, setEstimatedRetailValue] = useState<number>(
		values.estimatedRetailValue,
	);

	const [wholeSalesValue, setWholeSalesValue] = useState<number>(
		values.wholeSalesValue,
	);

	const [estimatedPieceCount, setEstimatedPieceCount] = useState<string>(
		values.estimatedPieceCount,
	);

	const [sellingRestrictions, setSellingRestrictions] = useState(
		values.sellingRestrictions || "None",
	);

	const [notesField, setNotesField] = useState(values.notesField || "");

	const [canBeSoldByThirdParty, setCanBeSoldByThirdParty] = useState(
		values.canBeSoldByThirdParty || false,
	);

	const [palletHeightAverage, setPalletHeightAverage] = useState(
		values.palletHeight || "",
	);

	const [validatedForm, setValidatedForm] = useState<FormValidations>({
		formErrors: {
			descriptionError: [],
			availableLoadsQuantityError: [],
			sellingRestrictionsError: [],
			galleryError: [],
			inventoryLoadConditionError: [],
			inventoryLoadSizeError: [],
			inventoryLoadFOBError: {
				// cityError: [],
				// streetError: [],
				stateError: [],
				// zipCodeError: [],
			},
			deliveryTypeError: [],
			listingStatusError: [],
			inventoryLoadCategoryError: [],
			priceError: [],
			leadTimeError: [],
			retailerError: [],
			hasManifestFile: [],
			manifestFileError: [],
			// estimatedRetailValueError: [],
			// wholeSalesValueError: [],
			estimatedPieceCountError: [],
			widthError: [],
			heightError: [],
			lengthError: [],
			weightError: [],
		},
		isFormValid: true,
	});

	const [toggleState, setToggleState] = useState(
		Boolean(!values.estimatedRetailValue),
	);

	const [toggleStateCount, setToggleStateCount] = useState(
		Boolean(values.palletSpace),
	);

	const prefixId = "newLoad";

	const [createItemResult, createItem] = useMutation<
		CreateItemWithGrantMutation,
		CreateItemWithGrantMutationVariables
	>(CREATE_ITEM_WITH_GRANT);

	const [updateItemResult, updateItem] = useMutation<
		UpdateItemWithGrantMutation,
		UpdateItemWithGrantMutationVariables
	>(UPDATE_ITEM_WITH_GRAND);

	const onlyConditions = (x: VocabularyEntry_FragmentFragment) =>
		x.parent?.keyword === "bca7e636-7651-42af-ad20-46dcc5e27bc5";

	const onFileUploadSample = (imgs: GalleryType[]) => {
		const newImgs = imgs.map((imgToProcess) => {
			const { __typename, ...imageFields } = imgToProcess;
			return {
				...imageFields,
				isActualImage: false,
			};
		});
		setGallery([...gallery, ...newImgs]);
	};

	const onFileUploadActual = (imgs: GalleryType[]) => {
		const newImgs = imgs.map((imgToProcess) => {
			const { __typename, ...imageFields } = imgToProcess;
			return {
				...imageFields,
				isActualImage: true,
			};
		});
		setGallery([...gallery, ...newImgs]);
	};

	const onFileRemove = (imgLink: string) => {
		const images = gallery.filter((image: GalleryType) => {
			return image.path != imgLink;
		});

		setGallery(images);
	};

	const uncheckCategory = (category: CategoryItem) => {
		const _checked = inventoryLoadCategory.slice();

		const removeRecursive = (category: CategoryItem) => {
			const index = _checked.indexOf(category.id);
			if (index > -1) _checked.splice(index, 1);
			category.children.forEach(removeRecursive);
		};

		removeRecursive(category);
		setInventoryLoadCategory(_checked);
	};

	const categoryTreeToFlat = (tree: CategoryItem[]) => {
		const data: Array<CategoryItem & { prefix: string }> = [];

		const teeToFlat = (tree: CategoryItem[], prefix = "") => {
			for (const category of tree) {
				data.push({ ...category, prefix });
				teeToFlat(category.children, `${category.title} > `);
			}

			return data;
		};

		return teeToFlat(tree)
			.filter(({ id }) => inventoryLoadCategory.includes(id))
			.map((category) => {
				return (
					<span key={category.id} className={styles.selectedCategory}>
						<svg
							width="12"
							height="12"
							viewBox="0 0 12 12"
							onClick={() => uncheckCategory(category)}
						>
							<circle cx="6" cy="6" r="6" fill="white" />
							<path
								d="M8.80004 3.49775C8.79441 3.48293 8.7881 3.46811 8.78299 3.45295C8.765 3.39531 8.73287 3.34309 8.68953 3.30104C8.64618 3.25898 8.593 3.22843 8.53482 3.21216C8.47665 3.19589 8.41533 3.19442 8.35643 3.20788C8.29754 3.22134 8.24296 3.24931 8.19765 3.28924C8.1806 3.30355 8.16577 3.31922 8.15026 3.33455C7.45082 4.03377 6.75139 4.73328 6.05195 5.43306C6.03618 5.45077 6.02144 5.46937 6.0078 5.48877C5.98428 5.46628 5.9686 5.45214 5.9536 5.43766C5.24905 4.73356 4.54449 4.02912 3.83994 3.32433C3.72386 3.20816 3.58938 3.17102 3.43614 3.22502C3.19971 3.31019 3.12488 3.60507 3.2875 3.7962C3.30454 3.81561 3.32159 3.83333 3.34034 3.85139C4.03989 4.55061 4.73955 5.24971 5.43933 5.9487C5.45953 5.96623 5.4807 5.98261 5.50274 5.99776C5.47941 6.01383 5.45699 6.03118 5.43558 6.04972C4.73194 6.75234 4.02841 7.45531 3.325 8.15862C3.15028 8.33339 3.15915 8.58806 3.35074 8.72247C3.40511 8.76062 3.47636 8.77476 3.53977 8.7998H3.58358C3.71671 8.78362 3.80875 8.70237 3.89926 8.61157C4.58347 7.92484 5.26893 7.23937 5.95564 6.55514C5.96945 6.54117 5.98411 6.52805 6.01104 6.50233C6.02233 6.52217 6.03533 6.54098 6.0499 6.55855C6.73377 7.24277 7.4177 7.92706 8.10168 8.6114C8.19236 8.70237 8.28424 8.78379 8.41753 8.7998H8.46117C8.52731 8.77289 8.59754 8.7533 8.65822 8.71702C8.73952 8.66796 8.77259 8.58074 8.80021 8.49386V8.3736C8.77344 8.26287 8.70219 8.18281 8.62379 8.10462C7.9348 7.41834 7.24656 6.73128 6.55905 6.04342C6.5396 6.02839 6.51864 6.01543 6.4965 6.00475C6.53059 5.97647 6.54627 5.96455 6.56042 5.95041C7.24963 5.26107 7.93895 4.5719 8.62839 3.8829C8.70595 3.80591 8.77464 3.72635 8.80004 3.61716V3.49775Z"
								fill="#4B9AD4"
							/>
						</svg>
						{category.prefix + category.title}
					</span>
				);
			});
	};

	useEffect(() => {
		const isMobileWidth = () => (windowSize.width <= 540 ? true : false);
		setIsMobile(isMobileWidth);
	}, [setIsMobile, windowSize.width]);

	useEffect(() => {
		if (hasManifestFile) return;
		setManifestFile("");
	}, [hasManifestFile]);

	useEffect(() => {
		const { overflowY } = document.body.style;

		document.body.style.overflowY = "hidden";

		return () => {
			document.body.style.overflowY = overflowY;
		};
	}, []);

	function disableNegativeNumbers(
		setValue: Dispatch<SetStateAction<any>>,
		value: any,
	) {
		let val = parseInt(value);
		setValue((val = Math.abs(val)));
	}

	const typуСheckForm = inventoryLoadSize === "SIZE_PALLET";

	const inputRefStreet = React.useRef<HTMLInputElement>(null);
	const inputRefCity = React.useRef<HTMLInputElement>(null);
	const inputRefState = React.useRef<HTMLInputElement>(null);
	const inputRefZip = React.useRef<HTMLInputElement>(null);

	const inputRefCost = React.useRef<HTMLInputElement>(null);

	const inputRefLeadTime = React.useRef<HTMLInputElement>(null);

	const inputRefRetailer = React.useRef<HTMLInputElement>(null);
	const inputRefCondition = React.useRef<HTMLInputElement>(null);
	const inputRefCategory = React.useRef<HTMLInputElement>(null);

	const inputRefImg = React.useRef<HTMLInputElement>(null);
	const inputRefDescription = React.useRef<HTMLInputElement>(null);

	const inputRefWidth = React.useRef<HTMLInputElement>(null);
	const inputRefHeight = React.useRef<HTMLInputElement>(null);
	const inputRefLength = React.useRef<HTMLInputElement>(null);
	const inputRefWeight = React.useRef<HTMLInputElement>(null);

	const executeScroll = () => {
		const refScrool = (target: any, lengthTarget: any) => {
			if (target.current && lengthTarget.length === 0) {
				target.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "center",
				});
				polyfill();
			}
		};
		refScrool(inputRefDescription, description);
		refScrool(inputRefImg, gallery);
		refScrool(inputRefCategory, inventoryLoadCategory);

		if (inputRefCost.current && (isNaN(cost) || cost === 0)) {
			inputRefCost.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
			polyfill();
		}

		if (inputRefRetailer.current && retailer[0] === undefined) {
			inputRefRetailer.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
			polyfill();
		}

		if (inputRefCondition.current && inventoryLoadCondition[0] === undefined) {
			inputRefCondition.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
			polyfill();
		}

		if (inventoryLoadSize === "SIZE_PALLET") {
			if (inputRefWidth.current && (isNaN(width) || width === 0)) {
				inputRefWidth.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "center",
				});
				polyfill();
			}

			if (inputRefHeight.current && (isNaN(height) || height === 0)) {
				inputRefHeight.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "center",
				});
				polyfill();
			}

			if (inputRefLength.current && (isNaN(length) || length === 0)) {
				inputRefLength.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "center",
				});
				polyfill();
			}

			if (inputRefWeight.current && (isNaN(weight) || weight === 0)) {
				inputRefWeight.current.scrollIntoView({
					behavior: "smooth",
					block: "center",
					inline: "center",
				});
				polyfill();
			}
		}

		// refScrool(inputRefCity, inventoryLoadFOB.city);
		// refScrool(inputRefStreet, inventoryLoadFOB.street);
		refScrool(inputRefState, inventoryLoadFOB.state);
		// refScrool(inputRefZip, inventoryLoadFOB.zipCode);

		if (inputRefLeadTime.current && (isNaN(cost) || cost === 0)) {
			inputRefLeadTime.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
			polyfill();
		}
	};
	const handleIdealUseChange = (value: string) => {
		let newVal = listingIdealUse || [];
		if (value !== "") {
			newVal.push(value as ListingIdealUseEnum);
		}
		setListingIdealUse([...newVal]);
	};

	return (
		<div className={styles.newLoadFormMobBox}>
			<Popup className={styles.newLoadPopup} isScrollLock={false}>
				<FormContainer
					// formInitialValues={{}}
					title={values.id ? "Edit Load Details" : "New Load Details"}
					subTitle={
						!isMobile
							? "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
							: ""
					}
					className={styles.newLoadFormContainer}
					formHeaderClassName={styles.formHeader}
					formContentClassName={styles.formContent}
					showSubmitButton={!!inventoryLoadSize}
					closeForm={closeForm}
					onSubmit={async () => {
						if (!inventoryLoadSize) return;

						executeScroll();
						let _validatedForm = validateForm({
							description,
							hasManifestFile,
							manifestFile,
							availableLoadsQuantity,
							inventoryLoadCondition,
							inventoryLoadSize,
							deliveryType,
							sellingRestrictions,
							inventoryLoadCategory,
							cost,
							inventoryLoadFOB,
							retailer,
							gallery,
							estimatedRetailValue,
							wholeSalesValue,
							estimatedPieceCount,
							toggleState,
							width,
							height,
							length,
							weight,
							leadTime,
						});

						if (!typуСheckForm) {
							_validatedForm = validateForm({
								description,
								hasManifestFile,
								manifestFile,
								availableLoadsQuantity,
								inventoryLoadCondition,
								inventoryLoadSize,
								deliveryType,
								sellingRestrictions,
								inventoryLoadCategory,
								cost,
								leadTime,
								inventoryLoadFOB,
								retailer,
								gallery,
								estimatedRetailValue,
								wholeSalesValue,
								estimatedPieceCount,
								toggleState,
								width: 1,
								height: 1,
								length: 1,
								weight: 1,
							});
						}
						setValidatedForm(_validatedForm);
						if (_validatedForm.isFormValid) {
							if (values.id) {
								let wholeSalesValueSent = 0;
								let estimatedRetailValueSent = 0;

								if (toggleState) {
									wholeSalesValueSent = wholeSalesValue;
								} else {
									estimatedRetailValueSent = estimatedRetailValue;
								}

								let palletCountSent = "";
								let palletSpaceSent = "";

								if (!toggleStateCount) {
									palletCountSent = palletCount;
								} else {
									palletSpaceSent = palletSpace;
								}

								let listingIdealUseSent: any = listingIdealUse;

								if (!listingIdealUse) {
									listingIdealUseSent = null;
								}

								await updateItem({
									permissionGrantKey,
									itemData: {
										itemId: values.id,
										inventoryLoadSize: LOAD_SIZE_MAP[inventoryLoadSize],
										availableLoadsQuantity,
										deliveryType,
										availableForExport,
										inventoryLoadFOB,
										width,
										height,
										length,
										weight,
										palletCount: palletCountSent,
										palletSpace: palletSpaceSent,
										cost,
										discount,
										retailer,
										inventoryLoadCondition,
										inventoryLoadCategory,
										title,
										description,
										estimatedRetailValue: estimatedRetailValueSent,
										wholeSalesValue: wholeSalesValueSent,
										estimatedPieceCount,
										sellingRestrictions,
										canBeSoldByThirdParty,
										listingStatus: ListingStatusEnum.Draft,
										manifestFile,
										gallery,
										listingIdealUse: listingIdealUseSent,
										commission,
										notesField,
										leadTimeField: leadTime,
										palletHeight: palletHeightAverage,
									},
								});
							} else {
								let wholeSalesValueSent = 0;
								let estimatedRetailValueSent = 0;

								if (toggleState) {
									wholeSalesValueSent = wholeSalesValue;
								} else {
									estimatedRetailValueSent = estimatedRetailValue;
								}

								let palletCountSent = "";
								let palletSpaceSent = "";

								if (!toggleStateCount) {
									palletCountSent = palletCount;
								} else {
									palletSpaceSent = palletSpace;
								}

								let listingIdealUseSent: any = listingIdealUse;

								if (!listingIdealUse) {
									listingIdealUseSent = null;
								}

								await createItem({
									permissionGrantKey,
									itemData: {
										inventoryLoadSize: LOAD_SIZE_MAP[inventoryLoadSize],
										availableLoadsQuantity,
										deliveryType,
										availableForExport,
										inventoryLoadFOB,
										width,
										height,
										length,
										weight,
										palletCount: palletCountSent,
										palletSpace: palletSpaceSent,
										cost,
										discount,
										retailer,
										inventoryLoadCondition,
										inventoryLoadCategory,
										title,
										description,
										estimatedRetailValue: estimatedRetailValueSent,
										wholeSalesValue: wholeSalesValueSent,
										estimatedPieceCount,
										sellingRestrictions,
										canBeSoldByThirdParty,
										schedulerSendToFacebook: true,
										schedulerSendToMailingList: true,
										schedulerSendsLeft: 3,
										listingStatus: ListingStatusEnum.Draft,
										manifestFile,
										gallery,
										listingIdealUse: listingIdealUseSent,
										commission,
										notesField,
										leadTimeField: leadTime,
										palletHeight: palletHeightAverage,
									},
								});
							}

							closeForm();
						}
					}}
				>
					{isMobile && (
						<p className={styles.mobileSubtitle}>
							It is a long established fact that a reader will be distracted by
							the readable content of a page when looking at its layout.
						</p>
					)}

					{/* Inventory Load Size:*/}
					{/* <FormGroup
						title="Load Size"
						className={[styles.newLoadFormGroup, styles.noMargin].join(" ")}
						childrenClassName={styles.threeRadiosAndInput}
					>
						<RadioGroup
							name="loadSize"
							options={loadSizeOptions}
							onChange={setInventoryLoadSize}
							value={inventoryLoadSize}
						/>
					</FormGroup> */}

					{inventoryLoadSize && (
						<>
							{/* Available for Export: */}
							<FormGroup
								// title="Available for Export"
								className={`${styles.newLoadFormGroup} ${styles.newLoadFormGroupUp}`}
							>
								<div className={styles.inputUpper}>
									<Input
										label="Available Quantity"
										invalidFeedback={
											validatedForm.formErrors.availableLoadsQuantityError
										}
										placeholder="Enter Available Quantity"
										id={"quantity" + inventoryLoadSize}
										name="quantity"
										value={availableLoadsQuantity}
										onChange={(val) =>
											disableNegativeNumbers(setAvailableLoadsQuantity, val)
										}
										type="number"
										min="0"
									/>
									<Input
										label={"Delivery Type"}
										invalidFeedback={validatedForm.formErrors.deliveryTypeError}
										inputElement="select"
										placeholder={"Select Delivery Type"}
										id={"deliveryType" + prefixId}
										name={"deliveryType"}
										value={deliveryType}
										onChange={setDeliveryType}
										selectOptions={deliveryTypeOptions}
									/>

									<MultipleSelectBox
										label={"Ideal Use"}
										handleChange={handleIdealUseChange}
										handleValueDelete={handleDeleteIdealUse}
										values={idealUseTypes}
										chosenValues={listingIdealUse}
										placeholder={"Select Ideal Use"}
									/>
								</div>
							</FormGroup>
							<div className={styles.availableForExport}>
								<div className={styles.leadTime} ref={inputRefLeadTime}>
									<p>Lead Time</p>
									<Input
										invalidFeedback={validatedForm.formErrors.leadTimeError}
										placeholder={"Enter Lead Time"}
										id={"leadTime" + prefixId}
										name="leadTime"
										value={leadTime === 0 ? "" : leadTime}
										onChange={(val) => disableNegativeNumbers(setLeadTime, val)}
										type="number"
										min="0"
										error={validatedForm.formErrors.leadTimeError.length === 1}
									/>
								</div>
								<Checkbox
									name="availableForExport"
									text="Available for Export"
									value="true"
									checked={availableForExport}
									onChecked={setAvailableForExport}
								/>
							</div>
							{/* Location: */}
							<FormGroup
								title="Location"
								childrenClassName={styles.fourInputs}
								className={styles.newLoadFormGroup}
							>
								<div ref={inputRefStreet}>
									<Input
										label={"Street"}
										// invalidFeedback={
										// 	validatedForm.formErrors.inventoryLoadFOBError.streetError
										// }
										placeholder="Enter Street"
										id={"street" + inventoryLoadSize}
										name="street"
										value={inventoryLoadFOB.street}
										onChange={(val) => {
											setInventoryLoadFOB({ ...inventoryLoadFOB, street: val });
										}}
										// error={
										// 	validatedForm.formErrors.inventoryLoadFOBError.streetError
										// 		.length === 1
										// }
									/>
								</div>
								<div ref={inputRefCity}>
									<Input
										label={"City"}
										// invalidFeedback={
										// 	validatedForm.formErrors.inventoryLoadFOBError.cityError
										// }
										placeholder="Enter City"
										id={"city" + inventoryLoadSize}
										name="city"
										value={inventoryLoadFOB.city}
										onChange={(val) => {
											setInventoryLoadFOB({ ...inventoryLoadFOB, city: val });
										}}
										// error={
										// 	validatedForm.formErrors.inventoryLoadFOBError.cityError
										// 		.length === 1
										// }
									/>
								</div>
								<div ref={inputRefState}>
									<Input
										label={"State"}
										invalidFeedback={
											validatedForm.formErrors.inventoryLoadFOBError.stateError
										}
										inputElement="select"
										placeholder={"Select State"}
										id={"state" + prefixId}
										name={"state"}
										value={inventoryLoadFOB.state}
										onChange={(val) => {
											setInventoryLoadFOB({ ...inventoryLoadFOB, state: val });
										}}
										selectOptions={states?.map((state) => ({
											key: state.abbreviation,
											value: state.name,
										}))}
										error={
											validatedForm.formErrors.inventoryLoadFOBError.stateError
												.length === 1
										}
									/>
								</div>
								<div ref={inputRefZip}>
									<Input
										label={"ZIP Code"}
										// invalidFeedback={
										// 	validatedForm.formErrors.inventoryLoadFOBError
										// 		.zipCodeError
										// }
										placeholder="Enter ZIP Code"
										id={"zipCode" + inventoryLoadSize}
										name="zipCode"
										value={inventoryLoadFOB.zipCode}
										onChange={(val) => {
											setInventoryLoadFOB({
												...inventoryLoadFOB,
												zipCode: val,
											});
										}}
										// error={
										// 	validatedForm.formErrors.inventoryLoadFOBError
										// 		.zipCodeError.length === 1
										// }
									/>
								</div>
							</FormGroup>

							{/* Dimensions: */}
							<FormGroup
								title={"Pallet Count/Space"}
								childrenClassName={styles.fourInputs}
								className={styles.newLoadFormGroup}
							>
								{inventoryLoadSize === "SIZE_TRUCKLOAD" ? (
									<>
										{/* pallet Count = Count */}
										<Input
											label="Pallet Count"
											id={"palletCount" + prefixId}
											name="palletCount"
											placeholder="Pallet Count"
											value={palletCount}
											onChange={(val) => setPalletCount(val.toString())}
											min="0"
											disabled={toggleStateCount}
										/>
										<div className={styles.toggleWrapper}>
											<Toggle
												onChange={(e: any) =>
													setToggleStateCount(e.target.checked)
												}
												defaultChecked={toggleStateCount}
											/>
										</div>
										<Input
											label="Pallet Space"
											id={"palletSpace" + prefixId}
											name="palletSpace"
											placeholder="Pallet Space"
											value={palletSpace}
											onChange={(val) => setPalletSpace(val.toString())}
											min="0"
											disabled={!toggleStateCount}
										/>
									</>
								) : (
									<>
										<div ref={inputRefWidth}>
											<Input
												invalidFeedback={
													typуСheckForm
														? validatedForm.formErrors.widthError
														: undefined
												}
												label="Width"
												id={"width" + prefixId}
												name="width"
												placeholder="Width"
												value={width === 0 ? "" : width}
												onChange={(val) =>
													disableNegativeNumbers(setWidth, val)
												}
												type="number"
												min="0"
												error={
													typуСheckForm
														? validatedForm.formErrors.widthError.length === 1
														: false
												}
											/>
										</div>
										<div ref={inputRefHeight}>
											<Input
												label="Height"
												id={"height" + prefixId}
												name="height"
												placeholder="Height"
												value={height === 0 ? "" : height}
												onChange={(val) =>
													disableNegativeNumbers(setHeight, val)
												}
												type="number"
												min="0"
												invalidFeedback={
													typуСheckForm
														? validatedForm.formErrors.heightError
														: undefined
												}
												error={
													typуСheckForm
														? validatedForm.formErrors.heightError.length === 1
														: false
												}
											/>
										</div>
										<div ref={inputRefLength}>
											<Input
												label="Length"
												id={"length" + prefixId}
												name="length"
												placeholder="Length"
												value={length === 0 ? "" : length}
												onChange={(val) =>
													disableNegativeNumbers(setLength, val)
												}
												type="number"
												min="0"
												invalidFeedback={
													typуСheckForm
														? validatedForm.formErrors.lengthError
														: undefined
												}
												error={
													typуСheckForm
														? validatedForm.formErrors.lengthError.length === 1
														: false
												}
											/>
										</div>
										<div ref={inputRefWeight}>
											<Input
												label="Weight"
												id={"weight" + prefixId}
												name="weight"
												placeholder="Weight"
												value={weight === 0 ? "" : weight}
												onChange={(val) =>
													disableNegativeNumbers(setWeight, val)
												}
												type="number"
												min="0"
												invalidFeedback={
													typуСheckForm
														? validatedForm.formErrors.weightError
														: undefined
												}
												error={
													typуСheckForm
														? validatedForm.formErrors.weightError.length === 1
														: false
												}
											/>
										</div>
									</>
								)}
								<div className={styles.palletHeightAverageDIV}></div>
								<div className={styles.palletHeightAverage}>
									<Input
										label="Average Pallet Height"
										inputElement="select"
										placeholder={"Select  Pallet Height"}
										id={"palletHeightAverage" + prefixId}
										name={"palletHeightAverage"}
										value={palletHeightAverage}
										onChange={(val) => {
											setPalletHeightAverage(val);
										}}
										selectOptions={palletHeightAverageEnum}
									/>
								</div>
							</FormGroup>

							{/* Cost and discount: */}
							<FormGroup
								title={"Cost and Discount"}
								childrenClassName={styles.threeInputs}
								className={styles.newLoadFormGroup}
							>
								<div ref={inputRefCost}>
									<Input
										label={"Cost"}
										invalidFeedback={validatedForm.formErrors.priceError}
										placeholder={"Enter Load Cost"}
										id={"cost" + prefixId}
										name="cost"
										value={cost === 0 ? "" : cost}
										onChange={(val) => disableNegativeNumbers(setCost, val)}
										type="number"
										min="0"
										error={validatedForm.formErrors.priceError.length === 1}
									/>
								</div>
								<Input
									label={"Discount Amount"}
									placeholder={"Enter Discount Amount"}
									id={"discount" + prefixId}
									name="discount"
									value={discount === 0 ? "" : discount}
									onChange={(val) => disableNegativeNumbers(setDiscount, val)}
									type="number"
									min="0"
								/>

								<Input
									label={"Sales Commission"}
									placeholder={"Enter Sales Commission"}
									id={"commission" + prefixId}
									name="commission"
									value={commission === 0 ? "" : commission}
									onChange={(val) => disableNegativeNumbers(setCommission, val)}
									type="number"
									min="0"
								/>
							</FormGroup>

							{/* Retailer, Condition and Category */}
							<FormGroup
								title={"Retailer, Condition and Category"}
								childrenClassName={`${styles.threeInputs} ${styles.selectGroup}`}
								className={styles.newLoadFormGroup}
							>
								<>
									<div ref={inputRefRetailer}>
										<Input
											label={"Retailer"}
											invalidFeedback={validatedForm.formErrors.retailerError}
											inputElement="select"
											placeholder={"Select Retailer"}
											id={"retailer" + prefixId}
											name={"retailer"}
											value={retailer[0]}
											onChange={(val) => {
												setRetailer([val]);
												setInventoryLoadCategory([]);
											}}
											selectOptions={retailers?.map((retailer) => ({
												key: retailer.id,
												value: retailer.name,
											}))}
											error={
												validatedForm.formErrors.retailerError.length === 1
											}
										/>
									</div>
									{/* <div ref={inputRefCondition} className={styles.loadCondition}> */}
									<div ref={inputRefCondition}>
										<Input
											label={"Condition"}
											invalidFeedback={
												validatedForm.formErrors.inventoryLoadConditionError
											}
											inputElement="select"
											placeholder={"Select Condition"}
											id={"condition" + prefixId}
											name={"condition"}
											value={inventoryLoadCondition[0]}
											onChange={(val) => setInventoryLoadCondition([val])}
											selectOptions={vocabularyEntries
												.filter(onlyConditions)
												.map((ele) => ({
													key: ele.id,
													value: ele.title,
												}))}
											error={
												validatedForm.formErrors.inventoryLoadConditionError
													.length === 1
											}
										/>
										{/* <div className={styles.loadConditionInfo}>
											<p>Condition</p>
											<div>i</div>
										</div> */}
									</div>
									<div style={{ position: "relative" }}>
										<CategoryDropdown
											isOpen={categoryOpen}
											checked={inventoryLoadCategory}
											setChecked={setInventoryLoadCategory}
											retailer={retailer}
											onClose={() => setCategoryOpen(false)}
											onTreeBuild={(tree) => setCategoryTree(tree)}
											onChange={(value, tree) => {
												setCategoryTree(tree);
												setInventoryLoadCategory(value);
											}}
										/>

										<div
											onClick={() => setCategoryOpen(true)}
											ref={inputRefCategory}
										>
											<Input
												label={"Category"}
												invalidFeedback={
													validatedForm.formErrors.inventoryLoadCategoryError
												}
												placeholder={"Select Category"}
												id={"category" + prefixId}
												name="category"
												error={
													validatedForm.formErrors.inventoryLoadCategoryError
														.length === 1
												}
											/>
										</div>

										<div className={styles.selectedCategoryWrapper}>
											{categoryTreeToFlat(categoryTree)}
										</div>
									</div>
								</>
							</FormGroup>

							{/* Images Gallery Upload: */}
							<FormGroup
								title="Sample Images Gallery Upload"
								childrenClassName={styles.secondFlex}
								className={styles.newLoadFormGroup}
							>
								<Gallery
									maxImages={10}
									onFileUpload={onFileUploadSample}
									onFileRemove={onFileRemove}
									images={gallery.filter((img) => !img.isActualImage)}
									onUploadError={(error: string) => {
										if (error) alert(error);
									}}
								/>
								<div className={styles.invalidFeedback} ref={inputRefImg}>
									{validatedForm.formErrors.galleryError}
								</div>
							</FormGroup>
							<FormGroup
								title="Real Images Gallery Upload"
								childrenClassName={styles.secondFlex}
								className={styles.newLoadFormGroup}
							>
								<Gallery
									maxImages={10}
									onFileUpload={onFileUploadActual}
									onFileRemove={onFileRemove}
									images={gallery.filter((img) => img.isActualImage)}
									onUploadError={(error: string) => {
										if (error) alert(error);
									}}
								/>
							</FormGroup>

							{/* Title and Description: */}
							<FormGroup
								title="Title and Description"
								// className={styles.titleNDescription}
								className={styles.newLoadFormGroup}
								childrenClassName={styles.secondFlex}
							>
								<Input
									label="Alternate Title"
									value={title}
									onChange={setTitle}
								/>
								<div ref={inputRefDescription}>
									<Input
										label="Description and Detail"
										invalidFeedback={validatedForm.formErrors.descriptionError}
										inputElement="textarea"
										value={description}
										onChange={setDescription}
										error={
											validatedForm.formErrors.descriptionError.length === 1
										}
									/>
								</div>
							</FormGroup>

							{/* Upload / Skip Manifest: */}
							<FormGroup
								title="Upload / Skip Manifest"
								className={[styles.newLoadFormGroup, styles.noMargin].join(" ")}
								childrenClassName={styles.twoRadiosAndInput}
							>
								<RadioGroup
									name="manifest"
									options={uploadManifestOptions}
									onChange={(val: any) => setHasManifestFile(val === "yes")}
									value={
										typeof hasManifestFile === "boolean"
											? hasManifestFile
												? "yes"
												: "no"
											: hasManifestFile
									}
									invalidFeedback={validatedForm.formErrors.hasManifestFile}
								/>
								{hasManifestFile === true ? (
									<div className={styles.flexBox}>
										<UploadManifest
											manifestFile={manifestFile}
											onFileUpload={setManifestFile}
											removeFile={() => setManifestFile("")}
										/>

										<div className={styles.invalidFeedback}>
											{validatedForm.formErrors.manifestFileError}
										</div>
									</div>
								) : null}
							</FormGroup>

							{/* Retail Value and Wholesale Value: */}
							{typeof hasManifestFile !== "undefined" ? (
								<FormGroup
									title="Retail Value and Wholesale Value"
									className={styles.newLoadFormGroup}
								>
									<div className={styles.threeInputs}>
										<Input
											label={
												hasManifestFile
													? "Retail Value"
													: "Estimated Retail Value"
											}
											// invalidFeedback={
											// 	validatedForm.formErrors.estimatedRetailValueError
											// }
											placeholder="Enter Value"
											disabled={toggleState}
											value={
												estimatedRetailValue === 0 ? "" : estimatedRetailValue
											}
											onChange={(val) => (
												disableNegativeNumbers(setEstimatedRetailValue, val),
												setEstimatedRetailValueRemember(val)
											)}
											type="number"
											min="0"
											// error={
											// 	validatedForm.formErrors.estimatedRetailValueError
											// 		.length === 1
											// }
										/>
										<div className={styles.toggleWrapper}>
											<Toggle
												onChange={(e: any) => setToggleState(e.target.checked)}
												defaultChecked={toggleState}
											/>
										</div>
										<Input
											label={
												hasManifestFile
													? "Wholesale Value"
													: "Estimated Wholesale Value"
											}
											// invalidFeedback={
											// 	validatedForm.formErrors.wholeSalesValueError
											// }
											placeholder="Enter Value"
											disabled={!toggleState}
											value={wholeSalesValue === 0 ? "" : wholeSalesValue}
											onChange={(val) => (
												disableNegativeNumbers(setWholeSalesValue, val),
												setWholeSalesValueRemember(val)
											)}
											type="number"
											min="0"
											// error={
											// 	validatedForm.formErrors.wholeSalesValueError.length ===
											// 	1
											// }
										/>
									</div>
									<div className={styles.oneInput}>
										<Input
											label={
												hasManifestFile
													? "Piece Count"
													: "Estimated Piece Count"
											}
											invalidFeedback={
												validatedForm.formErrors.estimatedPieceCountError
											}
											placeholder="Enter Value"
											value={
												parseInt(estimatedPieceCount) === 0
													? ""
													: parseInt(estimatedPieceCount)
											}
											onChange={(val) => setEstimatedPieceCount(val.toString())}
											min="0"
											error={
												validatedForm.formErrors.estimatedPieceCountError
													.length === 1
											}
										/>
									</div>
								</FormGroup>
							) : null}

							{/* Selling Restrictions */}
							<FormGroup
								title="Selling Restrictions"
								className={styles.newLoadFormGroup}
							>
								<Input
									label="Restrictions (500 character Maximum)"
									invalidFeedback={
										validatedForm.formErrors.sellingRestrictionsError
									}
									inputElement="textarea"
									value={sellingRestrictions}
									onChange={setSellingRestrictions}
									error={
										validatedForm.formErrors.sellingRestrictionsError.length ===
										1
									}
								/>
							</FormGroup>

							<FormGroup title="Notes" className={styles.newLoadFormGroup}>
								<div className={styles.NotesAllert}>
									<div className={styles.orb}></div>
									<p>Notes not visible in public interfaces</p>
								</div>
								<Input
									// invalidFeedback={
									// 	validatedForm.formErrors.sellingRestrictionsError
									// }
									inputElement="textarea"
									value={notesField}
									onChange={setNotesField}
									// error={
									// 	validatedForm.formErrors.sellingRestrictionsError.length ===
									// 	1
									// }
								/>
							</FormGroup>
						</>
					)}
				</FormContainer>
			</Popup>
		</div>
	);
}

export default NewLoadForm;
