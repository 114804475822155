import styles from "./hubspotForm.module.scss";
// @ts-ignore
import HubspotForm from "react-hubspot-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useScrollLock } from "../../hooks/useScroll";
import Popup from "../common/popup/popup";
import { useEffect } from "react";
import { useRouter } from "next/router";

export default function HubspotFormBox({
	closeForm,
	title,
	children,
	id,
}: any) {
	const { lockScroll, unlockScroll } = useScrollLock();

	useEffect(() => {
		window.addEventListener("message", (event) => {
			if (
				event.data.type === "hsFormCallback" &&
				event.data.eventName === "onFormReady"
			) {
				//@ts-ignore
				let result = document
					.getElementsByClassName("hs-form-iframe")[0]
					//@ts-ignore
					?.contentWindow.document.querySelectorAll(
						'[name="requested_load_id"]',
					)[0];
				result.value = window.location.href;
			}
		});

		return () => {
			window.removeEventListener("message", (event) => {});
		};
	});

	return (
		<Popup>
			{closeForm && (
				<div className={styles.containerMain}>
					<div className={styles.containerMainHeadert}>
						<div className={styles.containerMainTitle}>{title}</div>
						<div onClick={() => (closeForm(), unlockScroll())}>
							<FontAwesomeIcon className={styles.closeBtn} icon={faTimes} />
						</div>
					</div>
					<div className={styles.containerBody}>{children}</div>
				</div>
			)}
		</Popup>
	);
}
