import { CircularProgress } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";
import { gql, useQuery } from "urql";
import styles from "..//..//../pages/inventory/inventory.module.scss";

import Badge from "../common/badge/badge";
import InventoryCard from "../inventory-card/inventory-card";

import Schema from "../layout-new/schema";

import {
	GetItemsListQuery,
	GetItemsListQueryVariables,
} from "./all-items-list.graphql.module";
import Loader from "../loader/loader";
import Button from "../common/button/button";
import Link from "next/link";
import { PaginationComponent } from "../pagination/pagination";
import {
	IQueryParameters,
	parametersToSearchData,
} from "../../../pages/inventory/InventoryPage";
import useWindowSize from "../../hooks/useWindowSize";
import CartCategories from "../cart/cartCategories";
import NoResult from "../noResult/noResult";

type ItemsListProps = {
	setMinValue: (value: number) => void;
	searchParameters: IQueryParameters;
	rand?: number;
	categoriesList?: boolean;
	setDate?: any;
};

export const GET_ITEMS = gql`
	query getItemsList($searchData: ItemSearchFilters!) {
		list(searchData: $searchData) {
			data {
				listingStatus
				authorizedTitle
				availableForExport
				availableLoadsQuantity
				deliveryType
				title
				description
				compiledTitle
				estimatedRetailValue
				wholeSalesValue
				estimatedValueType
				palletValueType
				gallery {
					id
					path
					isActualImage
					thumbnail
				}
				id
				inventoryLoadCategory
				inventoryLoadFOB {
					city
					state
					zipCode
					street
				}
				palletCount
				manifestFile
				sellingRestrictions
				retailer
				inventoryLoadCondition
				inventoryLoadSize
				cost
				discount
				estimatedPieceCount
				palletSpace
				width
				height
				length
				weight
				notesField
				leadTimeField
				palletHeight
				canBeSoldByThirdParty
			}
			totalCount
			success
		}
	}
`;

const AllItemsList: FC<ItemsListProps> = ({
	searchParameters,
	setMinValue,
	rand = 0,
	categoriesList = false,
	setDate,
}) => {
	const router = useRouter();
	const { width } = useWindowSize();

	const [getItemQueryResult, GetItem] = useQuery<
		GetItemsListQuery,
		GetItemsListQueryVariables
	>({
		query: GET_ITEMS,
		requestPolicy: "cache-and-network",
		variables: {
			searchData: {
				...parametersToSearchData(searchParameters),
			},
		},
	});
	const [schema, setSchema] = useState<object>({});

	useEffect(() => {
		const minValue: number[] = [];
		const schemaItem: any = [];
		if (getItemQueryResult?.data?.list?.data) {
			const { data = [] } = getItemQueryResult?.data?.list;
			for (const { wholeSalesValue } of data) {
				if (wholeSalesValue) minValue.push(wholeSalesValue);
			}

			data.forEach((ele, key) => {
				const imageGalley: string[] = [];

				for (const { path } of ele?.gallery || []) {
					imageGalley.push(`${process.env.FILE_SERVER_DOMAIN}/${path}`);
				}

				schemaItem.push({
					"@type": "ListItem",
					position: key,
					item: {
						"@type": "Product",
						url: "/inventory/" + ele.id,
						name: ele.title,
						image: imageGalley,
						description: ele.description,
						weight: ele.weight,
						width: ele.width,
						height: ele.height,
						length: ele.length,
						sku: ele.palletCount,
						offers: {
							"@type": "Offer",
							price: ele.cost,
							priceCurrency: "USD",
							discount: ele.discount,
						},
					},
				});
			});
		}

		setMinValue(Math.max(...minValue));

		setSchema({
			"@context": "https://schema.org",
			"@type": "ItemList",
			itemListElement: schemaItem,
		});
	}, [getItemQueryResult]);

	useEffect(() => {
		if (rand !== 0) GetItem();
	}, [rand]);

	if (!getItemQueryResult?.data?.list?.data) {
		return <Loader />;
	}

	const data = getItemQueryResult?.data?.list?.data;
	const totalCount = getItemQueryResult?.data?.list?.totalCount;

	if (categoriesList) {
		setDate(data || [""]);
	}

	const renderCards = (ele: any) => {
		return (
			<CartCategories
				imagePath={
					ele.gallery[0]?.path
						? `${process.env.FILE_SERVER_DOMAIN}/${ele.gallery[0]?.path}`
						: "/img/thinkbig.png"
				}
				title={ele.title || "No Title"}
				categories={ele.inventoryLoadCategory}
				onBoxClick={() => router.push(`/inventory/${ele.id}`)}
				cost={ele.cost}
				discountI={ele.discount}
				qty={ele.availableLoadsQuantity}
				condition={ele.inventoryLoadCondition}
				size={ele.inventoryLoadSize}
				retailer={ele.retailer}
				palletHeight={ele.palletHeight}
				location={ele.inventoryLoadFOB.state}
				estValue={ele.wholeSalesValue}
				deliveryType={ele.deliveryType}
				allImage={ele.gallery}
			/>
		);
	};

	const noResult = () => {
		if (categoriesList) {
			return <></>;
		}
		return <NoResult />;
	};

	return (
		<div className={styles.containetItemsBoxInventari}>
			<Schema siteTitle="items-list" schema={schema}></Schema>
			{data.length ? (
				<div className={styles.containetItems}>
					{data?.map((ele) => renderCards(ele))}
				</div>
			) : (
				noResult()
			)}

			{!categoriesList && (
				<div className={styles.containetItemspagination}>
					{data.length ? (
						<PaginationComponent
							skip={searchParameters.skip}
							take={searchParameters.take}
							totalCount={totalCount || 0}
						/>
					) : (
						<div></div>
					)}
				</div>
			)}
		</div>
	);
};

export default AllItemsList;
