import React from "react";
import styles from "./icon.module.scss";
import { getSvg, IconData } from "./icon-data";

interface IconProps
	extends React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {
	name: IconData;
	className?: string;
}

// TODO: optimized this component
function IconComponent({ className, name, onClick }: IconProps): JSX.Element {
	return (
		<div
			className={`${className ? className : ""} ${styles.iconWindow}`}
			onClick={onClick}
		>
			{getSvg(name)}
		</div>
	);
}

export default IconComponent;
