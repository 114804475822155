export enum IconData {
	CHEVRON_DOWN = "CHEVRON_DOWN",
	CHEVRON_LEFT = "CHEVRON_LEFT",
	CIRCLE_CHEVRON_LEFT = "CIRCLE_CHEVRON_LEFT",
	CIRCLE_CHEVRON_RIGHT = "CIRCLE_CHEVRON_RIGHT",
	CLOSE = "CLOSE",
	COPY = "COPY",
	DELETE = "DELETE",
	EDIT = "EDIT",
	INFO = "INFO",
	INFO_BIG = "INFO_BIG",
	QUESTION = "QUESTION",
	SEARCH = "SEARCH",
	UPLOAD_FILE = "UPLOAD_FILE",
	LINK = "LINK",
}

export const getSvg = (name: IconData) => {
	switch (name) {
		case IconData.CIRCLE_CHEVRON_LEFT:
			return (
				<svg
					width="46"
					height="46"
					viewBox="0 0 46 46"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle
						r="22.5"
						transform="matrix(-1 0 0 1 23 23)"
						stroke="#363636"
					/>
					<path
						d="M19.8024 22.4765C19.8955 22.3934 19.9704 22.3349 20.0366 22.2666C22.2551 19.9807 24.4719 17.6927 26.687 15.4025C26.8269 15.2549 26.9283 15.0733 26.9818 14.8745C27.0586 14.575 26.8831 14.2556 26.6208 14.1018C26.4914 14.0251 26.3426 13.9902 26.1937 14.0017C26.0448 14.0132 25.9027 14.0705 25.7858 14.1661C25.72 14.2234 25.6575 14.2844 25.5984 14.349L18.3484 21.8242C17.8844 22.3027 17.8838 22.6922 18.3484 23.1687C20.7763 25.6714 23.2055 28.1728 25.6359 30.6729C25.7791 30.8171 25.9546 30.9226 26.1468 30.9801C26.436 31.0618 26.7476 30.8828 26.8994 30.6137C26.974 30.4804 27.0083 30.3272 26.998 30.1737C26.9876 30.0202 26.9331 29.8734 26.8413 29.7521C26.781 29.6783 26.7166 29.6082 26.6483 29.5422L20.0085 22.698C19.9486 22.6368 19.8917 22.5725 19.8024 22.4765Z"
						fill="black"
					/>
				</svg>
			);

		case IconData.CIRCLE_CHEVRON_RIGHT:
			return (
				<svg
					width="46"
					height="46"
					viewBox="0 0 46 46"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="23" cy="23" r="22.5" stroke="#363636" />
					<path
						d="M26.1976 22.4765C26.1045 22.3934 26.0296 22.3349 25.9634 22.2666C23.7449 19.9807 21.5281 17.6927 19.313 15.4025C19.1731 15.2549 19.0717 15.0733 19.0182 14.8745C18.9414 14.575 19.1169 14.2556 19.3792 14.1018C19.5086 14.0251 19.6574 13.9902 19.8063 14.0017C19.9552 14.0132 20.0973 14.0705 20.2142 14.1661C20.28 14.2234 20.3425 14.2844 20.4016 14.349L27.6516 21.8242C28.1156 22.3027 28.1162 22.6922 27.6516 23.1687C25.2237 25.6714 22.7945 28.1728 20.3641 30.6729C20.2209 30.8171 20.0454 30.9226 19.8532 30.9801C19.564 31.0618 19.2524 30.8828 19.1006 30.6137C19.026 30.4804 18.9917 30.3272 19.002 30.1737C19.0124 30.0202 19.0669 29.8734 19.1587 29.7521C19.219 29.6783 19.2834 29.6082 19.3517 29.5422L25.9915 22.698C26.0514 22.6368 26.1083 22.5725 26.1976 22.4765Z"
						fill="black"
					/>
				</svg>
			);

		case IconData.CHEVRON_DOWN:
			return (
				<svg
					width="15"
					height="9"
					viewBox="0 0 15 9"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M14.0019 1.11979C13.9398 1.42572 13.7485 1.64524 13.5362 1.85931C11.607 3.78906 9.6796 5.72102 7.754 7.65518C7.45091 7.95955 7.09816 8.08721 6.68603 7.94008C6.53295 7.88254 6.39399 7.79268 6.27855 7.67658C4.28982 5.69027 2.30446 3.70071 0.322458 1.70791C0.0217039 1.40587 -0.0807473 1.0439 0.0694357 0.640277C0.210693 0.261177 0.499805 0.0463281 0.903009 0.00623853C1.22899 -0.0260667 1.49676 0.104711 1.72378 0.333572C2.18015 0.793629 2.63755 1.25265 3.09599 1.71063C4.35877 2.97767 5.62143 4.24458 6.88395 5.51136C6.92951 5.56565 6.98166 5.61403 7.03917 5.65537C7.06634 5.60749 7.08419 5.55067 7.12183 5.51291C8.83736 3.78919 10.5539 2.06638 12.2715 0.34447C12.5657 0.0494418 12.9068 -0.0770545 13.315 0.0509987C13.6368 0.151806 13.8498 0.368991 13.953 0.692432C13.9693 0.743809 13.986 0.795186 14.0027 0.84812L14.0019 1.11979Z"
						fill="#FCB53B"
					/>
				</svg>
			);

		case IconData.CHEVRON_LEFT:
			return (
				<svg
					width="8"
					height="14"
					viewBox="0 0 8 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.8809 -6.77817e-06C6.57498 0.0620843 6.35546 0.253014 6.14139 0.465677C4.21164 2.39516 2.27969 4.32257 0.345526 6.24791C0.0411572 6.551 -0.0865069 6.90336 0.0606179 7.31549C0.118071 7.46861 0.207946 7.60759 0.32412 7.72296C2.31017 9.71117 4.30012 11.6964 6.29396 13.6787C6.59599 13.9794 6.95797 14.0815 7.36159 13.9313C7.74068 13.79 7.95553 13.5009 7.99562 13.0977C8.02793 12.7721 7.89715 12.5044 7.66829 12.277C7.20823 11.8206 6.74921 11.3633 6.29123 10.9051C5.02446 9.64236 3.75755 8.37958 2.49051 7.1168C2.45548 7.08148 2.41267 7.05161 2.32509 6.97554C2.39437 6.93674 2.4512 6.91811 2.48896 6.88085C4.21268 5.16533 5.93549 3.44864 7.65739 1.73078C7.95242 1.43663 8.07853 1.09552 7.95086 0.687267C7.85006 0.365558 7.63287 0.152506 7.30943 0.0496673C7.25805 0.0329803 7.20629 0.0162922 7.15374 -6.76624e-06L6.8809 -6.77817e-06Z"
						fill="#CACACA"
					/>
				</svg>
			);

		case IconData.CLOSE:
			return (
				<svg
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						opacity="0.3"
						d="M11.9993 11.3587C11.9869 11.3908 11.9733 11.4225 11.9628 11.455C11.9242 11.5785 11.8553 11.6904 11.7625 11.7805C11.6696 11.8707 11.5556 11.9361 11.431 11.971C11.3063 12.0059 11.1749 12.009 11.0487 11.9802C10.9225 11.9513 10.8056 11.8914 10.7085 11.8058C10.6719 11.7751 10.6405 11.7416 10.6073 11.7083C9.10779 10.2103 7.60877 8.712 6.11025 7.21346C6.07654 7.17544 6.04496 7.13559 6.01565 7.0941C5.96525 7.14191 5.93164 7.17258 5.89986 7.2036C4.39013 8.71236 2.88041 10.2219 1.37068 11.7321C1.12195 11.981 0.83376 12.0606 0.505395 11.9449C-0.000849729 11.7624 -0.161561 11.1302 0.186893 10.7206C0.223419 10.6793 0.261774 10.641 0.300491 10.6027C1.7995 9.10416 3.29875 7.6061 4.79825 6.10854C4.84146 6.07093 4.8867 6.03571 4.93376 6.00304C4.88385 5.96932 4.83593 5.93275 4.79022 5.89354C3.2822 4.38794 1.77466 2.88161 0.267613 1.37455C-0.10714 1.00004 -0.087781 0.454333 0.322401 0.16633C0.438918 0.0845645 0.591598 0.0542675 0.727474 0.000244141H0.821347C1.10661 0.0367464 1.30385 0.209037 1.49817 0.403959C2.96431 1.87549 4.433 3.34434 5.90425 4.81051C5.9342 4.84008 5.96524 4.86855 6.02332 4.92331C6.04735 4.8809 6.0751 4.84071 6.10623 4.80321C7.57164 3.33704 9.03729 1.87062 10.5032 0.403959C10.6971 0.209402 10.894 0.0349213 11.1796 0.000244141H11.2735C11.4149 0.0582828 11.5657 0.0998952 11.6957 0.17801C11.8696 0.282772 11.9405 0.470028 12 0.65619V0.913896C11.9427 1.15116 11.7896 1.32236 11.6216 1.4899C10.1452 2.9607 8.67045 4.43296 7.19725 5.90668C7.15556 5.93887 7.11064 5.96664 7.0632 5.98954C7.13625 6.0505 7.16986 6.07605 7.20017 6.10635C8.67678 7.58055 10.1539 9.05561 11.6315 10.5315C11.7973 10.6969 11.9445 10.8673 11.9993 11.101V11.3587Z"
						fill="black"
					/>
				</svg>
			);

		case IconData.COPY:
			return (
				<svg
					width="16"
					height="17"
					viewBox="0 0 16 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4.80486 11.7002H4.58095C3.87514 11.7002 3.16884 11.7002 2.46254 11.7002C1.04443 11.6947 0.00400739 10.6541 0.00200369 9.23479C-0.000667898 7.14761 -0.000667898 5.0601 0.00200369 2.97226C0.00200369 1.55242 1.04392 0.506828 2.45954 0.504824C4.55941 0.501484 6.65928 0.501484 8.75915 0.504824C9.98441 0.507329 10.9537 1.32146 11.1691 2.52386C11.1919 2.67824 11.2015 2.83429 11.1976 2.9903C11.2007 3.68418 11.1976 4.37807 11.1976 5.07146V5.29691C11.2768 5.30092 11.3424 5.30693 11.408 5.30693C12.1329 5.30693 12.8582 5.30242 13.583 5.30693C14.7903 5.31695 15.7475 6.12406 15.9649 7.31094C15.9882 7.4535 15.9986 7.59787 15.996 7.7423C15.998 9.84851 15.998 11.9547 15.996 14.0609C15.996 15.2889 15.1815 16.2548 13.9788 16.4708C13.8368 16.4928 13.6931 16.5024 13.5495 16.4993C11.4489 16.5013 9.34891 16.5013 7.24937 16.4993C6.01158 16.4993 5.0453 15.6832 4.83091 14.4698C4.80899 14.3151 4.80011 14.1589 4.80436 14.0028C4.80336 13.2453 4.80486 12.4893 4.80486 11.7002ZM10.4197 6.89661H7.30848C6.70436 6.89661 6.3998 7.20172 6.3998 7.81044C6.3998 9.87189 6.3998 11.9335 6.3998 13.9953C6.3998 14.6035 6.70319 14.9073 7.30998 14.9066C9.37178 14.9066 11.4336 14.9066 13.4954 14.9066C14.1005 14.9066 14.4036 14.6024 14.4046 13.9938C14.4046 11.932 14.4046 9.87022 14.4046 7.80843C14.4046 7.20222 14.0985 6.89661 13.4939 6.89661H10.4197ZM9.60471 5.30743C9.60471 4.51334 9.60471 3.75432 9.60471 2.9903C9.60471 2.41064 9.29163 2.09851 8.71106 2.09851C6.63657 2.09851 4.56241 2.09851 2.48859 2.09851C1.90902 2.09851 1.59694 2.41114 1.59644 2.9918C1.59644 5.06595 1.59644 7.1401 1.59644 9.21424C1.59644 9.7929 1.91002 10.104 2.49109 10.1055C3.19706 10.1055 3.90303 10.1055 4.609 10.1055C4.66911 10.1055 4.72922 10.099 4.80486 10.0945V9.87457C4.80486 9.18118 4.80486 8.48779 4.80486 7.7944C4.80486 6.33749 5.83626 5.31044 7.29695 5.30743C7.99023 5.30743 8.68368 5.30743 9.37729 5.30743H9.60471Z"
						fill="white"
					/>
				</svg>
			);

		case IconData.DELETE:
			return (
				<svg
					width="14"
					height="17"
					viewBox="0 0 14 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0.725874 9.68154C0.725874 8.34153 0.725874 7.00153 0.725874 5.66152C0.725874 5.23603 0.956133 4.94479 1.33566 4.87836C1.43514 4.86126 1.53711 4.86518 1.63498 4.88988C1.73286 4.91457 1.82449 4.95948 1.90396 5.02172C1.98343 5.08396 2.04899 5.16215 2.09642 5.25126C2.14385 5.34037 2.1721 5.43842 2.17934 5.53911C2.18389 5.60145 2.18162 5.66425 2.18162 5.72659V13.5792C2.18162 14.4497 2.7732 15.0422 3.64737 15.0422H9.43713C10.3086 15.0422 10.9024 14.4506 10.9024 13.5792C10.9024 10.9398 10.9024 8.29952 10.9024 5.65833C10.9024 5.24878 11.1213 4.95708 11.4785 4.88473C11.5744 4.86326 11.6737 4.86176 11.7702 4.88031C11.8667 4.89886 11.9583 4.93707 12.0394 4.99256C12.1205 5.04805 12.1894 5.11963 12.2416 5.20285C12.2939 5.28607 12.3284 5.37915 12.3432 5.47631C12.3551 5.55476 12.3604 5.63406 12.3591 5.7134C12.3591 8.34275 12.3591 10.9718 12.3591 13.6005C12.3591 15.0413 11.3953 16.199 9.97956 16.4565C9.81168 16.4853 9.64158 16.4992 9.47126 16.4979C7.51813 16.5007 5.56515 16.5007 3.61233 16.4979C1.98958 16.4979 0.727238 15.236 0.725418 13.616C0.724205 12.3045 0.724357 10.993 0.725874 9.68154Z"
						fill="white"
					/>
					<path
						d="M9.44722 2.68176H9.96554C10.7487 2.68176 11.5319 2.67812 12.315 2.68176C12.8465 2.6854 13.196 3.13683 13.0518 3.61874C13.0098 3.75823 12.927 3.88192 12.814 3.97384C12.701 4.06575 12.563 4.12166 12.4179 4.13433C12.3787 4.13751 12.3387 4.13706 12.2991 4.13706H0.791017C0.328673 4.13706 0.00102821 3.82898 0.00466871 3.40304C0.00830921 2.98575 0.332315 2.68313 0.788743 2.68176C1.66246 2.67903 2.53664 2.68176 3.41082 2.68176H3.64108V2.49018C3.64108 2.08063 3.63835 1.67107 3.64108 1.26424C3.64517 0.809181 3.94096 0.503835 4.3983 0.50247C5.82841 0.498526 7.25868 0.498526 8.68909 0.50247C9.14734 0.50247 9.44404 0.805541 9.44768 1.26242C9.45041 1.72704 9.44722 2.19121 9.44722 2.68176ZM5.09773 2.6672H7.98602V1.96686H5.09773V2.6672Z"
						fill="white"
					/>
					<path
						d="M4.3628 9.57278C4.3628 8.72728 4.36007 7.88177 4.3628 7.03627C4.36346 6.86696 4.42377 6.70331 4.53313 6.57406C4.64248 6.44481 4.7939 6.35824 4.96076 6.32956C5.11844 6.30052 5.2813 6.3254 5.42312 6.40019C5.56494 6.47498 5.67745 6.59532 5.74255 6.74184C5.78995 6.85737 5.81437 6.98102 5.81445 7.10589C5.819 8.76262 5.819 10.4197 5.81445 12.077C5.81445 12.5402 5.50228 12.8661 5.0768 12.8615C4.65905 12.8574 4.36007 12.5316 4.35962 12.0761C4.36083 11.2415 4.36189 10.4071 4.3628 9.57278Z"
						fill="white"
					/>
					<path
						d="M8.72323 9.59418C8.72323 10.4397 8.7255 11.2852 8.72323 12.1307C8.72602 12.2911 8.67456 12.4478 8.57718 12.5753C8.4798 12.7029 8.34221 12.7938 8.18671 12.8333C7.8759 12.9193 7.53551 12.7942 7.38261 12.5089C7.31361 12.3794 7.27619 12.2355 7.2734 12.0888C7.26612 10.4203 7.2649 8.7517 7.26976 7.08314C7.26976 6.63444 7.5883 6.31362 8.0106 6.31954C8.42016 6.32591 8.72323 6.649 8.72459 7.09315C8.7252 7.92622 8.72474 8.75989 8.72323 9.59418Z"
						fill="white"
					/>
				</svg>
			);

		case IconData.EDIT:
			return (
				<svg
					width="16"
					height="17"
					viewBox="0 0 16 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M16.0001 4.46943C15.9216 4.84966 15.6816 5.12095 15.4158 5.38765C12.0145 8.78229 8.61649 12.1816 5.22186 15.5857C4.91953 15.8966 4.51596 16.0891 4.0841 16.1283C3.15879 16.2335 2.23556 16.3583 1.31316 16.4873C1.13771 16.5147 0.958277 16.5006 0.789259 16.4461C0.620242 16.3916 0.466349 16.2983 0.339931 16.1736C0.213514 16.0489 0.118092 15.8962 0.0613229 15.728C0.00455427 15.5597 -0.0119784 15.3805 0.0130489 15.2047C0.147999 14.215 0.278219 13.2245 0.403709 12.2334C0.443753 11.9006 0.59771 11.592 0.839446 11.3598C3.43495 8.76962 6.0285 6.17774 8.62011 3.58418C9.45235 2.75236 10.2896 1.92387 11.1147 1.08579C11.3785 0.818251 11.6515 0.580348 12.033 0.501465H12.4086C12.7913 0.584939 13.0655 0.829938 13.331 1.1004C14.0116 1.79379 14.6986 2.48093 15.392 3.1618C15.6658 3.43059 15.9179 3.70439 16.0026 4.09463L16.0001 4.46943ZM1.63496 14.8562C1.68254 14.8537 1.70758 14.8537 1.73179 14.8503C2.44425 14.7585 3.15587 14.6633 3.86874 14.5765C3.98096 14.563 4.08462 14.5097 4.1609 14.4263C6.6017 11.9835 9.04319 9.54163 11.4854 7.10055C11.5246 7.06173 11.5609 7.02 11.5868 6.99203L9.50786 4.91352C9.48032 4.93939 9.44233 4.9732 9.40644 5.00909L6.09167 8.32345C4.74411 9.67101 3.39683 11.019 2.04983 12.3674C2.00475 12.4129 1.95091 12.4717 1.94298 12.5302C1.83655 13.2977 1.73805 14.0665 1.63496 14.8562ZM12.2254 2.2561L10.6844 3.80038L12.7175 5.83633C13.2292 5.32421 13.75 4.80291 14.2617 4.29205L12.2254 2.2561Z"
						fill="white"
					/>
				</svg>
			);

		case IconData.INFO:
			return (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 10.586V9.41709C0.0159447 9.27667 0.0326491 9.13626 0.0478345 8.99584C0.287765 6.84404 1.11081 4.94575 2.56102 3.33588C4.10083 1.62734 6.00965 0.560171 8.28063 0.158653C8.66027 0.09186 9.0399 0.0539056 9.41954 0.00305176H10.5919C10.9267 0.0447975 11.263 0.0789571 11.5956 0.13057C13.6844 0.479716 15.5059 1.37839 17.0039 2.87516C19.2514 5.12032 20.2407 7.84897 19.9529 11.0087C19.7601 13.134 18.9302 15.0049 17.5111 16.5974C15.6266 18.7127 13.2592 19.8528 10.4256 19.9901C8.48718 20.0996 6.5617 19.6133 4.90794 18.5966C2.31199 17.0141 0.718273 14.7203 0.15793 11.7245C0.0857991 11.3465 0.0516305 10.9647 0 10.586ZM10.0102 1.82013C5.51915 1.80874 1.85111 5.46339 1.82149 9.98407C1.79112 14.4668 5.48423 18.1685 9.99811 18.1814C14.4892 18.1928 18.1565 14.5382 18.1869 10.0167C18.2165 5.53398 14.5211 1.83227 10.0102 1.82013Z"
						fill="black"
					/>
					<path
						d="M9.10075 12.1101C9.10075 11.1932 9.09467 10.2755 9.10075 9.35863C9.10075 8.97913 9.30804 8.70361 9.65654 8.55939C10.0051 8.41518 10.3353 8.47969 10.6178 8.73169C10.7163 8.8223 10.7935 8.93364 10.8438 9.05767C10.8941 9.1817 10.9162 9.31534 10.9086 9.44896C10.9086 10.1518 10.9086 10.8539 10.9086 11.5567C10.9086 12.6368 10.9086 13.7169 10.9086 14.7962C10.9086 15.3457 10.5441 15.7343 10.0362 15.751C9.50469 15.7685 9.10378 15.3776 9.09923 14.8228C9.0924 13.9188 9.09923 13.014 9.09923 12.1101H9.10075Z"
						fill="black"
					/>
					<path
						d="M10.0103 4.24817C10.3319 4.25098 10.6393 4.3812 10.8651 4.61029C11.0908 4.83938 11.2165 5.14861 11.2145 5.47018C11.2077 5.78833 11.077 6.09128 10.85 6.31445C10.6231 6.53761 10.318 6.66335 9.99963 6.66487C9.6776 6.66027 9.37042 6.52874 9.14491 6.29889C8.9194 6.06905 8.79379 5.75949 8.79543 5.43754C8.80198 5.11982 8.93286 4.81732 9.15998 4.59496C9.3871 4.3726 9.69236 4.2481 10.0103 4.24817Z"
						fill="black"
					/>
				</svg>
			);

		case IconData.INFO_BIG:
			return (
				<svg
					width="50"
					height="51"
					viewBox="0 0 50 51"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M49.9981 23.9831V26.9131C49.9677 27.0858 49.9336 27.2584 49.9089 27.433C49.7514 28.509 49.6622 29.6002 49.4326 30.6591C48.127 36.6633 45.0433 41.584 40.153 45.2902C34.069 49.8977 27.2165 51.4273 19.7453 49.8693C13.7657 48.6225 8.9039 45.4875 5.18446 40.6541C1.16897 35.4336 -0.508603 29.4882 0.127119 22.9469C0.597859 17.7513 2.73755 12.8475 6.22628 8.96864C10.0994 4.5888 14.9214 1.86183 20.6885 0.835188C21.6373 0.668193 22.5861 0.573311 23.535 0.446167H26.4669C26.5915 0.481235 26.7183 0.507866 26.8464 0.525872C30.7085 0.769401 34.4531 1.95028 37.7562 3.96636C44.2329 7.9363 48.22 13.6597 49.6185 21.146C49.7855 22.0854 49.8728 23.0342 49.9981 23.9831ZM25.0133 5.00628C13.7885 4.97782 4.62083 14.1151 4.54682 25.4177C4.47091 36.6254 13.7012 45.8803 24.981 45.9126C36.2076 45.943 45.3734 36.8038 45.4493 25.5012C45.5234 14.2859 36.2931 5.03095 25.0133 5.00628Z"
						fill="#FCB53B"
					/>
					<path
						d="M27.2544 30.7559C27.2544 33.0654 27.2848 35.3749 27.2431 37.6843C27.2331 38.0398 27.1379 38.3877 26.9657 38.6988C26.7935 39.01 26.5492 39.2752 26.2533 39.4725C25.9573 39.6697 25.6184 39.7931 25.265 39.8323C24.9116 39.8715 24.5539 39.8253 24.2219 39.6978C23.8095 39.5599 23.4482 39.3009 23.1852 38.9546C22.9222 38.6083 22.7696 38.1907 22.7474 37.7564C22.7357 37.6271 22.7307 37.4973 22.7323 37.3674C22.7323 32.9597 22.7323 28.5521 22.7323 24.1444C22.7323 22.8483 23.423 21.9412 24.5483 21.7211C24.8674 21.6526 25.1976 21.6549 25.5156 21.728C25.8337 21.8011 26.1318 21.9431 26.389 22.1439C26.6462 22.3448 26.8561 22.5997 27.004 22.8906C27.1519 23.1815 27.2342 23.5013 27.245 23.8275C27.281 24.8503 27.2601 25.877 27.2601 26.9017C27.2601 28.1858 27.2601 29.4705 27.2601 30.7559H27.2544Z"
						fill="#FCB53B"
					/>
					<path
						d="M24.9848 11.0789C25.7901 11.0789 26.5624 11.3987 27.1318 11.9682C27.7012 12.5376 28.0211 13.3099 28.0211 14.1151C28.0113 14.9115 27.6907 15.6725 27.1277 16.2358C26.5647 16.7991 25.8039 17.1202 25.0076 17.1306C24.2039 17.1256 23.4345 16.8041 22.8661 16.2357C22.2978 15.6674 21.9763 14.898 21.9713 14.0943C21.9768 13.2965 22.296 12.533 22.8599 11.9688C23.4238 11.4045 24.1871 11.0848 24.9848 11.0789Z"
						fill="#FCB53B"
					/>
				</svg>
			);

		case IconData.QUESTION:
			return (
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 10.5865V9.4142C0.016395 9.27372 0.0323221 9.13324 0.0477802 8.99276C0.287381 6.84133 1.10947 4.94275 2.56043 3.33357C4.10132 1.62464 6.00969 0.557704 8.28063 0.155934C8.65725 0.0856944 9.03878 0.0512748 9.41751 0H10.5902C10.9247 0.0421437 11.2612 0.07024 11.5936 0.127836C13.6832 0.479034 15.5038 1.37599 17.0039 2.87209C19.2524 5.11976 20.2403 7.84645 19.955 11.0058C19.7625 13.1271 18.932 15.0017 17.5126 16.5948C15.6289 18.7097 13.2574 19.8497 10.4279 19.9874C8.48945 20.0967 6.56396 19.6104 4.91006 18.5938C2.3138 17.0106 0.720208 14.7173 0.1595 11.7216C0.0857226 11.3465 0.0512928 10.9651 0 10.5865ZM10.0049 18.3192C14.5763 18.3192 18.3179 14.581 18.3256 10.0091C18.3333 5.43724 14.5798 1.67802 10.0028 1.68153C5.4258 1.68505 1.68985 5.41968 1.68212 9.99227C1.6744 14.5649 5.43142 18.3206 10.0049 18.3192Z"
						fill="#FCB53B"
					/>
					<path
						d="M9.17466 11.0985C9.17466 10.7536 9.1571 10.408 9.17958 10.0645C9.18801 9.87672 9.26467 9.69842 9.39518 9.56304C9.52569 9.42766 9.7011 9.3445 9.88855 9.32913C10.2722 9.28629 10.6432 9.2308 10.9889 9.03483C12.1082 8.40267 12.2635 6.74292 11.2699 5.92252C10.6593 5.41679 9.97427 5.28475 9.24774 5.59942C8.53315 5.90847 8.13405 6.47881 8.07573 7.26128C8.04903 7.61248 7.96612 7.91732 7.63728 8.09994C7.508 8.17754 7.35955 8.21736 7.20877 8.21487C7.05799 8.21239 6.91093 8.16771 6.78428 8.0859C6.49619 7.9117 6.37042 7.63988 6.38377 7.31326C6.47511 5.01432 8.43548 3.43253 10.7001 3.82798C12.2417 4.09629 13.491 5.52145 13.609 7.08007C13.673 7.91402 13.4475 8.74454 12.9705 9.43171C12.4935 10.1189 11.7942 10.6207 10.9903 10.8526C10.8694 10.8863 10.8413 10.9439 10.842 11.057C10.842 11.4216 10.8498 11.7868 10.8259 12.15C10.802 12.5131 10.5406 12.7877 10.1872 12.8741C9.86606 12.9521 9.48804 12.825 9.32011 12.5299C9.22927 12.3527 9.17661 12.1584 9.16553 11.9596C9.14023 11.6751 9.1585 11.3872 9.1585 11.1006L9.17466 11.0985Z"
						fill="#FCB53B"
					/>
					<path
						d="M9.99906 13.741C10.1536 13.7396 10.3069 13.7688 10.4501 13.8268C10.5933 13.8848 10.7237 13.9706 10.8336 14.0791C10.9436 14.1877 11.031 14.3169 11.0908 14.4593C11.1507 14.6018 11.1818 14.7546 11.1823 14.9091C11.1816 15.2211 11.0577 15.5202 10.8376 15.7415C10.6175 15.9627 10.3189 16.0881 10.0068 16.0905C9.69806 16.0878 9.40262 15.9646 9.18352 15.7471C8.96442 15.5297 8.83902 15.2353 8.83408 14.9267C8.83213 14.7722 8.86078 14.6189 8.91837 14.4755C8.97597 14.3322 9.06137 14.2016 9.16966 14.0914C9.27795 13.9812 9.40699 13.8935 9.54933 13.8334C9.69168 13.7732 9.84452 13.7418 9.99906 13.741Z"
						fill="#FCB53B"
					/>
				</svg>
			);

		case IconData.SEARCH:
			return (
				<svg
					width="30"
					height="30"
					viewBox="0 0 19 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M18.0335 19C17.8332 18.8518 17.6116 18.7249 17.4364 18.5511C15.7592 16.883 14.0843 15.2097 12.4117 13.5313C12.3636 13.4735 12.3209 13.4112 12.2844 13.3454C10.3521 14.8268 8.24238 15.363 5.91006 14.8607C3.97266 14.4425 2.42553 13.384 1.31466 11.7474C-0.793254 8.6471 -0.292875 4.65092 2.28336 2.15005C5.16391 -0.646351 9.4457 -0.646351 12.3267 1.74671C15.336 4.24572 16.0691 8.83343 13.3753 12.2483C13.4218 12.2976 13.4682 12.351 13.5198 12.4017C15.1924 14.0745 16.865 15.7498 18.5375 17.4275C18.7155 17.6069 18.846 17.8327 18.9989 18.0372V18.3713C18.9539 18.5202 18.8728 18.6557 18.7628 18.7657C18.6528 18.8757 18.5173 18.9568 18.3684 19.0019L18.0335 19ZM13.4613 7.51655C13.4394 4.23271 10.7763 1.57989 7.51806 1.59105C4.24167 1.60266 1.57995 4.27221 1.59342 7.53189C1.60689 10.8064 4.24167 13.4639 7.53246 13.4555C10.8581 13.4458 13.4394 10.7776 13.4613 7.51655Z"
						fill="#636363"
					/>
				</svg>
			);

		case IconData.UPLOAD_FILE:
			return (
				<svg
					width="30"
					height="28"
					viewBox="0 0 30 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.0056 28.0002H3.9905C1.59071 28.0002 0.00299425 26.3842 0.00195312 23.9434C0.00195312 22.3798 0.00195312 20.8154 0.00195312 19.2504C0.00195312 18.4021 0.503774 17.8253 1.24193 17.8211C1.98009 17.8168 2.50169 18.3926 2.50169 19.2324C2.50169 20.8228 2.50169 22.4133 2.50169 24.0038C2.50169 24.9486 2.99519 25.4522 3.92179 25.4522H26.0686C27.0181 25.4522 27.498 24.9549 27.498 23.9741C27.498 22.3964 27.498 20.8186 27.498 19.2408C27.498 18.3926 28.0051 17.8189 28.7474 17.8179C29.4897 17.8168 29.9967 18.3947 29.9967 19.2377C29.9967 20.8409 29.9967 22.4451 29.9967 24.0494C29.9874 26.3577 28.3747 28.0002 26.0977 28.0002C22.4011 28.0002 18.7037 28.0002 15.0056 28.0002Z"
						fill="#FCB53B"
					/>
					<path
						d="M13.7456 18.9609V18.4307C13.7456 12.7983 13.7456 7.16616 13.7456 1.53442C13.7387 1.32334 13.7593 1.11225 13.807 0.906712C13.8856 0.62306 14.0605 0.377014 14.3005 0.212363C14.5406 0.0477116 14.8302 -0.0248635 15.1178 0.00755588C15.4112 0.0393 15.6844 0.174562 15.8901 0.389904C16.0959 0.605247 16.2212 0.887101 16.2443 1.18664C16.2547 1.33084 16.2443 1.47716 16.2443 1.62349V18.9874C16.869 18.4837 17.4239 18.0331 17.9788 17.5846C18.8117 16.907 19.6446 16.2252 20.4775 15.5477C21.0845 15.0536 21.831 15.1055 22.287 15.6696C22.743 16.2337 22.6555 17.0194 22.0371 17.5252C19.9882 19.2019 17.9358 20.8737 15.8799 22.5405C15.2646 23.0399 14.7222 23.0367 14.11 22.5405C12.1069 20.9147 10.1051 19.2846 8.1048 17.6503C7.50615 17.1625 7.4062 16.3927 7.85493 15.8234C8.30365 15.254 9.05534 15.1872 9.66023 15.6749C10.9096 16.678 12.1433 17.6895 13.3843 18.6968C13.4884 18.7753 13.5915 18.8432 13.7456 18.9609Z"
						fill="#FCB53B"
					/>
					<path
						d="M15.0056 28.0002H3.9905C1.59071 28.0002 0.00299425 26.3842 0.00195312 23.9434C0.00195312 22.3798 0.00195312 20.8154 0.00195312 19.2504C0.00195312 18.4021 0.503774 17.8253 1.24193 17.8211C1.98009 17.8168 2.50169 18.3926 2.50169 19.2324C2.50169 20.8228 2.50169 22.4133 2.50169 24.0038C2.50169 24.9486 2.99519 25.4522 3.92179 25.4522H26.0686C27.0181 25.4522 27.498 24.9549 27.498 23.9741C27.498 22.3964 27.498 20.8186 27.498 19.2408C27.498 18.3926 28.0051 17.8189 28.7474 17.8179C29.4897 17.8168 29.9967 18.3947 29.9967 19.2377C29.9967 20.8409 29.9967 22.4451 29.9967 24.0494C29.9874 26.3577 28.3747 28.0002 26.0977 28.0002C22.4011 28.0002 18.7037 28.0002 15.0056 28.0002Z"
						fill="#FCB53B"
					/>
					<path
						d="M13.7456 18.9609V18.4307C13.7456 12.7983 13.7456 7.16616 13.7456 1.53442C13.7387 1.32334 13.7593 1.11225 13.807 0.906712C13.8856 0.62306 14.0605 0.377014 14.3005 0.212363C14.5406 0.0477116 14.8302 -0.0248635 15.1178 0.00755588C15.4112 0.0393 15.6844 0.174562 15.8901 0.389904C16.0959 0.605247 16.2212 0.887101 16.2443 1.18664C16.2547 1.33084 16.2443 1.47716 16.2443 1.62349V18.9874C16.869 18.4837 17.4239 18.0331 17.9788 17.5846C18.8117 16.907 19.6446 16.2252 20.4775 15.5477C21.0845 15.0536 21.831 15.1055 22.287 15.6696C22.743 16.2337 22.6555 17.0194 22.0371 17.5252C19.9882 19.2019 17.9358 20.8737 15.8799 22.5405C15.2646 23.0399 14.7222 23.0367 14.11 22.5405C12.1069 20.9147 10.1051 19.2846 8.1048 17.6503C7.50615 17.1625 7.4062 16.3927 7.85493 15.8234C8.30365 15.254 9.05534 15.1872 9.66023 15.6749C10.9096 16.678 12.1433 17.6895 13.3843 18.6968C13.4884 18.7753 13.5915 18.8432 13.7456 18.9609Z"
						fill="#FCB53B"
					/>
				</svg>
			);

		case IconData.LINK:
			return (
				<svg
					width="16"
					height="17"
					viewBox="0 0 16 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4.61919 16.4985C4.25827 16.4264 3.88915 16.381 3.53711 16.2782C1.61095 15.7152 0.19083 13.9744 0.0201017 11.9759C-0.110335 10.4458 0.357462 9.12127 1.4293 8.02041C2.0685 7.36345 2.72205 6.72082 3.37185 6.0741C3.67916 5.76679 4.12817 5.75894 4.41978 6.04747C4.71138 6.336 4.71104 6.78536 4.40373 7.09506C3.75189 7.75407 3.07956 8.3926 2.44308 9.06698C1.4672 10.1016 1.19403 11.3291 1.65568 12.6642C2.11392 13.9894 3.08263 14.7813 4.47168 14.9978C5.6118 15.175 6.61603 14.841 7.44611 14.0331C8.10273 13.3939 8.74365 12.7376 9.39242 12.0916C9.66251 11.8229 10.0388 11.7816 10.3324 11.9796C10.7156 12.2378 10.7661 12.765 10.4315 13.1064C9.9647 13.5828 9.49144 14.0506 9.01886 14.5207C8.61697 14.9209 8.23351 15.3402 7.75547 15.6571C7.0789 16.1123 6.29976 16.392 5.4882 16.4711C5.44792 16.4776 5.40811 16.4868 5.36903 16.4985H4.61919Z"
						fill="white"
					/>
					<path
						d="M15.9924 5.87336C15.9293 6.19911 15.8855 6.52998 15.7998 6.84958C15.6004 7.60913 15.2083 8.30433 14.6614 8.86793C13.9843 9.56758 13.2905 10.2515 12.5994 10.9375C12.2999 11.2349 11.8482 11.2329 11.5627 10.9454C11.2772 10.6579 11.2779 10.2102 11.5767 9.90835C12.2142 9.26437 12.8589 8.62721 13.4967 7.9839C14.238 7.23645 14.5788 6.33364 14.5289 5.28298C14.4542 3.70852 13.2731 2.32767 11.7252 2.03026C10.5004 1.795 9.42279 2.10368 8.52544 2.97883C7.88043 3.60813 7.24873 4.25041 6.61328 4.88894C6.39645 5.10713 6.14241 5.19454 5.84534 5.10576C5.3195 4.94835 5.1597 4.29822 5.54998 3.89735C5.92399 3.51287 6.30301 3.13328 6.68703 2.75859C7.11146 2.3444 7.51575 1.9029 7.97808 1.53481C9.99268 -0.0700386 13.0238 0.224297 14.7232 2.16514C15.4376 2.98088 15.8599 3.92262 15.9668 5.00401C15.9728 5.04412 15.9814 5.08381 15.9924 5.12284V5.87336Z"
						fill="white"
					/>
					<path
						d="M5.07779 12.1316C4.7756 12.1196 4.54956 11.9813 4.42561 11.7041C4.30507 11.4343 4.34298 11.1762 4.52805 10.9433C4.55474 10.9112 4.58336 10.8808 4.61375 10.8521C6.52387 8.94136 8.43364 7.03045 10.3431 5.11943C10.5705 4.89134 10.831 4.8029 11.138 4.90431C11.2494 4.94189 11.3499 5.00611 11.4309 5.09139C11.5118 5.17667 11.5707 5.28043 11.6024 5.39364C11.6341 5.50685 11.6376 5.6261 11.6128 5.741C11.5879 5.85591 11.5353 5.963 11.4596 6.05297C11.4231 6.09668 11.3821 6.13731 11.3418 6.1776C9.44994 8.06996 7.55837 9.96242 5.66715 11.855C5.50189 12.0203 5.32159 12.1381 5.07779 12.1316Z"
						fill="white"
					/>
				</svg>
			);
	}
};
