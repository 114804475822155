import gql from "graphql-tag";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import { useMutation } from "urql";
import { ListingStatusEnum } from "../../graphql/types";
import { StaticContext } from "..//../context/static-context/static-context";
import Badge, { BadgeColour } from "../common/badge/badge";
import Button, { ButtonColour } from "../common/button/button";
import ConfirmationDialog from "../common/confirmation-dialog/confirmation-dialog";
import Divider from "../common/divider/divider";
import IconComponent from "../common/icon/icon";
import { IconData } from "../common/icon/icon-data";
import NewLoadForm, { ValuesToSet } from "../forms/new-load-form/new-load-form";
import Link from "next/link";
import PriceInfo, { numberWithCommas } from "../price-info/price-info";
import EditForm from "./edit-form";
import {
	DeleteItemWithGrantMutation,
	DeleteItemWithGrantMutationVariables,
	MarkItemAsSoldWithGrantMutation,
	MarkItemAsSoldWithGrantMutationVariables,
} from "./load-card.graphql.module";
import styles from "./load-card.module.scss";
import { capitalizeFirst } from "../common/button/utils";
import Skeleton from "@mui/material/Skeleton";
interface LoadCardProps {
	imagePath: string;
	// TODO:
	loadStatus?: "draft" | "published" | "sold" | "delete" | "";
	ele?: any;
	onBoxClick?(): void;
	onDelete?: () => void;
	onUpdate?: () => void;
	// description
	// status
	// ....
}

export const DELETE_ITEM_WITH_GRANT = gql`
	mutation deleteItemWithGrant($itemId: String!, $permissionGrantKey: String!) {
		deleteItemWithGrant(
			itemId: $itemId
			permissionGrantKey: $permissionGrantKey
		) {
			error {
				code
				message
				status
			}
			success
		}
	}
`;

export const MARK_ITEM_AS_SOLD_WITH_GRANT = gql`
	mutation MarkItemAsSoldWithGrant(
		$itemId: String!
		$permissionGrantKey: String!
	) {
		markItemAsSoldWithGrant(
			itemId: $itemId
			permissionGrantKey: $permissionGrantKey
		) {
			error {
				code
				message
				status
			}
			success
		}
	}
`;

export default function LoadCard({
	imagePath,
	ele,
	loadStatus = "",
	onBoxClick,
	onDelete,
	onUpdate,
}: LoadCardProps) {
	const statusBadgeColor: any = {
		draft: BadgeColour.DARK_GREY,
		publish: BadgeColour.YELLOW,
		sold: BadgeColour.GREEN,
		delete: BadgeColour.RED,
	};

	const {
		translator,
		getStateByAbbreviation,
		categories,
		retailers,
		states,
	} = useContext(StaticContext);

	const router = useRouter();
	const { query } = router;
	const permissionGrantKey = query.permissionGrantKey as string;

	const [deleteItemWithGrantResult, deleteItemWithGrant] = useMutation<
		DeleteItemWithGrantMutation,
		DeleteItemWithGrantMutationVariables
	>(DELETE_ITEM_WITH_GRANT);

	const [markItemAsSoldWithGrantResult, markItemAsSoldWithGrant] = useMutation<
		MarkItemAsSoldWithGrantMutation,
		MarkItemAsSoldWithGrantMutationVariables
	>(MARK_ITEM_AS_SOLD_WITH_GRANT);

	const [valuesToEdit, setValuesToEdit] = useState<ValuesToSet>();

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [markAsSoldDialogOpen, setMarkAsSoldDialogOpen] = useState(false);
	// const [markAsNotSoldDialogOpen, setMarkAsNotSoldDialogOpen] = useState(false);
	const [showEditForm, setShowEditForm] = useState(false);

	const stateData = getStateByAbbreviation(ele.inventoryLoadFOB.state);

	const [copied, setCopied] = useState(false);

	const copy = (): any => {
		const el = document.createElement("input");
		let url = window.location.href;

		let result = url.split("/");
		let link = `${result[0]}/${result[1]}/${result[2]}/inventory/${ele.id}`;

		el.value = link;
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
		setCopied(true);
	};

	const parentsCategories = (e: any) => {
		let filter: any = categories.filter((ele) => ele.id === e);
		return filter[0];
	};

	let eleCategories = ele.inventoryLoadCategory.map((ele: any) =>
		parentsCategories(ele),
	);

	const nameCategories: any = [];

	eleCategories.map((ele: any) => {
		if (ele?.parent.id === "0e46c6e5-b0cc-4cfe-b3ff-08e7b9af7aa3") {
			nameCategories.push(ele.title);
		}
	});

	const retailerName = retailers.filter(
		(item) => item.id === `${ele.retailer}`,
	);

	const nameState = states.filter(
		(item) => item.abbreviation === ele?.inventoryLoadFOB.state,
	);

	const title = `${translator(ele?.inventoryLoadCondition[0])} ${translator(
		ele?.inventoryLoadSize,
	)} of ${retailerName[0]?.code} located in ${
		nameState[0]?.name
	} containing ${nameCategories?.join(", ")}`;

	const onlyActive = [ListingStatusEnum.Active, ListingStatusEnum.Sold];

	const discount: number = ele?.discount || 0;
	const oldPrice: number = ele?.cost || 0;
	const newPrice: number = oldPrice - discount;

	const sale: number = +((discount / oldPrice) * 100).toFixed(2);

	const deleteItems = () => {
		onDelete && onDelete();
	};

	const editItems = () => {
		onUpdate && onUpdate();
	};

	return (
		<>
			<ConfirmationDialog
				text="Are you sure you would like to delete this load?"
				dialogOpen={deleteDialogOpen}
				onClose={async (result) => {
					if (result) {
						await deleteItemWithGrant({ itemId: ele.id, permissionGrantKey });
						setTimeout(deleteItems, 1100);
					}
					setDeleteDialogOpen(false);
				}}
			/>
			<ConfirmationDialog
				text="Are you sure you would like to mark this load as SOLD ?"
				dialogOpen={markAsSoldDialogOpen}
				onClose={async (result) => {
					if (result) {
						await markItemAsSoldWithGrant({
							itemId: ele.id,
							permissionGrantKey,
						});
						setTimeout(editItems, 1100);
					}
					setMarkAsSoldDialogOpen(false);
				}}
			/>

			{showEditForm ? (
				valuesToEdit?.id ? (
					<EditForm
						id={valuesToEdit.id}
						onClose={() => {
							onUpdate && onUpdate();
							setShowEditForm(false);
						}}
					/>
				) : (
					<NewLoadForm
						values={valuesToEdit}
						closeForm={() => {
							onUpdate && onUpdate();
							setShowEditForm(false);
						}}
					/>
				)
			) : null}
			<div
				className={`${styles.loadCard} ${styles.animated} ${styles.animatedFadeInUp} ${styles.fadeInUp}`}
				onClick={() => {
					onBoxClick && onBoxClick();
				}}
			>
				<div
					className={styles.imgWrapper}
					onClick={() => router.push(`/inventory/${ele.id}`)}
				>
					{!ele?.gallery[0]?.isActualImage && (
						<Button
							className={styles.infoBtn}
							onClick={() => console.log("INFO")}
						>
							<IconComponent name={IconData.INFO} />
							<div className={styles.tooltiptext}>
								<div className={styles.tooltipMessage}>
									This is a sample image from a comparable truckload.
								</div>
							</div>
						</Button>
					)}

					<Badge
						color={statusBadgeColor[loadStatus]}
						title={capitalizeFirst(loadStatus)}
						className={`${styles.imgStatusBadge} ${styles.loadStatusBadge}`}
					/>
					{"" ? (
						<>
							<Image
								src={imagePath}
								alt="Inventory Load picture"
								width={300}
								height={300}
								objectFit="cover"
								className={styles.loadImg}
							/>
						</>
					) : (
						<>
							<Skeleton variant="rectangular" height={300} width={300} />
						</>
					)}
				</div>

				<div className={styles.loadCardDescription}>
					<div
						className={styles.loadTitle}
						onClick={() => router.push(`/inventory/${ele.id}`)}
					>
						<h4>{ele.compiledTitle}</h4>
						<Divider />
					</div>
					<div
						className={styles.loadText}
						onClick={() => router.push(`/inventory/${ele.id}`)}
					>
						{ele?.description}
					</div>
					{/* <PriceInfo price="1233.00" save="10" className={styles.loadPriceInfo} /> */}
					<div
						className={styles.priceSection}
						onClick={() => router.push(`/inventory/${ele.id}`)}
					>
						<PriceInfo
							design={"secondary"}
							price={newPrice}
							save={sale}
							oldPrice={oldPrice}
						/>

						<div className={styles.quantity}>
							<p>Available Quantity:</p>
							<div className={styles.badge}>
								{numberWithCommas(ele.availableLoadsQuantity)}
							</div>
						</div>
					</div>
					<div className={styles.cardFotter}>
						<div className={styles.acions}>
							<div className={styles.icon}>
								<Button
									className={styles.actionBtn}
									onClick={(e) => {
										e.stopPropagation();
										setValuesToEdit({ ...ele, id: null });
										setShowEditForm(true);
									}}
								>
									<IconComponent name={IconData.COPY} />
								</Button>
							</div>
							<div className={styles.icon}>
								<Button
									className={styles.actionBtn}
									color={
										loadStatus === "draft"
											? ButtonColour.BLUE
											: ButtonColour.DARK_GREY
									}
									onClick={(e) => {
										e.stopPropagation();
										loadStatus === "draft" && setValuesToEdit(ele);
										loadStatus === "draft" && setShowEditForm(true);
									}}
								>
									<IconComponent name={IconData.EDIT} />
								</Button>
							</div>
							<div className={styles.iconLink}>
								<Button
									className={styles.actionBtn}
									color={
										loadStatus === "published"
											? ButtonColour.GREEN
											: ButtonColour.DARK_GREY
									}
									onClick={copy}
								>
									{loadStatus === "published" && (
										<div className={styles.tooltiptext}>
											{!copied ? "Click to copy link" : "Link Copied"}
											<Link href={`/inventory/${ele.id}`}>
												<a className={styles.tooltipLink}>
													Click here to open this listing
												</a>
											</Link>
										</div>
									)}
									<IconComponent name={IconData.LINK} />
								</Button>
							</div>
							<div data-tip data-for="registerTip"></div>
							<div className={styles.icon}>
								<Button
									className={styles.actionBtn}
									color={
										loadStatus !== "sold"
											? ButtonColour.RED
											: ButtonColour.DARK_GREY
									}
									onClick={(e) => {
										e.stopPropagation();
										loadStatus !== "sold" && setDeleteDialogOpen(true);
									}}
								>
									<IconComponent name={IconData.DELETE} />
								</Button>
							</div>
						</div>

						{loadStatus === "published" || loadStatus === "draft" ? (
							<Button
								className={[styles.actionBtn, styles.w130].join(" ")}
								color={ButtonColour.GREEN}
								onClick={(e) => {
									e.stopPropagation(), setMarkAsSoldDialogOpen(true);
								}}
							>
								<span style={{ color: "white" }}>Mark as a Sold</span>
							</Button>
						) : (
							<Button
								className={[styles.actionBtn, styles.w130].join(" ")}
								color={ButtonColour.MUTED}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								Mark as a Sold
							</Button>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
