import React, { Children, useContext, useEffect, useState } from "react";
import { StaticContext } from "../../../context/static-context/static-context";
import { Category_FragmentFragment } from "../../../context/static-context/static-context.query.graphql.module";
import styles from "./category-dropdown.module.scss";

interface CategoryCheckboxListProps {
	parent?: CategoryItem;
	categories: CategoryItem[];
	checked: string[];
	onChecked: (value: string[]) => void;
	paddingLeft: number;
	disabled?: boolean;
	isParent?: boolean;
}

interface CategoryDropdownProps {
	isOpen: boolean;
	checked: string[];
	retailer?: string[];
	setChecked: (value: string[]) => void;
	onChange?: (value: string[], selected: CategoryItem[]) => void;
	onClose?: () => void;
	onTreeBuild?: (tree: CategoryItem[]) => void;
}

export type CategoryItem = Category_FragmentFragment & {
	children: CategoryItem[];
};

function CategoryCheckboxList({
	parent,
	categories,
	checked,
	onChecked,
	paddingLeft,
	disabled = false,
	isParent = false,
}: CategoryCheckboxListProps) {
	const [retailerIdx, setRetailerIdx] = useState<number>(-1);
	const [disabledLocal, setDisabledLocal] = useState(false);

	const onChange = (category: CategoryItem, value: boolean) => {
		const _checked = checked.slice(0);

		const removeRecursive = (category: CategoryItem) => {
			const index = _checked.indexOf(category.id);
			if (index > -1) _checked.splice(index, 1);
			category.children.forEach(removeRecursive);
		};

		if (value) {
			if (isDisabled(category)) return;
			_checked.push(category.id);
			if (parent && !_checked.includes(parent.id)) _checked.push(parent.id);
		} else {
			removeRecursive(category);
		}

		onChecked(_checked);
	};

	const isDisabled = ({ id }: CategoryItem) => {
		return disabled || (disabledLocal && !checked.includes(id));
	};

	useEffect(() => {
		let count = 0;

		setRetailerIdx(-1);

		for (let i = 0; i < categories.length; i++) {
			const { retailerId } = categories[i];
			if (retailerId) setRetailerIdx(i);
			else break;
		}

		for (const { id } of categories) {
			if (checked.includes(id)) count++;
		}

		setDisabledLocal(count > 2);
	}, [checked, categories]);

	return (
		<>
			{categories.map((category, i) => (
				<div style={{ paddingLeft }} key={category.id}>
					<div className={isDisabled(category) ? styles.disabled : ""}>
						<div className={styles.checkboxWrapper}>
							<label
								className={
									isParent || category.children.length
										? styles.parentTitle
										: styles.childTitle
								}
							>
								<input
									type="checkbox"
									checked={checked.includes(category.id)}
									onChange={(e) => onChange(category, e.target.checked)}
								/>
								<span style={{ marginLeft: 6 }}>{category.title}</span>
							</label>
						</div>
					</div>
					{retailerIdx > -1 && retailerIdx === i ? (
						<div className={styles.divider}></div>
					) : null}
					<CategoryCheckboxList
						parent={category}
						categories={category.children}
						checked={checked}
						onChecked={onChecked}
						paddingLeft={12}
						disabled={isDisabled(category)}
					/>
				</div>
			))}
		</>
	);
}

export default function CategoryDropdown({
	isOpen,
	checked,
	setChecked,
	retailer = [],
	onChange = () => {},
	onClose = () => {},
	onTreeBuild = () => {},
}: CategoryDropdownProps) {
	const { categories } = useContext(StaticContext);

	const [showRetailerSpecific, setShowRetailerSpecific] = useState(false);

	const buildTree = (array: Category_FragmentFragment[]) => {
		const tree: CategoryItem[] = [];
		const transformed: CategoryItem[] = [];

		for (const item of array) {
			transformed.push({ ...item, children: [] });
		}

		for (const item of transformed) {
			if (item.parent) {
				const { id } = item.parent;
				const parent = transformed.filter((elem) => elem.id === id).pop();
				item.parent = parent;
				if (parent) parent.children.push(item);
			} else {
				tree.push(item);
			}
		}

		if (tree.length) {
			return tree[0].children;
		}

		return tree;
	};

	const nonRetailerSpecific = categories.filter(
		(category) => !category.retailerId,
	);
	const retailerSpecific = categories.filter(
		(category) =>
			category.retailerId && retailer.indexOf(category.retailerId) !== -1,
	);
	const categoriesTree = buildTree(nonRetailerSpecific);
	const retailerSpecificTree = buildTree(retailerSpecific);

	const retailerSpecificCategoryItems: CategoryItem[] = retailerSpecific.map(
		(category) => ({ ...category, children: [] }),
	);
	return (
		<div className={isOpen ? styles.isOpen : ""}>
			<div className={styles.dropdownBackdrop} onClick={onClose} />
			<div
				className={styles.dropdownWrapper}
				onClick={(e) => e.stopPropagation()}
			>
				{retailerSpecific.length ? (
					<>
						<h4 style={{ margin: "10px 0" }}>Retailer Specific</h4>
						<CategoryCheckboxList
							categories={retailerSpecificCategoryItems}
							checked={checked}
							onChecked={setChecked}
							paddingLeft={0}
							isParent={false}
						/>
					</>
				) : null}

				<CategoryCheckboxList
					categories={categoriesTree}
					checked={checked}
					onChecked={setChecked}
					paddingLeft={0}
					isParent={true}
				/>
			</div>
		</div>
	);
}
