import { DeliveryTypeEnum } from "../../../graphql/types";

export interface FormErrors {
	descriptionError: string[];
	availableLoadsQuantityError: string[];
	sellingRestrictionsError: string[];
	galleryError: string[];
	inventoryLoadSizeError: string[];
	inventoryLoadConditionError: string[];
	inventoryLoadFOBError: {
		// cityError: string[];
		// streetError: string[];
		stateError: string[];
		// zipCodeError: string[];
	};
	deliveryTypeError: string[];
	listingStatusError: string[];
	inventoryLoadCategoryError: string[];
	priceError: string[];
	leadTimeError: string[];
	retailerError: string[];
	hasManifestFile: string[];
	manifestFileError: string[];
	// estimatedRetailValueError: string[];
	// wholeSalesValueError: string[];
	estimatedPieceCountError: string[];
	widthError: string[];
	lengthError: string[];
	weightError: string[];
	heightError: string[];
}

export interface FormValidations {
	formErrors: FormErrors;
	isFormValid: boolean;
}

export const validateForm = (values: {
	description: string;
	hasManifestFile: boolean | undefined;
	availableLoadsQuantity: number;
	inventoryLoadCondition: string[];
	inventoryLoadSize: string;
	deliveryType: DeliveryTypeEnum;
	sellingRestrictions: string;
	inventoryLoadCategory: string[];
	cost: number;
	leadTime: number;
	inventoryLoadFOB: {
		city: string;
		street: string;
		state: string;
		zipCode: string;
	};
	retailer: string[];
	gallery: object[];
	manifestFile: string;
	estimatedRetailValue: number;
	wholeSalesValue: number;
	estimatedPieceCount: string;
	toggleState: boolean;
	width: number;
	length: number;
	weight: number;
	height: number;
}) => {
	const formValidations: FormValidations = {
		formErrors: {
			descriptionError: [],
			availableLoadsQuantityError: [],
			sellingRestrictionsError: [],
			galleryError: [],
			inventoryLoadConditionError: [],
			inventoryLoadSizeError: [],
			inventoryLoadFOBError: {
				// cityError: [],
				// streetError: [],
				stateError: [],
				// zipCodeError: [],
			},
			deliveryTypeError: [],
			listingStatusError: [],
			inventoryLoadCategoryError: [],
			priceError: [],
			leadTimeError: [],
			retailerError: [],
			hasManifestFile: [],
			manifestFileError: [],
			// estimatedRetailValueError: [],
			// wholeSalesValueError: [],
			estimatedPieceCountError: [],
			widthError: [],
			heightError: [],
			lengthError: [],
			weightError: [],
		},
		isFormValid: true,
	};

	if (!values.description) {
		formValidations.formErrors.descriptionError.push(
			"Please enter description",
		);
	}

	if (typeof values.hasManifestFile === "undefined") {
		formValidations.formErrors.hasManifestFile.push(
			"You need to choose this field!",
		);
	}

	if (values.hasManifestFile !== false && !values.manifestFile) {
		formValidations.formErrors.manifestFileError.push("Please upload a file");
	}

	if (!values.availableLoadsQuantity) {
		formValidations.formErrors.availableLoadsQuantityError.push(
			"Please enter available loads quantity",
		);
	}
	if (values.inventoryLoadCondition.length < 1) {
		formValidations.formErrors.inventoryLoadConditionError.push(
			"Please enter available load condition",
		);
	}
	if (!values.inventoryLoadSize) {
		formValidations.formErrors.inventoryLoadSizeError.push(
			"Please enter load size",
		);
	}
	if (!values.deliveryType) {
		formValidations.formErrors.deliveryTypeError.push(
			"Please select delivery type",
		);
	}
	// if (!values.listingStatus) {
	// 	formValidations.formErrors.listingStatusError.push(
	// 		"Please enter listing status",
	// 	);
	// }
	if (!values.sellingRestrictions) {
		formValidations.formErrors.sellingRestrictionsError.push(
			"Please enter selling restrictions",
		);
	}
	if (values.inventoryLoadCategory.length < 1) {
		formValidations.formErrors.inventoryLoadCategoryError.push(
			"Please enter inventory load category",
		);
	}
	if (!values.cost) {
		formValidations.formErrors.priceError.push("Please enter cost");
	}

	if (!values.leadTime) {
		formValidations.formErrors.leadTimeError.push("Please enter lead time");
	}
	// if (!values.inventoryLoadFOB.city) {
	// 	formValidations.formErrors.inventoryLoadFOBError.cityError.push(
	// 		"Please enter city",
	// 	);
	// }
	if (!values.inventoryLoadFOB.state) {
		formValidations.formErrors.inventoryLoadFOBError.stateError.push(
			"Please enter state",
		);
	}
	// if (!values.inventoryLoadFOB.street) {
	// 	formValidations.formErrors.inventoryLoadFOBError.streetError.push(
	// 		"Please enter street",
	// 	);
	// }
	// if (!values.inventoryLoadFOB.zipCode) {
	// 	formValidations.formErrors.inventoryLoadFOBError.zipCodeError.push(
	// 		"Please enter zip code",
	// 	);
	// }

	if (values.gallery.length < 1) {
		formValidations.formErrors.galleryError.push(
			"Please upload a gallery image",
		);
	}

	if (!values.retailer.length) {
		formValidations.formErrors.retailerError.push("Please enter retailer");
	}

	// if (
	// 	(!values.estimatedRetailValue || values.wholeSalesValue === NaN) &&
	// 	!values.toggleState
	// ) {
	// 	formValidations.formErrors.estimatedRetailValueError.push(
	// 		"Please enter value",
	// 	);
	// }

	// if (
	// 	(!values.wholeSalesValue || values.wholeSalesValue === NaN) &&
	// 	values.toggleState
	// ) {
	// 	formValidations.formErrors.wholeSalesValueError.push("Please enter value");
	// }

	if (!values.estimatedPieceCount || values.estimatedPieceCount === "0") {
		formValidations.formErrors.estimatedPieceCountError.push(
			"Please enter value",
		);
	}

	if (!values.width) {
		formValidations.formErrors.widthError.push("Please enter width");
	}

	if (!values.height) {
		formValidations.formErrors.heightError.push("Please enter height");
	}

	if (!values.length) {
		formValidations.formErrors.lengthError.push("Please enter length");
	}

	if (!values.weight) {
		formValidations.formErrors.weightError.push("Please enter weight");
	}

	formValidations.isFormValid =
		formValidations.formErrors.hasManifestFile.length == 0 &&
		formValidations.formErrors.manifestFileError.length == 0 &&
		formValidations.formErrors.availableLoadsQuantityError.length == 0 &&
		formValidations.formErrors.deliveryTypeError.length == 0 &&
		formValidations.formErrors.descriptionError.length == 0 &&
		formValidations.formErrors.inventoryLoadSizeError.length == 0 &&
		formValidations.formErrors.listingStatusError.length == 0 &&
		formValidations.formErrors.sellingRestrictionsError.length == 0 &&
		formValidations.formErrors.inventoryLoadConditionError.length == 0 &&
		formValidations.formErrors.inventoryLoadCategoryError.length == 0 &&
		formValidations.formErrors.priceError.length == 0 &&
		formValidations.formErrors.leadTimeError.length == 0 &&
		// formValidations.formErrors.inventoryLoadFOBError.cityError.length == 0 &&
		// formValidations.formErrors.inventoryLoadFOBError.streetError.length == 0 &&
		// formValidations.formErrors.inventoryLoadFOBError.zipCodeError.length == 0 &&
		formValidations.formErrors.inventoryLoadFOBError.stateError.length == 0 &&
		formValidations.formErrors.retailerError.length == 0 &&
		formValidations.formErrors.galleryError.length == 0 &&
		// formValidations.formErrors.estimatedRetailValueError.length == 0 &&
		// formValidations.formErrors.wholeSalesValueError.length == 0 &&
		formValidations.formErrors.widthError.length == 0 &&
		formValidations.formErrors.heightError.length == 0 &&
		formValidations.formErrors.lengthError.length == 0 &&
		formValidations.formErrors.weightError.length == 0 &&
		formValidations.formErrors.estimatedPieceCountError.length == 0;

	return formValidations;
};
