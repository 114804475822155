import React, { useState } from "react";
import Input from "../input/input";

export interface Option {
	text: string;
	value: string;
	checked: boolean;
}

export interface CheckboxProps extends Option {
	name: string;
	onChecked?: (checked: boolean) => void;
}

function Checkbox({ name, text, value, checked, onChecked }: CheckboxProps) {
	const [checkedLocal, setCheckedLocal] = useState(checked);

	const onCheckedLocal = (checked: boolean) => {
		setCheckedLocal(checked);

		onChecked && onChecked(checked);
	};

	return (
		<Input
			key={text}
			id={(name + value) as string}
			value={value}
			checked={checkedLocal}
			name={name}
			label={text}
			type="checkbox"
			direction="row"
			labelFirst={false}
			onChecked={onCheckedLocal}
		/>
	);
}

export default Checkbox;
