import style from "./caruselImage.module.scss";
import React, { useContext, useState } from "react";
import AternativeImage from "../../../UI/aternativeImage";

interface caruselImageProps {
	allImage?: any;
	onBoxClick?(): void;
}

function CaruselImage({
	allImage,
	onBoxClick,
}: caruselImageProps): JSX.Element {
	const [movePx, setMovePx] = useState(0);

	const styleBox: any = {
		display: "flex",
		position: "absolute",
		left: `-${movePx}px`,
		top: 0,
		transition: "all .3s",
	};

	const maxWidth = allImage.length * 270;

	return (
		<div className={style.box}>
			<div style={styleBox}>
				{allImage.map((ele: any) => (
					<div
						key={ele.id}
						className={style.image}
						onClick={() => {
							onBoxClick && onBoxClick();
						}}
					>
						<AternativeImage
							src={`${process.env.FILE_SERVER_DOMAIN}/${ele?.path}`}
							alternativeText={"item fotto"}
							blurDataURL={`${process.env.FILE_SERVER_DOMAIN}/${ele?.path}`}
						/>
					</div>
				))}
			</div>
			{}
			<button
				className={style.left}
				onClick={() => setMovePx(movePx - 270)}
				disabled={movePx === 0}
			>
				<p>
					<svg
						width="13"
						height="22"
						viewBox="0 0 13 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M2.49997 0.5L0.0324707 2.9675L8.04747 11L0.0324707 19.0325L2.49997 21.5L13 11L2.49997 0.5Z"
							fill="white"
						/>
					</svg>
				</p>
			</button>
			<button
				className={style.right}
				onClick={() => setMovePx(movePx + 270)}
				disabled={movePx === maxWidth - 270}
			>
				<p>
					<svg
						width="13"
						height="22"
						viewBox="0 0 13 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M2.49997 0.5L0.0324707 2.9675L8.04747 11L0.0324707 19.0325L2.49997 21.5L13 11L2.49997 0.5Z"
							fill="white"
						/>
					</svg>
				</p>
			</button>
		</div>
	);
}

export default CaruselImage;
