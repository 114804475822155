import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Popup from "../popup/popup";
import Underlayer from "../underlayer/underlayer";
import styles from "./confirmation-dialog.module.scss";

interface ConfirmationDialogProps {
	title?: string;
	text: string;
	dialogOpen: boolean;
	noButtonText?: string;
	yesButtonText?: string;
	onClose?: (result: boolean) => void;
}

export default function ConfirmationDialog({
	title = "Confirmation",
	text,
	dialogOpen,
	noButtonText = "No",
	yesButtonText = "Yes",
	onClose = () => {},
}: ConfirmationDialogProps) {
	const [overflowY, setOverflowY] = useState("");

	useEffect(() => setOverflowY(document.body.style.overflowY), []);

	useEffect(() => {
		if (dialogOpen) {
			setOverflowY(document.body.style.overflowY);
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = overflowY;
		}
	}, [dialogOpen]);

	return dialogOpen ? (
		<Popup className={styles.newLoadPopup} isScrollLock={false}>
			<div className={styles.dialogBackdrop}>
				<div className={styles.dialogWrapper}>
					<div className={styles.dialogContent}>
						<FontAwesomeIcon
							icon={faInfoCircle}
							className={styles.iconInfoCircle}
						/>
						<h4 className={styles.title}>{title}</h4>
						<p className={styles.text}>{text}</p>
						<div className={styles.dialogButtons}>
							<button
								className={styles.buttonNo}
								onClick={() => onClose(false)}
							>
								{noButtonText}
							</button>
							<button
								className={styles.buttonYes}
								onClick={() => onClose(true)}
							>
								{yesButtonText}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	) : null;
}
