import React, { ReactNode, useContext, useEffect, useState } from "react";
import styles from "./filter-modal-delivery.module.scss";
import { useScrollLock } from "../../../hooks/useScroll";
import { StaticContext } from "../../../context/static-context/static-context";
import BadgeMobile from "../../common/badge-mobile/badge-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { deliveryTypes } from "../../translations/delivery-types";
import IconComponent from "../../common/icon/icon";
import { IconData } from "../../common/icon/icon-data";

interface FilterModalDeliveryProps {
	chekedToggelState?: any;
	statesOpen?: any;
	addArrayState?: any;
	deleteArrayState?: any;
	arrayState?: any;
	onFormSubmit?: any;
}

export default function FilterModalDelivery({
	chekedToggelState,
	statesOpen,
	addArrayState,
	deleteArrayState,
	arrayState,
	onFormSubmit,
}: FilterModalDeliveryProps): JSX.Element {
	const { lockScroll, unlockScroll } = useScrollLock();
	const [searchArrayFilter, setSearchArrayFilter] = useState<any[]>([]);

	useEffect(() => {
		setSearchArrayFilter([...deliveryTypes]);
	}, [deliveryTypes]);

	useEffect(() => {
		statesOpen ? lockScroll() : unlockScroll();
	}, [statesOpen, lockScroll, unlockScroll]);

	const addArray = (e: any) => {
		addArrayState(e);
	};

	const deleteArray = (e: any) => {
		deleteArrayState(e);
	};

	return (
		<div className={styles.statesWindowsConteiner}>
			<div className={styles.statesWindowsModal}>
				<div className={styles.statesWindowsModalHeader}>
					<p>Select Delivery Type</p>
					<div onClick={() => (chekedToggelState(), unlockScroll())}>
						<FontAwesomeIcon className={styles.closeBtn} icon={faTimes} />
					</div>
				</div>
				<div className={styles.badgeContainer}>
					{searchArrayFilter.map((ele: any, i: number) => (
						<BadgeMobile
							key={i}
							title={`${ele?.name || ele?.title}`}
							isVisible={true}
							addArray={addArray}
							deleteArray={deleteArray}
							ele={ele}
							id={ele?.id}
							array={arrayState}
							type={true}
						/>
					))}
				</div>
				<div
					className={styles.statesWindowsModalFooter}
					onClick={() => (chekedToggelState(), unlockScroll())}
				>
					<div onClick={onFormSubmit} className={styles.onFormSubmit}>
						<IconComponent
							className={styles.iconSearch}
							name={IconData.SEARCH}
						/>
						<div>Show Results</div>
					</div>
				</div>
			</div>
		</div>
	);
}
