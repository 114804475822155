import Link from "next/link";
import { useContext, useRef, useState } from "react";
import { useQuery } from "urql";
import Button, { ButtonColour } from "../common/button/button";
import Divider from "../common/divider/divider";
import styles from "./inventory.module.scss";

import { useRouter } from "next/router";
import { Navigation, Scrollbar } from "swiper";
import { StaticContext } from "../../context/static-context/static-context";
import InventoryCard from "../inventory-card/inventory-card";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import {
	getParametersFromRouter,
	parametersToSearchData,
} from "../../../pages/inventory/InventoryPage";
import useHover from "../../hooks/useHover";
import { IconData } from "..//common/icon/icon-data";
import IconComponent from "../common/icon/icon";
import { GET_ITEMS } from "../items/all-items-list";
import {
	GetItemsListQuery,
	GetItemsListQueryVariables,
} from "../items/all-items-list.graphql.module";

interface SimilarCategoriesProps {
	categoriesID: string[];
	title?: string;
}

export default function SimilarCategories({
	categoriesID,
	title,
}: SimilarCategoriesProps) {
	const [array, setArray] = useState<any>([""]);
	const router = useRouter();
	const swiper = useSwiper();
	const contextData = useContext(StaticContext);
	const searchParameters = getParametersFromRouter(router);
	const [hover, isHover] = useHover<HTMLDivElement>();

	const searchParametersNew = {
		...searchParameters,
		inventoryLoadCategory: categoriesID,
	};

	const [getItemQueryResult, GetItem] = useQuery<
		GetItemsListQuery,
		GetItemsListQueryVariables
	>({
		query: GET_ITEMS,
		requestPolicy: "cache-and-network",
		variables: {
			searchData: {
				...parametersToSearchData(searchParametersNew),
			},
		},
	});

	const dataArray: any = getItemQueryResult?.data?.list?.data;

	const nextEl = useRef(null);
	const prevtEl = useRef(null);

	const singleSlide = () => {
		return (
			<div>
				<div className={styles.headerWrapper}>
					<Divider />
					<h3>{title ?? "Similar products"}</h3>
					<Divider />
				</div>
				<div className={styles.singleElement}>
					<InventoryCard
						priceDesign="secondary"
						imagePath={
							dataArray[0].gallery[0]?.path
								? `${process.env.FILE_SERVER_DOMAIN}/${dataArray[0].gallery[0]?.path}`
								: `img/thinkbig.png`
						}
						onBoxClick={() => router.push(`/inventory/${dataArray[0].id}`)}
						ele={dataArray[0]}
						swiper={true}
					/>
				</div>

				<div className={styles.buttonWrapper}>
					<Link href="/inventory">
						<a>
							<Button
								title="View All Products"
								color={ButtonColour.DARK}
								className={styles.button}
							/>
						</a>
					</Link>
				</div>
			</div>
		);
	};
	const twoSlide = () => {
		return (
			<div className={styles.similarProducts}>
				<div className={styles.headerWrapper}>
					<Divider />
					<h3>{title}</h3>
					<Divider />
				</div>
				<div className={styles.swiperBox}>
					<div className={styles.cardsWrapperTwo}>
						{dataArray.map((ele: any) => (
							<div key={ele.id}>
								<InventoryCard
									priceDesign="secondary"
									imagePath={
										ele?.gallery[0]?.path
											? `${process.env.FILE_SERVER_DOMAIN}/${ele.gallery[0]?.path}`
											: `/img/thinkbig.png`
									}
									onBoxClick={() => router.push(`/inventory/${ele.id}`)}
									ele={dataArray[0]}
									swiper={true}
								/>
							</div>
						))}
					</div>
				</div>
				<div className={styles.buttonWrapper}>
					<Link href="/inventory">
						<a>
							<Button
								title="View All Products"
								color={ButtonColour.DARK}
								className={styles.button}
							/>
						</a>
					</Link>
				</div>
			</div>
		);
	};

	const renderSwiper = () => {
		if (dataArray?.length === 1) return singleSlide();
		if (dataArray?.length === 2 || dataArray?.length === 3) return twoSlide();
		return (
			<div className={styles.similarProducts}>
				<div className={styles.headerWrapper}>
					<Divider />
					<h3>{title}</h3>
					<Divider />
				</div>
				<div className={styles.swiperBox}>
					<div
						ref={prevtEl}
						onClick={() => swiper?.slidePrev()}
						className={styles.swiperButtonLeft}
					>
						<IconComponent name={IconData.CIRCLE_CHEVRON_LEFT} />
					</div>
					<div className={styles.cardsWrapper}>
						<Swiper
							loop={true}
							navigation={{
								nextEl: nextEl.current,
								prevEl: prevtEl.current,
							}}
							modules={[Navigation, Scrollbar]}
							// onSwiper={(swiper) => console.log(swiper)}
							spaceBetween={5}
							pagination
							breakpoints={{
								250: {
									slidesPerView: 1,
								},
								750: {
									slidesPerView: 2,
								},
								1000: {
									slidesPerView: 3,
								},
								1400: {
									slidesPerView: 4,
								},
							}}
						>
							<div className={styles.slide}>
								{dataArray?.map((ele: any) => (
									<SwiperSlide key={ele.id}>
										<div>
											<InventoryCard
												priceDesign="secondary"
												imagePath={
													ele?.gallery[0]?.path
														? `${process.env.FILE_SERVER_DOMAIN}/${ele.gallery[0]?.path}`
														: "/img/thinkbig.png"
												}
												onBoxClick={() => router.push(`/inventory/${ele.id}`)}
												ele={ele}
												swiper={true}
											/>
										</div>
									</SwiperSlide>
								))}
							</div>
						</Swiper>
					</div>
					<div
						ref={nextEl}
						onClick={() => swiper?.slideNext()}
						className={styles.swiperButtonRight}
					>
						<IconComponent name={IconData.CIRCLE_CHEVRON_RIGHT} />
					</div>
				</div>
				<div className={styles.buttonWrapper}>
					<Link href="/inventory">
						<a>
							<Button
								title="View All Products"
								color={ButtonColour.DARK}
								className={styles.button}
							/>
						</a>
					</Link>
				</div>
			</div>
		);
	};
	return (
		<>
			{dataArray?.length ? (
				renderSwiper()
			) : (
				<div className={styles.buttonWrapper}>
					<Link href="/inventory">
						<a>
							<Button
								title="View All Products"
								color={ButtonColour.DARK}
								className={styles.button}
							/>
						</a>
					</Link>
				</div>
			)}
		</>
	);
}
