import React, { ReactNode, useContext, useEffect, useState } from "react";
import styles from "./filter-modal-condition.module.scss";
import { useScrollLock } from "../../../hooks/useScroll";
import { StaticContext } from "../../../context/static-context/static-context";
import BadgeMobile from "../../common/badge-mobile/badge-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IconComponent from "../../common/icon/icon";
import { IconData } from "../../common/icon/icon-data";

export const idealUseID = [
	{ id: "PALLET_SALES", title: "Pallet sales" },
	{ id: "PER_PIECE_SALES", title: "Per piece sales" },
	{ id: "E_COMMERCE_SALES", title: "E-commerce sales" },
	{ id: "RETAIL_SALES", title: "Retailer sales" },
	{ id: "AUCTIONS", title: "Auction" },
	{ id: "BIN_STORES", title: "Bin stores" },
	{ id: "FLEA_MARKETS", title: "Flea markets" },
];

export const idealUseBadge = {
	PALLET_SALES: "Pallet sales",
	PER_PIECE_SALES: "Per piece sales",
	E_COMMERCE_SALES: "E-commerce sales",
	RETAIL_SALES: "Retailer sales",
	AUCTIONS: "Auction",
	BIN_STORES: "Bin stores",
	FLEA_MARKETS: "Flea markets",
};

interface FilterConditionProps {
	chekedToggelState?: any;
	statesOpen?: any;
	addArrayState?: any;
	deleteArrayState?: any;
	arrayState?: any;
	onFormSubmit?: any;
}

export default function FilterModalIdealUse({
	chekedToggelState,
	statesOpen,
	addArrayState,
	deleteArrayState,
	arrayState,
	onFormSubmit,
}: FilterConditionProps): JSX.Element {
	const { lockScroll, unlockScroll } = useScrollLock();
	const [searchArrayFilter, setSearchArrayFilter] = useState<any[]>([]);
	const contextData = useContext(StaticContext);

	useEffect(() => {
		let result: any = [];
		result = contextData.vocabularyEntries.filter(
			(ele) => ele.parent?.id === "74bde057-97f0-40c7-9718-ccd970b8337d",
		);

		setSearchArrayFilter(result);
	}, [contextData]);

	useEffect(() => {
		statesOpen ? lockScroll() : unlockScroll();
	}, [statesOpen, lockScroll, unlockScroll]);

	const addArray = (e: any) => {
		const arrayID = arrayState.map((ele: any) => (ele = ele.id));
		if (arrayID.includes(e.id)) return;
		addArrayState(e);
	};

	const deleteArray = (e: any) => {
		deleteArrayState(e);
	};

	return (
		<div className={styles.statesWindowsConteiner}>
			<div className={styles.statesWindowsModal}>
				<div className={styles.statesWindowsModalHeader}>
					<p>Select Ideal Use</p>
					<div onClick={() => (chekedToggelState(), unlockScroll())}>
						<FontAwesomeIcon className={styles.closeBtn} icon={faTimes} />
					</div>
				</div>
				<div className={styles.badgeContainer}>
					{idealUseID.map((ele: any) => (
						<BadgeMobile
							key={ele?.id}
							title={`${ele?.name || ele?.title}`}
							isVisible={true}
							addArray={addArray}
							deleteArray={deleteArray}
							ele={ele}
							id={ele?.id}
							array={arrayState}
						/>
					))}
				</div>
				<div
					className={styles.statesWindowsModalFooter}
					onClick={() => (chekedToggelState(), unlockScroll())}
				>
					<div onClick={onFormSubmit} className={styles.onFormSubmit}>
						<IconComponent
							className={styles.iconSearch}
							name={IconData.SEARCH}
						/>
						<div>Show Results</div>
					</div>
				</div>
			</div>
		</div>
	);
}
