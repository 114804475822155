import { useState } from "react";
import { Dialog } from "@mui/material";
import styles from "./image-cropper.module.scss";
import ReactCrop from "react-image-crop";
import { Button } from "@mui/material";
import { useEffect } from "react";

interface IImageCropperComponentProps {
	imageToCrop: any;
	onCrop: any;
	onFail?: any;
	onCancel: any;
}
export default function ImageCropper({
	imageToCrop,
	onCrop,
	onCancel,
	onFail,
}: IImageCropperComponentProps) {
	useEffect(() => {
		setIsPerformingActions(false);
	}, [imageToCrop]);

	const [isPerformingActions, setIsPerformingActions] = useState<boolean>(
		false,
	);
	const [src, setSrc] = useState<any>(null);
	const [crop, setCrop] = useState<any>({
		unit: "px",
		width: 1200,
		aspect: 2,
	});
	const [imageRef, setImageRef] = useState<any>(null);

	const onImageLoaded = (image: any) => {
		setImageRef(image);
	};
	const onCropChange = (crop: any) => {
		setCrop(crop);
	};
	const cancelUploadImg = () => {
		onCancel();
	};
	const getCroppedImg = async () => {
		// const crop = crop;
		const image = imageRef;
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width * scaleX;
		canvas.height = crop.height * scaleX;
		const ctx = canvas.getContext("2d")!;

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			canvas.width,
			canvas.height,
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob: any) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error("Canvas is empty");
					return;
				}
				blob.name = imageToCrop.name;
				onCrop(blob);
			}, "image/jpeg");
		});
	};
	return (
		<Dialog className={styles.imageCropper} open={true} maxWidth={false}>
			<div className={styles.reactCropContainer}>
				<ReactCrop
					//@ts-ignore
					imageStyle={{
						maxHeight: "calc(100vh - 150px)",
					}}
					src={imageToCrop}
					crop={crop}
					// ruleOfThirds
					keepSelection
					onImageLoaded={onImageLoaded}
					// onComplete={this.onCropComplete}
					// onComplete={this.onCropComplete}
					onChange={onCropChange}
					className={styles.reactCrop}
				/>
				<div className={styles.buttons}>
					<Button
						disabled={isPerformingActions}
						onClick={() => {
							cancelUploadImg();
						}}
						className={styles.button}
						style={{ backgroundColor: "red" }}
						// color="red"
					>
						Cancel
					</Button>
					<Button
						disabled={isPerformingActions}
						onClick={async () => {
							setIsPerformingActions(true);
							await getCroppedImg();
						}}
						className={styles.button}
						// color="primary"
						style={{ backgroundColor: "green" }}
					>
						Crop
					</Button>
				</div>
			</div>
		</Dialog>
	);
}
