import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

type FbImageModalProps = {
	images: string[];
	index: number;
	onClose: () => void;
} & typeof defaultProps;

const defaultProps = {
	index: 0,
	onClose: () => {},
};

const FbImageModal = (props: FbImageModalProps) => {
	const [images, setImages] = useState(props.images);
	const [currentImageIndex, setCurrentImageIndex] = useState(props.index);

	const onMovePrevRequest = () => {
		setCurrentImageIndex(
			(currentImageIndex + images.length - 1) % images.length,
		);
	};

	const onMoveNextRequest = () => {
		setCurrentImageIndex((currentImageIndex + 1) % images.length);
	};

	const result = images[0].split(" ");

	return (
		<>
			<Lightbox
				mainSrc={images[currentImageIndex].split(" ")[0]}
				nextSrc={images[(currentImageIndex + 1) % images.length].split(" ")[0]}
				prevSrc={
					images[(currentImageIndex + images.length - 1) % images.length].split(
						" ",
					)[0]
				}
				// nextSrc={images[(currentImageIndex + 1) % images.length]}
				// prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
				onCloseRequest={props.onClose}
				onMovePrevRequest={onMovePrevRequest}
				onMoveNextRequest={onMoveNextRequest}
				imageCaption={
					images[currentImageIndex].split(" ")[1] === "false"
						? "This is a sample image from a comparable truckload."
						: ""
				}
			/>
		</>
	);
};

FbImageModal.defaultProps = defaultProps;

export default FbImageModal;
