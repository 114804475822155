import React, {
	InputHTMLAttributes,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { FormContext } from "../../../forms/form-container/form-container";
// import { useFormElement } from "../../../../hooks/useFormElement";
import FormField from "../form-field/form-field";
import styles from "./input.module.scss";

export interface SelectOption {
	key: string;
	value: string;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	siblings?: ReactNode;
	inputElement?: "input" | "textarea" | "select";
	label?: string;
	invalidFeedback?: string[];
	direction?: "row" | "column";
	labelFirst?: boolean;
	selectOptions?: SelectOption[];
	onChange?: (val: any) => void;
	onChecked?: (checked: boolean) => void;
	error?: boolean;
}

function Input({
	id,
	inputElement = "input",
	label,
	invalidFeedback,
	type = "text",
	value = "",
	checked,
	disabled,
	placeholder,
	className,
	siblings,
	direction = "column",
	labelFirst = true,
	name,
	selectOptions,
	min,
	onInput,
	onChange,
	onClick,
	onChecked,
	error = false,
}: InputProps): JSX.Element {
	const formContext = useContext(FormContext);
	const { form, errors, handleFormChange } = formContext;
	const [localValue, setLocalValue] = useState<any>(value);
	const [options, setOptions] = useState<SelectOption[] | undefined>([]);

	useEffect(() => {
		if (inputElement !== "select") return;
		setOptions(selectOptions ?? []);
		// placeholder
		// 	? setOptions([...[placeholder], ...(selectOptions ?? [])])
		// 	: setOptions(selectOptions ?? []);
	}, [inputElement, selectOptions, placeholder]);

	const handleChange = (event: any, inputType: string) => {
		// event.target.type === "radio"
		// 	? setLocalValue(event.target.checked)
		// 	: setLocalValue(event.target.value);

		setLocalValue(event.target.value);

		onChange && onChange(event.target.value);
		onChecked && onChecked(event.target.checked);

		handleFormChange(event, inputType);
	};

	function isNumber(n: any) {
		return !isNaN(parseFloat(n)) && !isNaN(n - 0);
	}

	const checkNumeric = (event: any) => {
		onInput && onInput(event.target.value);

		if (type == "number") {
			if (isNumber(event.target.value)) {
				event.target.oldValue = event.target.value;
			} else if (event.target.hasOwnProperty("oldValue")) {
				event.target.value = event.target.oldValue;
			} else {
				event.target.value = "";
			}
		}
	};
	const checkKeyCode = (event: any) => {
		if (event.keyCode == 46 || event.keyCode == 8) {
			event.target.oldValue = "";
		}
	};

	return (
		<FormField
			id={id}
			label={label}
			invalidFeedback={invalidFeedback}
			direction={direction}
			labelFirst={labelFirst}
			type={type}
			siblings={siblings}
			className={`${inputElement === "textarea" ? styles.textareaWrapper : ""}`}
		>
			{inputElement === "input" && (
				<input
					id={id}
					name={name}
					type={type}
					min={min}
					onInput={checkNumeric}
					checked={checked}
					disabled={disabled}
					value={value}
					placeholder={placeholder}
					// className={`${styles.input}`}
					className={`${styles.input} ${error ? styles.isError : ""}`}
					onKeyDown={(e) => checkKeyCode(e)}
					onChange={(e) => handleChange(e, type)}
					onWheel={(e) => e.currentTarget.blur()}
				/>
			)}

			{inputElement === "textarea" && (
				<textarea
					id={id}
					name={name}
					value={localValue}
					placeholder={placeholder}
					className={`${styles.textarea} ${className ? className : ""} ${
						error ? styles.isError : ""
					}`}
					onChange={(e) => handleChange(e, inputElement)}
				>
					{localValue}
				</textarea>
			)}

			{/* TODO: custom select component */}
			{inputElement === "select" && options && options.length > 0 && (
				// <Select onClick={onClick} />

				<select
					name={name}
					id={id}
					className={`${styles.select} ${error ? styles.isError : ""}`}
					onChange={(e) => handleChange(e, inputElement)}
					defaultValue={localValue}
				>
					{placeholder && (
						<option value="" disabled hidden>
							{placeholder}
						</option>
					)}
					{options.map(({ key, value }, idx) => (
						<option key={key} value={key}>
							{value}
						</option>
					))}
				</select>
			)}
		</FormField>
	);
}

export default Input;
