import Image from "next/image";
import alteImage from "../../../public/img/NoImageVersion7.png";

interface AternativeImageProps {
	src: string | undefined;
	alternativeText: string | undefined;
	blurDataURL: string | undefined;
	onBoxClick?(): void;
}

function AternativeImage({
	src,
	alternativeText,
	blurDataURL,
	onBoxClick,
}: AternativeImageProps): JSX.Element {
	return (
		<>
			<Image
				src={src ? src : alteImage}
				layout="fill"
				blurDataURL={blurDataURL}
				loading="lazy"
				objectFit="cover"
				placeholder="blur"
				unoptimized={true}
				alt={alternativeText ? alternativeText : "image"}
				onClick={() => {
					onBoxClick && onBoxClick();
				}}
			/>
		</>
	);
}

export default AternativeImage;
