import React, { useEffect, useState, useContext, useCallback } from "react";
import styles from "./search-bar.module.scss";
import useWindowSize from "../../hooks/useWindowSize";
import Button from "../common/button/button";
import IconComponent from "../icon/icon";
import { IconData } from "../icon/icon-data";
import { StaticContext } from "../../context/static-context/static-context";
import { useRouter, NextRouter } from "next/router";

import { Console } from "console";
import Link from "next/link";
import { getParametersFromRouter } from "../../../pages/inventory/InventoryPage";

interface SearchBarProps {
	isCompact?: boolean;
	onFormSubmit?: any;
	onFormClean?: any;
	updateSearchState?: any;
}

function SearchBar({
	isCompact = false,
	onFormSubmit,
	updateSearchState,
	onFormClean,
}: SearchBarProps): JSX.Element {
	const contextValue = useContext(StaticContext);
	const [isMobile, setIsMobile] = useState(false);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const [text, setText] = useState<string>(queryParameters.fullTextSearch);
	const { width } = useWindowSize();

	useEffect(() => {
		const isMobileWidth = () => (width <= 540 ? true : false);
		setIsMobile(isMobileWidth);
	}, [setIsMobile, width]);

	const handleKeyPress = (event: React.KeyboardEvent): any => {
		if (event.key === "Enter") {
			onFormSubmit(event);
		}
	};

	useEffect(() => {
		setText(queryParameters.fullTextSearch);
	}, [contextValue]);

	return (
		<div className={styles.focusEffect}>
			{!isCompact ? (
				<div className={styles.searchBar}>
					<div className={styles.searchBarInput}>
						<input
							type="text"
							value={text}
							placeholder="Search Inventory"
							className={styles.searchBarPlaceholder}
							onKeyPress={handleKeyPress}
							onChange={(e) => {
								updateSearchState({
									fullTextSearch: e.target.value,
								});
								setText(e.target.value);
							}}
						/>
					</div>
					<IconComponent className={styles.iconSearch} name={IconData.SEARCH} />
					<div className={styles.iconSearchBox}>
						<Button
							className={styles.cleanButton}
							onClick={(e) => {
								onFormClean(e);
								setText("");
							}}
						>
							<IconComponent
								className={styles.iconDelete}
								name={IconData.DELETE}
							/>
						</Button>
						<Button
							className={styles.search}
							title="Search"
							onClick={onFormSubmit}
						/>
					</div>
					<div className={styles.iconSearchBoxMob}>
						<Button
							className={styles.iconSearchMob}
							onClick={(e) => {
								onFormClean(e);
								setText("");
							}}
						>
							<IconComponent
								className={styles.iconSearchMobDelete}
								name={IconData.DELETE}
							/>
						</Button>

						<Button onClick={onFormSubmit} className={styles.search}>
							<div style={{ width: "1rem", margin: "0 auto" }}>
								<IconComponent name={IconData.SEARCH} />
							</div>
						</Button>
					</div>
				</div>
			) : (
				<div>
					<div className={styles.searchBarCompact}>
						<input
							type="text"
							value={text}
							placeholder="Search"
							onKeyPress={handleKeyPress}
							onChange={(e) => {
								updateSearchState({
									fullTextSearch: e.target.value || "",
								});
								setText(e.target.value);
							}}
						/>
					</div>
					<div className={styles.searchCompactbutton}>
						<Button onClick={onFormSubmit} className={styles.search}>
							<div>Apply Filter</div>
						</Button>
						<Button
							className={styles.search}
							onClick={(e) => {
								onFormClean(e);
								setText("");
							}}
						>
							<div>Clear Filter</div>
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

export default SearchBar;
