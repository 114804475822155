import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import PriceInfo from "../price-info/price-info";
import { PriceInfoProps } from "../price-info/price-info";
import { StaticContext } from "..//..//context/static-context/static-context";
import styles from "./inventory-card.module.scss";
import IconComponent from "../common/icon/icon";
import { IconData } from "../common/icon/icon-data";
import Button from "../common/button/button";
import useHover from "../../hooks/useHover";
import Skeleton from "@mui/material/Skeleton";

interface InventoryCardProps {
	imagePath: string;
	priceDesign?: PriceInfoProps["design"];
	onBoxClick?(): void;
	ele?: any;
	swiper?: boolean;
}

function InventoryCard({
	imagePath,
	priceDesign,
	onBoxClick,
	ele,
	swiper = false,
}: InventoryCardProps): JSX.Element {
	const { getStateByAbbreviation, categories } = useContext(StaticContext);
	const [hover, isHover] = useHover<HTMLDivElement>();
	const router = useRouter();

	const stateData = getStateByAbbreviation(ele.inventoryLoadFOB.state);

	const parentsCategories = (e: any) => {
		let filter: any = categories.filter((ele) => ele.id === e);
		return filter[0];
	};

	let eleCategories = ele.inventoryLoadCategory.map((ele: any) =>
		parentsCategories(ele),
	);

	const nameCategories: any = [];

	eleCategories.map((ele: any) => {
		if (ele?.parent.id === "0e46c6e5-b0cc-4cfe-b3ff-08e7b9af7aa3") {
			nameCategories.push(ele.title);
		}
	});

	const discount: number = ele?.discount || 0;
	const oldPrice: number = ele?.cost || 0;
	const newPrice: number = oldPrice - discount;

	const sale: number = +((discount / oldPrice) * 100).toFixed(2);
	return (
		<div
			className={`${styles.inventoryCard} ${
				swiper ? styles.inventoryCardSlider : ""
			}`}
			ref={hover}
		>
			<div
				onClick={() => {
					onBoxClick && onBoxClick();
				}}
				className={
					!swiper
						? `${styles.animated} ${styles.animatedFadeInUp} ${styles.fadeInUp}`
						: ""
				}
			>
				{imagePath ? (
					<>
						<div className={styles.image}>
							<div className={styles.imgWrapper}>
								{!ele?.gallery[0]?.isActualImage &&
									!(imagePath === "/img/thinkbig.png") && (
										<Button className={styles.infoBtn}>
											<IconComponent name={IconData.INFO} />
											<div className={styles.tooltiptext}>
												<div className={styles.tooltipMessage}>
													This is a sample image from a comparable truckload.
												</div>
											</div>
										</Button>
									)}
							</div>
							<div
								className={
									!swiper
										? `${styles.animated} ${styles.animatedFadeInUp} ${styles.fadeInUp}`
										: ""
								}
							>
								<Image
									src={imagePath}
									alt="Inventory picture"
									width={300}
									height={300}
									objectFit="cover"
									onClick={() => router.push(`/inventory/${ele.id}`)}
									className={`${swiper ? "" : styles.inventoryImg}`}
								/>
							</div>
						</div>
					</>
				) : (
					<>
						<Skeleton variant="rectangular" height={300} />
					</>
				)}

				<div className={styles.inventoryDescription}>
					{true && (
						<PriceInfo
							design={priceDesign}
							price={newPrice}
							oldPrice={oldPrice}
							save={sale}
							effectAnimation={isHover}
						/>
					)}
					<div
						className={styles.inventoryTitle}
						onClick={() => router.push(`/inventory/${ele.id}`)}
					>
						{ele.title}
					</div>
				</div>
			</div>
		</div>
	);
}

export default InventoryCard;
