import AternativeImage from "../../UI/aternativeImage";
import styles from "./noResult.module.scss";
import hero from "../../../public/img/noResultSearh.png";
import Image from "next/image";
import VisibilitySensorComponent from "../../UI/Animation/VisibilitySensorComponent";
import SearchBar from "../search-bar/search-bar";
import { useState } from "react";
import { useRouter } from "next/router";
import IconComponent from "../icon/icon";
import { IconData } from "../icon/icon-data";

interface NoResultProps {}

function NoResult({}: NoResultProps): JSX.Element {
	const router = useRouter();
	const [text, setText] = useState<string>("");

	const handleKeyPress = (event: React.KeyboardEvent): any => {
		if (event.key === "Enter") {
			router.push(`/inventory?skip=0&take=9&minValue=0&fullTextSearch=${text}`);
		}
	};

	return (
		<div className={styles.noResult}>
			<div className={styles.image}>
				<Image
					src={hero}
					layout="responsive"
					blurDataURL={hero.src}
					loading="lazy"
					placeholder="blur"
					objectFit="cover"
					alt={"hero"}
				/>
			</div>

			<div className={styles.text}>
				<p>No results found</p>
				<p>Try modifying your search parameters to get other results</p>
			</div>

			<div className={styles.searchBar}>
				<input
					type="text"
					value={text}
					placeholder="Search"
					className={styles.searchBarPlaceholder}
					onKeyPress={handleKeyPress}
					onChange={(e) => {
						setText(e.target.value);
					}}
				/>

				<div
					className={styles.icon}
					onClick={() => {
						if (text.length === 0) {
							router.push(`/inventory`);
						} else {
							router.push(
								`/inventory?skip=0&take=9&minValue=0&fullTextSearch=${text}`,
							);
						}
					}}
				>
					<svg
						width="16"
						height="17"
						viewBox="0 0 16 17"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M15.7817 13.9509L12.6663 10.8356C12.5257
						10.695 12.3351 10.6169 12.1351 10.6169H11.6258C12.4882
						9.51388 13.0007 8.12651 13.0007 6.61729C13.0007
						3.02701 10.0916 0.11792 6.50132 0.11792C2.91104
						0.11792 0.00195312 3.02701 0.00195312 6.61729C0.00195312 10.2076 2.91104
						13.1167 6.50132 13.1167C8.01055 13.1167 9.39791 12.6042 10.5009 11.7418V12.2511C10.5009
						12.4511 10.579 12.6417 10.7197 12.7823L13.835 15.8976C14.1287 16.1914 14.6037 16.1914
						14.8942 15.8976L15.7785 15.0133C16.0723 14.7196 16.0723 14.2447 15.7817 13.9509ZM6.50132
						10.6169C4.29216 10.6169 2.50171 8.82957 2.50171 6.61729C2.50171 4.40813 4.28903
						2.61768 6.50132 2.61768C8.71048 2.61768 10.5009 4.405 10.5009 6.61729C10.5009 8.82644
						8.7136 10.6169 6.50132 10.6169Z"
							fill="#FCB53B"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
}

export default NoResult;
