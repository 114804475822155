import { ListingIdealUseEnum } from "..//..//graphql/types";

export const idealUseTypes = [
	{
		value: ListingIdealUseEnum.Auctions,
		title: "Auctions",
	},
	{
		value: ListingIdealUseEnum.ECommerceSales,
		title: "E-commerce Sales",
	},
	{
		value: ListingIdealUseEnum.PalletSales,
		title: "Pallet Sales",
	},
	{
		value: ListingIdealUseEnum.PerPieceSales,
		title: "Per Piece Sales",
	},
	{
		value: ListingIdealUseEnum.RetailSales,
		title: "Retail Sales",
	},
	{
		value: ListingIdealUseEnum.BinStores,
		title: "Bin Stores",
	},
	{
		value: ListingIdealUseEnum.FleaMarkets,
		title: "Flea Markets",
	},
];
