import * as React from "react";
import { Pagination } from "@mui/material";
import { useRouter } from "next/router";
import styles from "./pagination.module.scss";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "black",
		},
	},
}));
interface IPaginationProps {
	skip: number;
	take?: number;
	totalCount: number;
}

export function PaginationComponent({
	skip = 0,
	take = 6,
	totalCount,
}: IPaginationProps) {
	const router = useRouter();
	const query = router.query;
	const handlePaginationChange = (e: any, p: any) => {
		router.push({
			query: {
				...query,
				skip: (p - 1) * take,
			},
		});
	};

	const count = Math.ceil(totalCount / take);

	return (
		<div className={styles.pagination}>
			<Pagination
				count={count}
				color="standard"
				page={Math.floor(skip / take) + 1}
				onChange={handlePaginationChange}
				variant="outlined"
				size="small"
				siblingCount={1}
				boundaryCount={1}
				shape="rounded"
			/>
		</div>
	);
}
