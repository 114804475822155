import React, { useContext, useEffect, useState } from "react";
import styles from "./item-info-list.module.scss";
import { StaticContext } from "../../context/static-context/static-context";
import { deliveryTypes } from "../translations/delivery-types";
import { numberWithCommas } from "../price-info/price-info";
//import { deliveryName } from "../../pages/items";

export interface InventoryInfoListProps
	extends React.HTMLAttributes<HTMLDivElement> {
	ele: any;
	retailers: string;
}

function InventoryInfoListItem({
	infoKey,
	infoValue,
	className,
}: {
	infoKey: string;
	infoValue: string;
	className?: string;
}): JSX.Element {
	return (
		<div
			className={`
			${className ? className : ""}
			${infoKey ? styles.listItem : ""}
		`}
		>
			<div className={styles.key}>{infoKey}</div>
			<div className={infoKey ? styles.line : ""}></div>
			<div
				className={`${styles.value} ${styles.animated} ${styles.animatedfadeInRight} ${styles.fadeInRight}`}
			>
				{infoValue}
			</div>
		</div>
	);
}

function InventoryInfoList({
	ele,
	retailers,
}: InventoryInfoListProps): JSX.Element {
	console.log(ele)
	const contextValue = useContext(StaticContext);
	/* const [retailValueName, setRetailValueName] = useState<string>(
		"Estimated Retail Value",
	); */
	const [estimatedValueType, setEstimatedValueType] = useState<string>(
		"",
	);

	const [palletValueType, setPalletValueType] = useState<string>(
		"",
	);

	const [pieceCountName, setPieceCountName] = useState<string>("Piece Count");

	const stateFullName = (str: string | undefined) => {
		let result = contextValue.states.filter((ele) => ele.abbreviation === str);
		let nameStates = result[0]?.name;

		return nameStates;
	};

	let deliveryName = "";

	for (let i in deliveryTypes) {
		if (deliveryTypes[i].value === ele.deliveryType) {
			deliveryName = deliveryTypes[i].title;
		}
	}

	const listingIdealUseOptions: any = {
		"": "N/A",
		AUCTIONS: "Auctions",
		E_COMMERCE_SALES: "E-commerce Sales",
		PALLET_SALES: "Pallet Sales",
		PER_PIECE_SALES: "Per Piece Sales",
		RETAIL_SALES: "Retail Sales",
		BIN_STORES: "Bin Stores",
		FLEA_MARKETS: "Flea Market",
	};

	let idealUse: string[] = [];
	for (let i in ele.listingIdealUse) {
		idealUse.push(listingIdealUseOptions[ele.listingIdealUse[i]]);
	}

	const listIdealUse = () => {
		if (!ele.listingIdealUse.length) {
			return "N/A";
		}
		return (
			<div className={styles.listIdealUse}>
				{idealUse.map((ele: string, i: number) => (
					<div key={i}>{ele}</div>
				))}
			</div>
		);
	};

	console.log('useEffect()')
	useEffect(() => {
		if (ele?.manifestFile) {
			//setRetailValueName("Retail Value");
			setEstimatedValueType(ele?.estimatedValueType + " Value");
		} else {
			//setRetailValueName("Estimated Retail Value");
			setEstimatedValueType((ele.estimatedValueType != null)?'Estimated '+ele?.estimatedValueType + ' Value': 'Estimated Value');
			setPieceCountName("Estimated Piece Count");
		}
		setPalletValueType((ele.palletValueType != null)?'Pallet '+ele?.palletValueType : '');
	}, [ele?.manifestFile]);

	const mockItems: any[] = [
		[
			"Inventory Load Size",
			contextValue.translator(ele.inventoryLoadSize) || "N/A",
		],
		["Cost", `$ ${numberWithCommas(ele?.cost - ele?.discount)}` || "N/A"],
		["Retailer", retailers || "N/A"],
		["", "break"],
		["Average Pallet Height", ele?.palletHeight || "N/A"],
		[pieceCountName, numberWithCommas(ele?.estimatedPieceCount) || "N/A"],
		[
			"State of Shipping Origin",
			contextValue.states.filter(
				(e) => e.abbreviation === ele.inventoryLoadFOB?.state,
			)[0]?.name || "N/A",
		],
		["Freight Options", deliveryName || "N/A"],
		["Ideal Use", listIdealUse() || "N/A"],

		["", "break"],
		["Available For Export", !ele.availableForExport ? "NO" : "YES"],
		["Selling Restrictions", ele.sellingRestrictions || "N/A"],
		["Available for Brokering", !ele.canBeSoldByThirdParty ? "NO" : "YES"],
		["Lead Time", ele.leadTimeField > 7?'Inquire for details':ele.leadTimeField+' Days'],
		["", "break"],
		

		// ["Street", ele.inventoryLoadFOB?.street || "N/A"],
		// ["City", ele.inventoryLoadFOB?.city || "N/A"],
		// [
		// 	"State Zip",
		// 	`${stateFullName(ele.inventoryLoadFOB?.state) || "N/A"}, ${
		// 		ele.inventoryLoadFOB?.zipCode || "N/A"
		// 	}` || "N/A",
		// ],

		// ["Street", ele.inventoryLoadFOB?.street || "N/A"],
		// ["City", ele.inventoryLoadFOB?.city || "N/A"],
		// ["State", ele.inventoryLoadFOB?.state || "N/A"],
	];

	if (contextValue.translator(ele.inventoryLoadSize) === "Truckload") {

			mockItems.splice(5, 0, [
				palletValueType,
				numberWithCommas((ele?.palletCount != '') ? ele?.palletCount : ele?.palletSpace)
			]);
		/* ele?.palletSpace &&
			mockItems.splice(5, 0, [
				palletValueType,
				numberWithCommas(ele?.palletSpace) || "N/A",
			]); */

		// ["Street", ele.inventoryLoadFOB?.street || "N/A"],
		// ["City", ele.inventoryLoadFOB?.city || "N/A"],

		// if (contextValue.translator(ele.inventoryLoadSize) === "Truckload") {
		// 	if (ele?.palletCount) {
		// 		mockItems.splice(4, 0, [
		// 			"Pallet Count",
		// 			numberWithCommas(ele?.palletCount) || "N/A",
		// 		]);
		// 	}

		// 	if (ele?.palletSpace) {
		// 		mockItems.splice(4, 0, [
		// 			"Pallet Space",
		// 			numberWithCommas(ele?.palletSpace) || "N/A",
		// 		]);
		// 	}
		// }

		/* if (ele?.estimatedRetailValue) {
			mockItems.splice(5, 0, [
				retailValueName,
				`$ ${numberWithCommas(ele?.estimatedRetailValue)}` || "N/A",
			]);
		} */

		if (ele?.wholeSalesValue ) {
			mockItems.splice(5, 0, [
				estimatedValueType,
				`$ ${numberWithCommas(ele?.wholeSalesValue) }`  ,
			]);
		}else {
			mockItems.splice(5, 0, [
				estimatedValueType, "N/A",
			])
		}
	}

	if (contextValue.translator(ele.inventoryLoadSize) !== "Truckload") {
		mockItems.push.apply(mockItems, [
			["Pallet / Box Lot Dimensions", "break"],
			["Width", ele.width || "N/A"],
			["Height", ele.height || "N/A"],
			["Length", ele.length || "N/A"],
			["Weight", ele.weight || "N/A"],
		]);
	}

	return (
		<div className={styles.inventoryInfoList}>
			{mockItems
				.filter((item) => item)
				.map((item, i) => (
					<InventoryInfoListItem
						key={i}
						className={item[1] == "break" ? styles.topMargin : ""}
						infoKey={item[0]}
						infoValue={item[1] != "break" ? item[1] : ""}
					/>
				))}
		</div>
	);
}

export default InventoryInfoList;
