import Head from "next/head";
import { ReactNode } from "react";

interface SchemaProps {
	siteTitle: string;
	schema?: any;
	children?: string | ReactNode | JSX.Element | JSX.Element[];
}

export default function Schema({
	siteTitle,
	schema,
	children,
}: SchemaProps): JSX.Element {
	return (
		<Head>
			{children}
			<script
				key={`schemaJSON-${siteTitle}`}
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
			/>
		</Head>
	);
}
