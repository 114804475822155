import React, { useEffect, useState, useContext } from "react";
import styles from "./search-bar.module.scss";
import useWindowSize from "../../hooks/useWindowSize";
import Button from "../common/button/button";
import IconComponent from "../icon/icon";
import { IconData } from "../icon/icon-data";
import { StaticContext } from "../../context/static-context/static-context";
import { useRouter, NextRouter } from "next/router";

interface SearchBarSmallProps {
	isCompact?: boolean;
	value?: number;
	onChangeValue?: any;
	onFormSubmit?: any;
	max?: any;
}

function SearchBarSmall({
	isCompact = false,
	value,
	onChangeValue,
	onFormSubmit,
	max,
}: SearchBarSmallProps): JSX.Element {
	const [isMobile, setIsMobile] = useState(false);
	const { width } = useWindowSize();

	useEffect(() => {
		const isMobileWidth = () => (width <= 540 ? true : false);
		setIsMobile(isMobileWidth);
	}, [setIsMobile, width]);

	const chekedValue = (e: any) => {
		if (e.target.value < 0) return;
		if (e.target.value > max) return;
		onChangeValue(e.target.value);
	};

	const handleKeyPress = (event: React.KeyboardEvent): any => {
		if (event.key === "Enter") {
			onFormSubmit(event);
		}
	};

	return (
		<>
			{!isCompact ? (
				<div className={styles.searchBarSmall}>
					<input
						type="number"
						value={value}
						onKeyPress={handleKeyPress}
						inputMode="numeric"
						pattern="[0-9]*"
						onChange={(e) => chekedValue(e)}
						placeholder={"Min Value"}
					/>
				</div>
			) : (
				<div className={styles.searchBarCompact}>
					<input type="number" placeholder="" />
				</div>
			)}
		</>
	);
}

export default SearchBarSmall;
