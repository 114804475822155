import React, { useContext, useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import SearchBarSmallDouble from "../search-bar/search-bar-small-duble";
import styles from "./filter-minValue.module.scss";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../pages/inventory/InventoryPage";

interface FilterCostProps {
	onChangeFrom?: (value: string) => void;
	onChangeTo?: (value: string) => void;
	onFormSubmit?: any;
}

const FilterCost = ({
	onChangeFrom,
	onChangeTo,
	onFormSubmit,
}: FilterCostProps) => {
	const STEP = 1;
	const MIN = 0;
	const MAX = 100_000;
	const [values, setValues] = useState<any>([0, 100_000]);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const onChangeValue = (first: any, second: any) => {
		setValues([first, second]);
	};

	useEffect(() => {
		onChangeFrom && onChangeFrom(values[0]);
	}, [values[0]]);

	useEffect(() => {
		onChangeTo && onChangeTo(values[1]);
	}, [values[1]]);

	useEffect(() => {
		setValues([
			queryParameters.currentPriceFrom || MIN,
			queryParameters.currentPriceTo || MAX,
		]);
	}, [queryParameters.currentPriceFrom, queryParameters.currentPriceTo]);

	return (
		<>
			<div className={styles.conteiner}>
				<div className={styles.title}>Cost</div>
				<Range
					values={values}
					step={STEP}
					min={MIN}
					max={MAX}
					onChange={(values) => {
						setValues(values);
					}}
					renderTrack={({ props, children }) => (
						<div
							onMouseDown={props.onMouseDown}
							onTouchStart={props.onTouchStart}
							className={styles.button}
						>
							<div
								ref={props.ref}
								style={{
									height: "3px",
									width: "100%",
									borderRadius: "4px",
									background: getTrackBackground({
										values,
										colors: ["#ccc", "#4b9ad4", "#ccc"],
										min: MIN,
										max: MAX,
									}),
									alignSelf: "center",
								}}
							>
								{children}
							</div>
						</div>
					)}
					renderThumb={({ props, isDragged }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: "1.5rem",
								width: "1.5rem",
								borderRadius: "50%",
								backgroundColor: "#FFF",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div
								style={{
									height: "1.5rem",
									width: "1.5rem",
									borderRadius: "50%",
									backgroundColor: isDragged ? "#4b9ad4" : "#CCC",
								}}
							/>
						</div>
					)}
				/>

				<SearchBarSmallDouble
					values={values}
					onChangeValue={onChangeValue}
					onChangeFrom={onChangeFrom}
					onChangeTo={onChangeTo}
					max={MAX}
					min={MIN}
					onFormSubmit={onFormSubmit}
				/>
			</div>
		</>
	);
};

export default FilterCost;
