import React from "react";
import { capitalizeFirst } from "./utils";
import styles from "./button.module.scss";

export enum ButtonColour {
	BLUE = "blue",
	GREY = "grey",
	DARK = "dark",
	DARK_GREY = "darkGrey",
	TRANSPARENT = "transparent",
	YELLOW = "yellow",
	RED = "red",
	ORANGE = "orange",
	MUTED = "muted",
	GREEN = "green",
}

interface ButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	title?: string;
	color?: string;
	disabled?: boolean;
}

function Button({
	children,
	className,
	color = ButtonColour.YELLOW,
	title,
	type = "button",
	onClick,
	disabled = false,
}: ButtonProps): JSX.Element {
	const buttonColorStyles = {
		blue: "buttonBlue",
		grey: "buttonGrey",
		dark: "buttonDark",
		darkGrey: "buttonDarkGrey",
		transparent: "buttonTransparent",
		yellow: "buttonYellow",
		red: "buttonRed",
	};

	return (
		<button
			className={`
        ${styles.buttonBase}
        ${className ? className : ""}
        ${styles["button" + capitalizeFirst(color)]}
      `}
			type={type}
			onClick={onClick}
			disabled={disabled}
		>
			{title}
			{children}
		</button>
	);
}

export default Button;
