import style from "./cartCategories.module.scss";
import React, { useContext } from "react";
import AternativeImage from "../../UI/aternativeImage";
import { StaticContext } from "../../context/static-context/static-context";
import useHover from "../../hooks/useHover";
import PriceInfo from "../price-info/price-info";
import { deliveryTypes } from "../translations/delivery-types";
import CaruselImage from "./carusel/caruselImage";
import { useRouter } from "next/router";
import useWindowSize from "../../hooks/useWindowSize";

interface cartCategoriesProps {
	imagePath: string;
	title: string;
	categories: string[];
	onBoxClick?(): void;
	discountI: number;
	cost: number;
	qty: number;
	condition: string[];
	size: string;
	retailer: string;
	palletHeight: string;
	location: string;
	estValue: number;
	deliveryType: string;
	allImage?: any;
}

function CartCategories({
	imagePath,
	title,
	categories,
	onBoxClick,
	discountI,
	cost,
	qty,
	condition,
	size,
	retailer,
	palletHeight,
	location,
	estValue,
	deliveryType,
	allImage,
}: cartCategoriesProps): JSX.Element {
	const contextData = useContext(StaticContext);
	const categoriesArrey: any = [];
	const [hover, isHover] = useHover<HTMLDivElement>();
	const { width } = useWindowSize();

	categories.forEach((ele) => {
		let item = contextData.categories.filter((i) => i.id === ele);
		categoriesArrey.push(item[0]);
	});

	const renderCategories = () => {
		const result = categoriesArrey.filter(
			(i: any) => i?.parent?.id === "0e46c6e5-b0cc-4cfe-b3ff-08e7b9af7aa3",
		);
		return result;
	};

	const discount: number = discountI || 0;
	const oldPrice: number = cost || 0;
	const newPrice: number = oldPrice - discount;

	const sale: number = +((discount / oldPrice) * 100).toFixed(2);
	let deliveryName = "";

	for (let i in deliveryTypes) {
		if (deliveryTypes[i].value === deliveryType) {
			deliveryName = deliveryTypes[i].title;
		}
	}

	return (
		<div className={style.itemBox} ref={hover}>
			<div className={style.image}>
				{allImage.length <= 1 || width <= 576 ? (
					<AternativeImage
						src={imagePath}
						alternativeText={"item fotto"}
						blurDataURL={imagePath}
						onBoxClick={onBoxClick}
					/>
				) : (
					<CaruselImage allImage={allImage} onBoxClick={onBoxClick} />
				)}
			</div>

			<span
				onClick={() => {
					onBoxClick && onBoxClick();
				}}
			>
				<div className={style.title}>
					<p>{title}</p>
				</div>

				{width > 576 && (
					<>
						<div className={style.categories}>
							{renderCategories().map((ele: any) => (
								<p key={ele.id}>{ele.title}</p>
							))}
						</div>

						<div className={style.priceBox}>
							<PriceInfo
								design={"primary"}
								price={newPrice}
								oldPrice={oldPrice}
								save={sale}
								effectAnimation={isHover}
							/>
							<div className={style.priceBoxRigth}>
								<p>
									Qty:<span>{(qty <= 3)? qty :'3 +'}</span>
								</p>
								<p>
									Condition:
									<span>
										{contextData.getVocabularyEntry(condition[0])?.title}
									</span>
								</p>
							</div>
						</div>

						<div className={style.infoItem}>
							<div className={style.info}>
								<p>Load Size:</p>
								<p>Retailer:</p>
								<p>Pallet Height:</p>
								<p>Location:</p>
								<p>Est. Value:</p>
								<p>Delivery Type:</p>
							</div>

							<div className={style.result}>
								<p>{contextData.getVocabularyEntry(size)?.title}</p>
								<p>
									{
										contextData.retailers.filter(
											(ele: any) => ele.id === retailer[0],
										)[0]?.name
									}
								</p>
								<p>{palletHeight || "-"}</p>
								<p>{contextData.getStateByAbbreviation(location)?.name}</p>
								<p>{`$ ${estValue || "-"}`}</p>
								<p>{deliveryName}</p>
							</div>
						</div>
					</>
				)}
				
			</span>
		</div>
	);
}

export default CartCategories;
