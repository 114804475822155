import React, {
	Children,
	ReactNode,
	useCallback,
	useEffect,
	useState,
	useContext,
} from "react";
import styles from "./filter-item.module.scss";
import Divider from "../common/divider/divider";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../pages/inventory/InventoryPage";
import { StaticContext } from "../../context/static-context/static-context";
export interface FilterItemProps {
	title?: string;
	mobile?: boolean;
	onChangeValue: (value: boolean | undefined) => void;
	isFirstRadioChecked: boolean | undefined;
}

function FilterRadio({
	title,
	mobile = false,
	onChangeValue,
	isFirstRadioChecked = undefined,
}: FilterItemProps) {
	const router = useRouter();
	const contextData = useContext(StaticContext);

	return (
		<>
			{!mobile && (
				<div
					className={`${styles.filterItem} ${
						mobile ? styles.filterItemMobile : ""
					}`}
				>
					<div className={styles.checkboxesconteiner}>
						<div className={styles.checkboxTitle}>
							{title}
							{mobile && <Divider />}
						</div>
						<div className={styles.checkboxes}>
							<label className={styles.checkboxesitem}>
								<input
									type="radio"
									name={`name ${title}`}
									value={title}
									onChange={() => onChangeValue(true)}
									checked={isFirstRadioChecked === true ? true : false}
								/>{" "}
								Yes
							</label>
							<label className={styles.checkboxesitem}>
								<input
									type="radio"
									name={`name ${title}`}
									value={title}
									onChange={() => onChangeValue(false)}
									checked={isFirstRadioChecked === false ? true : false}
								/>{" "}
								No
							</label>
							<label className={styles.checkboxesitem}>
								<input
									type="radio"
									name={`name ${title}`}
									value={title}
									onChange={() => onChangeValue(undefined)}
									checked={isFirstRadioChecked === undefined ? true : false}
								/>{" "}
								Both
							</label>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default FilterRadio;
