import React, { useEffect, useState, useContext } from "react";
import gql from "graphql-tag";
import { useRouter, NextRouter } from "next/router";
import { Context, useQuery } from "urql";
import {
	ItemDetail_GetItemQuery,
	ItemDetail_GetItemQueryVariables,
} from "./[id].graphql.module";
import styles from "./[id].module.scss";
import Badge, { BadgeColour } from "../../src/components/common/badge/badge";
import Link from "next/link";
import { StaticContext } from "../../src/context/static-context/static-context";
import Loader from "../../src/components/loader/loader";
import Schema from "../../src/components/layout-new/schema";
import Images from "../../src/components/common/fb-image-grid/Images";
import PriceInfo from "../../src/components/price-info/price-info";
import Button, {
	ButtonColour,
} from "../../src/components/common/button/button";
import Divider from "../../src/components/common/divider/divider";
import GetInTouchFormNew from "../../src/components/forms/get-in-touch-form/get-in-touch-form-new";
import InventoryInfoList from "../../src/components/item-info-list/item-info-list";
import SimilarCategories from "../../src/components/similar_сategories";
import { CategoryItem } from "../../src/components/common/category-dropdown/category-dropdown";
import NextLink from "next/link";
// @ts-ignore
import HubspotForm from "react-hubspot-form";
import HubspotFormBox from "../../src/components/hubspot/hubspotForm";
import MyCustom404Page from "../404";

export const GET_ITEM = gql`
	query itemDetail_getItem($id: String!) {
		getItem(id: $id) {
			error {
				message
				code
			}
			success
			data {
				id
				title
				compiledTitle
				cost
				estimatedPieceCount
				canBeSoldByThirdParty
				availableLoadsQuantity
				palletCount
				retailer
				estimatedRetailValue
				manifestFile
				description
				availableForExport
				sellingRestrictions
				inventoryLoadSize
				listingStatus
				userId
				wholeSalesValue
				deliveryType
				listingIdealUse
				commission
				inventoryLoadCondition
				inventoryLoadCategory
				palletSpace
				estimatedValueType
				palletValueType
				inventoryLoadFOB {
					city
					state
					street
					zipCode
				}
				palletCount
				gallery {
					id
					path
					thumbnail
					isActualImage
				}
				width
				height
				length
				weight
				discount
				palletSpace
				notesField
				leadTimeField
				palletHeight
			}
		}
	}
`;

export const conditionType: { [key: string]: string } = {
	"Master Packs / Case Packs":
		"This product has never been opened nor taken out of the cases it was received in. The product has never been placed on a retail stores shelves for sale. This product type will contain multiple quantities of each item, and sometimes a very large quantity of each product will be received.",
	"Like New":
		"Items fall into several distinct sub-categories of merchandise such as; Shelf-Pulled merchandise that has been for sale by a retailer and removed from its master-pack, open-box merchandise that is in excellent condition with no wear but may have missing or open original packaging but otherwise of an original manufactured state including all product accessories,  Dot-Com Undelivered merchandise from online retailers with minimal shipping wear - typically the result of a faulty customer delivery address or other common shipping error but can be the result of a customer non-pickup at their local retail outlet - or lastly, Warehouse-Damaged merchandise where the product packaging has been smashed or torn so that the product is no longer presentable in a traditional retail environment.",
	New:
		"Items are new, never used or opened. It is still sealed in the manufacturer’s packaging with no cosmetic defects",
	"Scratch & Dent":
		"Items damaged from being in the warehouse or during shipping.  These items have cosmetic damage and potentially functionality defects. The item may be missing manuals and accessories.  The items generally will not ship in the original retail packaging.",
	Refurbished:
		"Items have been restored to like-new condition by a manufacturer, vendor or third party.  The item is tested for functionality and defects, repaired and re-packaged it protective packaging before being re-sold to the public.",
	Salvage:
		"Items that have been tested and failed a technical functionality or physical condition test. The packaging may be in various states, including: opened, distressed, and/or missing. There may be units that lack essential components, accessories, and documentation. This inventory condition is always sold AS IS and no cosmetic or operational condition is guaranteed.",
	"Consumer Returns- store":
		"Items that have been sold to an end-user and returned. The items could  have been returned through a retail storefront, manufacturer,  and/or distribution center. The packaging may be in various states,  including: opened, distressed, and/or missing. There may be units that  lack essential components, accessories, and documentation. This  inventory condition is always sold AS IS and warranties are not valid.  No cosmetic or operational condition is guaranteed. Normally, this  condition should always be considered untested and, generally, will have  a 75% plug-and-play success rate. Returns from “.com” are generally  considered a cleaner type compared to store returns.",
	"Consumer Returns -.com":
		"Items that have been sold to an end-user and returned. The items could  have been returned through a website and is usually a bit cleaner than store returns. The packaging may be in various states,  including: opened, distressed, and/or missing. There may be units that  lack essential components, accessories, and documentation. This  inventory condition is always sold AS IS and warranties are not valid.  No cosmetic or operational condition is guaranteed. Normally, this  condition should always be considered untested and, generally, will have  a 75% plug-and-play success rate. Returns from “.com” are generally  considered a cleaner type compared to store returns.",
};

const sizeType: { [key: string]: string } = {
	Truckload:
		" are the largest unit size you can purchase in the liquidation industry.  Typically a full truckload contains 24-26 pallets when single stacked and up to 56 pallets if double stacked.  A full truckload can also contain less pallets if  oversized pallets are included in the shipment.  It would still make use of 24-26 pallet spaces (see the FAQ for more information about pallet orientations).",
	Pallet:
		'are the building blocks of a truckload (unless the truck is floor stacked).  Pallets can also be purchased individually.  The standard pallet (in the united states) takes up a space of 48" in length and 40" in depth and supports merchandise wrapped in cellophane or a single gaylord box filled with product.',
	"Box Lot":
		"are a boxed collection of items which can be shipped by traditional means (i.e. FED-EX, UPS, USPS).",
};

const conditionTypeTitle: { [key: string]: string } = {
	"Master Packs / Case Packs": "Case-packed/Master-packed:",
	"Like New": "Like New:",
	New: "New:",
	"Consumer Returns": "Consumer Returns:",
	"Scratch & Dent": "Scratch & Dent:",
	Refurbished: "Refurbished:",
	Salvage: "Salvage:",
	"Consumer Returns -.com": "Consumer Returns- .com:",
	"Consumer Returns- store": "Consumer Returns- store:",
};

const sizeTypeTitle: { [key: string]: string } = {
	Truckload: "Truckload / Container:",
	Pallet: "Pallet:",
	"Box Lot": "Box Lot:",
};

function SingleInventory({}): JSX.Element {
	const [arrayCategories, setArrayCategories] = useState<string[]>([]);
	const fileServerURL = `${process.env.FILE_SERVER_DOMAIN}/`;

	const router = useRouter();
	const { id } = router.query;
	const contextData = useContext(StaticContext);

	// TODO fix any type
	const images: any = [];
	const [categoryTree, setCategoryTree] = useState<CategoryItem[]>([]);
	const [openForm, setOpenForm] = useState(false);

	const [imagesList, setImagesList] = useState<string[]>([]);

	const closeForm = () => setOpenForm(false);

	const [openFormContact, setOpenFormContact] = useState(false);

	const [downloadLink, setDownloadLink] = useState<boolean>(true);
	const [manifestDisable, setManifestDisable] = useState<boolean>(false);

	const [conditionTranscript, setConditionTranscript] = useState<string>("");
	const [sizeTranscript, setSizeTranscript] = useState<string>("");

	const [
		conditionTranscriptTitle,
		setConditionTranscriptTitle,
	] = useState<string>("");
	const [sizeTranscriptTitle, setSizeTranscriptTitle] = useState<string>("");
	const [loadsQuantity, setLoadsQuantity] = useState<string>("");

	const closeFormContact = () => setOpenFormContact(false);

	const [itemDetailResult, getItemDetail] = useQuery<
		ItemDetail_GetItemQuery,
		ItemDetail_GetItemQueryVariables
	>({
		query: GET_ITEM,
		requestPolicy: "cache-and-network",
		variables: {
			id: id as string,
		},
	});

	useEffect(() => {
		const list: any[] = [];

		for (const { path, isActualImage } of itemDetailResult.data?.getItem.data
			?.gallery || []) {
			list.push(`${process.env.FILE_SERVER_DOMAIN}/${path} ${isActualImage}`);
		}

		setImagesList(list);
	}, [itemDetailResult]);

	itemDetailResult.data?.getItem.data?.gallery.forEach((ele) => {
		images.push({
			original: `${process.env.FILE_SERVER_DOMAIN}/${ele.path}`,
			thumbnail: `${process.env.FILE_SERVER_DOMAIN}/${ele.thumbnail}`,
			originalHeight: 300,
			originalWidth: 300,
			thumbnailHeight: 115,
			thumbnailWidth: 115,
			originalClass: styles.test,
			thumbnailClass: "originalThumbnailClass",
		});
	});

	const item = itemDetailResult?.data?.getItem?.data;

	const parentsCategories = (e: any) => {
		let filter: any = contextData.categories.filter((ele) => ele.id === e);
		return filter[0];
	};

	let eleCategories = item?.inventoryLoadCategory.map((ele: string) =>
		parentsCategories(ele),
	);

	const nameCategories = (ele: string) => {
		let result = contextData.getCategory(ele);

		return result?.title;
	};
	const stateData = contextData.getStateByAbbreviation(
		item?.inventoryLoadFOB.state || "",
	);

	const nameCategoriesTitle: string[] = [];

	eleCategories?.map((ele) => {
		if (ele?.parent.id === "0e46c6e5-b0cc-4cfe-b3ff-08e7b9af7aa3") {
			nameCategoriesTitle.push(ele.title);
		}
	});

	const retailerId = item?.retailer;
	const retailerName = contextData?.retailers.filter(
		(ele) => ele.id === `${retailerId}`,
	);

	const nameState = contextData.states.filter(
		(ele) => ele.abbreviation === item?.inventoryLoadFOB.state,
	);

	useEffect(() => {
		setArrayCategories(
			itemDetailResult.data?.getItem.data?.inventoryLoadCategory || [],
		);
	}, [itemDetailResult.data?.getItem.data?.inventoryLoadCategory]);

	useEffect(() => {
		itemDetailResult.data?.getItem.data?.manifestFile
			? setManifestDisable(true)
			: setManifestDisable(false);
	}, [itemDetailResult.data?.getItem.data?.manifestFile]);

	const discount: number = itemDetailResult.data?.getItem.data?.discount || 0;
	const oldPrice: number = itemDetailResult.data?.getItem.data?.cost || 0;
	const newPrice: number = oldPrice - discount;

	const sale: number = +((discount / oldPrice) * 100).toFixed(2);

	const schema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		name: item?.title,
		image: imagesList,
		description: item?.description,
		sku: item?.palletCount,
		brand: {
			"@type": "Brand",
			name: "BuyLow Warehouse",
		},
		offers: {
			"@type": "Offer",
			url: "/inventory/" + item?.id,
			priceCurrency: "USD",
			price: item?.cost,
			itemCondition: "https://schema.org/UsedCondition",
			availability: "https://schema.org/InStock",
		},
	};

	useEffect(() => {
		let result = contextData.translator(
			itemDetailResult.data?.getItem.data?.inventoryLoadCondition[0] || "",
		);

		setConditionTranscript(conditionType[result]);
		setConditionTranscriptTitle(conditionTypeTitle[result]);
	}, [
		itemDetailResult.data?.getItem.data?.inventoryLoadCondition,
		contextData,
	]);

	useEffect(() => {
		let result = contextData.translator(
			itemDetailResult.data?.getItem.data?.inventoryLoadSize || "",
		);
		setSizeTranscript(sizeType[result]);
		setSizeTranscriptTitle(sizeTypeTitle[result]);
	}, [itemDetailResult.data?.getItem.data?.inventoryLoadSize, contextData]);

	useEffect(() => {
		let result: number =
			itemDetailResult.data?.getItem.data?.availableLoadsQuantity || 0;
		if (result > 3) {
			setLoadsQuantity("3+");
		} else {
			setLoadsQuantity(result.toString());
		}
	}, [itemDetailResult.data?.getItem.data]);

	useEffect(() => {
		let test: any = [];

		item?.inventoryLoadCategory.map((ele) => {
			let result = contextData.categories.filter((cat) => cat.id === ele);
			test.push(result[0]);
		});

		setCategoryTree(test);
	}, [item?.inventoryLoadCategory]);

	const listingStatus = itemDetailResult.data?.getItem.data?.listingStatus;

	if (!itemDetailResult.data?.getItem.data) {
		if (itemDetailResult.fetching) {
			return (
				<div className={styles.idCardLoader}>
					<Loader />
				</div>
			);
		} else {
			return <MyCustom404Page />;
		}
	}

	return (
		<div className="container">
			<Schema siteTitle="inventory" schema={schema}></Schema>
			<div className={styles.singleInventoryContent}>
				<div className={styles.galleryWrapperFixed}>
					<div className={styles.galleryWrapper}>
						<div className={styles.galleryBox}>
							<Images
								images={imagesList.length ? imagesList : ["/img/thinkbig.png"]}
							/>
						</div>
					</div>
				</div>

				<div className={styles.singleInventoryInfo}>
					<h3 className={styles.title}>{item?.compiledTitle || "No title"}</h3>

					<div className={styles.bagesWrapper}>
						{categoryTree.map((ele) => (
							<div key={ele?.id}>
								{<Badge className={styles.badge} title={ele?.title || ""} />}
							</div>
						))}
					</div>

					<p className={`${styles.text}`}>{item?.description}</p>

					<PriceInfo
						className={styles.priceInfo}
						price={newPrice}
						oldPrice={oldPrice}
						save={sale}
					/>
					{manifestDisable && downloadLink && (
						<div className={styles.manifestSection}>
							<div className={styles.textWrapper}>
								<p className="text">Manifest Link:</p>
								<NextLink passHref href={fileServerURL + item?.manifestFile}>
									<a
										className={`${styles.manifestLink} ${
											manifestDisable ? styles.noActive : ""
										}`}
										onClick={() => setDownloadLink(false)}
									>
										Consumer Returns Truckload from-Manifest link
									</a>
								</NextLink>

								<div className={styles.tooltiptext}>
									{downloadLink
										? "Click to download Manifest"
										: "Manifest loaded"}
								</div>
							</div>
						</div>
					)}

					<div className={styles.quantity}>
						<p className={`text ${styles.text}`}>Available Quantity:</p>
						<Badge
							className={styles.badge}
							color={BadgeColour.LIGHT}
							title={loadsQuantity}
						/>
					</div>

					{listingStatus === "ACTIVE" ? (
						<div className={styles.actions}>
							<Button
								title="Buy Now"
								onClick={() => setOpenFormContact(true)}
							/>
							<Button
								color={ButtonColour.DARK}
								title="Inquire Now"
								onClick={() => setOpenForm(true)}
							/>
						</div>
					) : (
						<div className={styles.actions}>
							<Button
								title="Buy Now"
								onClick={() => setOpenFormContact(true)}
								color={ButtonColour.GREY}
								disabled={true}
							/>
							<Button
								color={ButtonColour.GREY}
								title="Inquire Now"
								onClick={() => setOpenForm(true)}
								disabled={true}
							/>

							<div className={styles.notAvailable}>
								<div className={styles.notAvailableText}>
									This listing is not currently available
								</div>
							</div>
						</div>
					)}

					<div className={styles.generalInfo}>
						<InventoryInfoList
							ele={itemDetailResult.data?.getItem?.data || ""}
							retailers={retailerName[0]?.code}
						/>
					</div>

					<div className="conditionDescription">
						<div className={styles.headerWrapper}>
							<h4>Categories:</h4>
							<Divider />
						</div>
						<div className={styles.conditionBadges}>
							<div className={styles.conditionBadgesBox}>
								<ul className={styles.conditionBadgesTranscript}>
									{categoryTree.map((ele) => (
										<li key={ele?.id}>{ele?.title}</li>
									))}
								</ul>
							</div>
						</div>
					</div>

					<div className="conditionDescription">
						<div className={styles.headerWrapper}>
							<h4>Condition Description:</h4>
							<Divider />
						</div>
						<div className={styles.conditionBadges}>
							<div className={styles.conditionBadgesBox}>
								<div className={styles.conditionBadgesTranscript}>
									<span>{conditionTranscriptTitle}</span> {conditionTranscript}
								</div>
							</div>
						</div>
					</div>

					<div className="conditionDescription">
						<div className={styles.headerWrapper}>
							<h4>Size Description:</h4>
							<Divider />
						</div>

						<p className={styles.sizeDescriptionText}>
							<span>{sizeTranscriptTitle}</span> {sizeTranscript}
						</p>
					</div>
				</div>
			</div>

			{listingStatus === "ACTIVE" ? (
				<SimilarCategories categoriesID={arrayCategories} />
			) : (
				<div className={styles.buttonWrapper}>
					<Link href="/inventory">
						<a>
							<Button
								title="View All Products"
								color={ButtonColour.DARK}
								className={styles.button}
							/>
						</a>
					</Link>
				</div>
			)}
			{openForm && (
				// <GetInTouchFormNew
				// 	closeForm={closeForm}
				// 	listID={id}
				// 	ele={itemDetailResult.data?.getItem?.data}
				// 	title={"Inquire About this Listing."}
				// 	subTitle={
				// 		"Complete this short form to get more information about this listing."
				// 	}
				// />
				<HubspotFormBox
					closeForm={closeForm}
					title={"Inquire About this Listing."}
				>
					<HubspotForm
						portalId="23191308"
						formId="42e732bf-3330-4219-aaba-6aaae1eb2a77"
						onSubmit={() => console.log("Submit!")}
						onReady={(form: any) => console.log("Form ready!")}
						loading={
							<div className={styles.containerMainLoading}>Loading...</div>
						}
					/>
				</HubspotFormBox>
			)}
			{openFormContact && (
				// <GetInTouchFormNew
				// 	closeForm={closeFormContact}
				// 	listID={id}
				// 	ele={itemDetailResult.data?.getItem?.data}
				// 	title={"Buy Now"}
				// 	subTitle={
				// 		"Complete this short form to begin a conversation with one of our brokers."
				// 	}
				// />
				<HubspotFormBox closeForm={closeFormContact} title={"Buy Now"}>
					<HubspotForm
						portalId="23191308"
						formId="9221465b-70c5-4f4b-bcd7-55bbf71f34e2"
						onSubmit={() => console.log("Submit!")}
						onReady={(form: any) => console.log("Form ready!")}
						loading={
							<div className={styles.containerMainLoading}>Loading...</div>
						}
					/>
				</HubspotFormBox>
			)}
		</div>
	);
}

export default SingleInventory;
