import React, {
	Children,
	ReactNode,
	useCallback,
	useEffect,
	useState,
	useContext,
} from "react";
import styles from ".././filter-item.module.scss";
import Badge from "../../common/badge/badge";
import useHover from "../../../hooks/useHover";
import { StaticContext } from "../../../context/static-context/static-context";
import { useScrollLock } from "../../../hooks/useScroll";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../../pages/inventory/InventoryPage";
import nextId from "react-id-generator";
import FilterModalReatiler from "./filter-modal-reatiler";

export interface FilterRetailerProps
	extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	handleRetailerChange?: (value: string) => void;
	handleDeleteOfRetailer?: (value: string) => void;
	onFormSubmit?: any;
	seo?: boolean;
}

function FilterRetailer({
	title,
	handleRetailerChange,
	handleDeleteOfRetailer,
	onFormSubmit,
	seo = false,
}: FilterRetailerProps) {
	const [searchArray, setSearchArray] = useState<string[]>([]);
	const [statesOpen, setStatesOpen] = useState<boolean>(false);

	const contextData = useContext(StaticContext);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const addArray = (e: any) => {
		handleRetailerChange && handleRetailerChange(e.id);
		const arrayID = searchArray.map((ele: any) => (ele = ele.id));
		if (arrayID.includes(e.id)) return;
		setSearchArray((prev) => [...prev, e]);
	};

	const deleteArray = (e: any) => {
		handleDeleteOfRetailer && handleDeleteOfRetailer(e.id);
		let filter = searchArray.filter((ele: any) => ele.id !== e.id);
		setSearchArray(filter);
	};

	useEffect(() => {
		let result: any = [];
		for (let i in queryParameters.retailer) {
			let target = contextData.retailers.filter(
				(ele) => ele.id === queryParameters.retailer[i],
			);
			result.push(target[0]);
		}

		setSearchArray(result);
	}, [router, contextData.retailers]);

	const chekedToggelState = () => {
		setStatesOpen(false);
	};

	return (
		<>
			<div className={styles.filterItem}>
				<div className={styles.title} onClick={() => setStatesOpen(true)}>
					{title}
				</div>
				{seo && <div className={styles.seo}></div>}

				<div className={styles.chekedCategoti}>
					{searchArray.map((ele: any) => (
						<Badge
							key={nextId()}
							title={ele?.code}
							selfClosing={true}
							onClick={() => deleteArray(ele)}
						/>
					))}
				</div>

				{statesOpen && (
					<FilterModalReatiler
						chekedToggelState={chekedToggelState}
						statesOpen={statesOpen}
						addArrayState={addArray}
						deleteArrayState={deleteArray}
						arrayState={searchArray}
						onFormSubmit={onFormSubmit}
					/>
				)}
			</div>
		</>
	);
}

export default FilterRetailer;
