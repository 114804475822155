import React, {
	Children,
	ReactNode,
	useCallback,
	useEffect,
	useState,
	useContext,
} from "react";
import styles from ".././filter-item.module.scss";
import Badge from "../../common/badge/badge";
import useHover from "../../../hooks/useHover";
import { StaticContext } from "../../../context/static-context/static-context";
import { useScrollLock } from "../../../hooks/useScroll";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../../pages/inventory/InventoryPage";
import nextId from "react-id-generator";
import FilterModalRegion from "./filter-modal-region";
import FilterModalRegionMap from "../filter-region-map/filter-modal-region-map";

export interface FilterRegionProps
	extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	handleRegionAdd?: (value: string) => void;
	handleRegionDelete?: (value: string) => void;
	onFormSubmit?: any;
}

function FilterRegion({
	title,
	handleRegionAdd,
	handleRegionDelete,
	onFormSubmit,
}: FilterRegionProps) {
	const [statesOpen, setStatesOpen] = useState<boolean>(false);
	const [searchArray, setSearchArray] = useState<string[]>([]);

	const contextData = useContext(StaticContext);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const addArray = (e: any) => {
		handleRegionAdd && handleRegionAdd(e.value);
		const arrayID = searchArray.map((ele: any) => (ele = ele.value));
		if (arrayID.includes(e.value)) return;
		setSearchArray((prev) => [...prev, e]);
	};

	const deleteArray = (e: any) => {
		handleRegionDelete && handleRegionDelete(e.value);
		let filter = searchArray.filter((ele: any) => ele.value !== e.value);
		setSearchArray(filter);
	};

	useEffect(() => {
		let result: any = [];
		for (let i in queryParameters.regions) {
			let target = {
				value: queryParameters.regions[i],
				title: queryParameters.regions[i],
				id: queryParameters.regions[i],
			};
			result.push(target);
		}
		setSearchArray(result);
	}, [router, contextData.regions]);

	const chekedToggelState = () => {
		setStatesOpen(false);
	};

	return (
		<>
			<div className={styles.filterItem}>
				<div className={styles.title} onClick={() => setStatesOpen(true)}>
					{title}
				</div>

				<div className={styles.chekedCategoti}>
					{searchArray.map((ele: any) => (
						<Badge
							key={nextId()}
							title={ele?.title || ele?.value}
							selfClosing={true}
							onClick={() => deleteArray(ele)}
						/>
					))}
				</div>

				{statesOpen && (
					// <FilterModalRegion
					// 	chekedToggelState={chekedToggelState}
					// 	statesOpen={statesOpen}
					// 	addArrayState={addArray}
					// 	deleteArrayState={deleteArray}
					// 	arrayState={searchArray}
					// 	onFormSubmit={onFormSubmit}
					// />
					<FilterModalRegionMap
						chekedToggelState={chekedToggelState}
						statesOpen={statesOpen}
						addArrayState={addArray}
						deleteArrayState={deleteArray}
						arrayState={searchArray}
						onFormSubmit={onFormSubmit}
					/>
				)}
			</div>
		</>
	);
}

export default FilterRegion;
