import { CircularProgress } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { gql, useQuery } from "urql";
import styles from "..//..//../pages/inventory/inventory.module.scss";
import LoadCard from "../inventory-card/load-card";
import { PaginationComponent } from "../pagination/pagination";
import {
	GetItemsListWithPermissionGrantQuery,
	GetItemsListWithPermissionGrantQueryVariables,
} from "./third-party-items-list.graphql.module";
import Loader from "../loader/loader";
import Button from "../common/button/button";
import Link from "next/link";
import {
	IQueryParameters,
	parametersToSearchData,
} from "../../../pages/inventory/InventoryPage";
import Schema from "../layout-new/schema";

export const typeLoadStatus: any = {
	ACTIVE: "published",
	DRAFT: "draft",
	REMOVED: "delete",
	SOLD: "sold",
};

export const GET_ITEMS_WITH_PERMISSION_GRANT = gql`
	query getItemsListWithPermissionGrant($searchData: SearchItemQuery!) {
		listWithPermissionGrant(searchData: $searchData) {
			data {
				authorizedTitle
				listingStatus
				listingIdealUse
				commission
				compiledTitle
				availableForExport
				availableLoadsQuantity
				deliveryType
				title
				description
				estimatedRetailValue
				wholeSalesValue
				gallery {
					id
					path
					isActualImage
					thumbnail
				}
				id
				inventoryLoadCategory
				inventoryLoadFOB {
					city
					state
					zipCode
					street
				}
				palletCount
				manifestFile
				sellingRestrictions
				retailer
				inventoryLoadCondition
				inventoryLoadSize
				cost
				discount
				estimatedPieceCount
				palletSpace
				width
				height
				length
				weight
				notesField
				leadTimeField
				palletHeight
			}
			totalCount
			success
		}
	}
`;

type ItemsListProps = {
	searchParameters: IQueryParameters;
	permissionGrantKey: string;
	rand?: number;
};

const ThirdPartyItemsList: FC<ItemsListProps> = ({
	searchParameters,
	permissionGrantKey,
	rand = 0,
}) => {
	const [getUserItemsResult, getUserItems] = useQuery<
		GetItemsListWithPermissionGrantQuery,
		GetItemsListWithPermissionGrantQueryVariables
	>({
		query: GET_ITEMS_WITH_PERMISSION_GRANT,
		requestPolicy: "cache-and-network",
		variables: {
			searchData: {
				permissionGrantKey,
				...parametersToSearchData(searchParameters),
			},
		},
	});
	const router = useRouter();

	useEffect(() => {
		if (rand !== 0) getUserItems();
	}, [rand]);

	if (!getUserItemsResult?.data?.listWithPermissionGrant?.data) {
		return <Loader />;
	}

	const data = getUserItemsResult?.data?.listWithPermissionGrant?.data;
	const totalCount =
		getUserItemsResult?.data?.listWithPermissionGrant?.totalCount;

	const schemaItem: any = [];

	data.forEach((ele, key) => {
		const imageGalley: string[] = [];

		for (const { path } of ele?.gallery || []) {
			imageGalley.push(`${process.env.FILE_SERVER_DOMAIN}/${path}`);
		}

		schemaItem.push({
			"@type": "ListItem",
			position: key,
			item: {
				"@type": "Product",
				url: "/inventory/" + ele.id,
				name: ele.title,
				image: imageGalley,
				description: ele.description,
				weight: ele.weight,
				width: ele.width,
				height: ele.height,
				length: ele.length,
				sku: ele.palletCount,
				offers: {
					"@type": "Offer",
					price: ele.cost,
					priceCurrency: "USD",
					discount: ele.discount,
				},
			},
		});
	});

	const schema = {
		"@context": "https://schema.org",
		"@type": "ItemList",
		itemListElement: schemaItem,
	};

	const reload = () => {
		getUserItems({ requestPolicy: "cache-and-network" });
	};

	return (
		<div className={styles.containetItemsBox}>
			<Schema siteTitle="third-party-items-list" schema={schema}></Schema>
			{data.length ? (
				<div className={styles.containetItems}>
					{data?.map((ele) => (
						<LoadCard
							key={ele.id}
							imagePath={
								ele.gallery[0]?.path
									? `${process.env.FILE_SERVER_DOMAIN}/${ele.gallery[0]?.path}`
									: "/img/thinkbig.png"
							}
							onBoxClick={() => router.push(`/inventory/${ele.id}`)}
							ele={ele}
							onDelete={reload}
							onUpdate={reload}
							loadStatus={typeLoadStatus[ele.listingStatus]}
						/>
					))}
				</div>
			) : (
				<div className={styles.containetNoItems}>
					<div>
						<Link href={`/third-party/${permissionGrantKey}`}>
							<a>
								<Button title="View All Products" className={styles.button} />
							</a>
						</Link>
					</div>
					<h4>There are no results for your search</h4>
					<Image
						src="/img/no-results.png"
						alt="Logo"
						width={150}
						height={150}
						objectFit="contain"
					/>
				</div>
			)}
			<div className={styles.containetItemspagination}>
				<PaginationComponent
					skip={searchParameters.skip}
					take={searchParameters.take}
					totalCount={totalCount || 0}
				/>
			</div>
		</div>
	);
};

export default ThirdPartyItemsList;
