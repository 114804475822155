import { NextPage } from "next";
import { NextRouter, useRouter } from "next/router";
import Head from "next/head";
import { useContext, useEffect, useState } from "react";
import Banner from "../../src/components/banner/banner";
import { StaticContext } from "../../src/context/static-context/static-context";
import styles from "./inventory.module.scss";
import {
	ListingIdealUseEnum,
	ListingStatusEnum,
} from "../../src/graphql/types";
import {
	GetItemsListQueryVariables,
	GetUserItemsQueryVariables,
} from "./index.graphql.module";
import useWindowSize from "../../src/hooks/useWindowSize";
import lodash from "lodash";
import SearchBar from "../../src/components/search-bar/search-bar";
import AllItemsList from "../../src/components/items/all-items-list";
import ThirdPartyItemsList from "../../src/components/items/third-party-items-list";
import NewLoadForm from "../../src/components/forms/new-load-form/new-load-form";
import FilterCategories from "../../src/components/filter-sidebar/filter-categories/filter-categories";
import FilterLoadCondition from "../../src/components/filter-sidebar/filter-condition/filter-load-condition";
import FilterIdealUse from "../../src/components/filter-sidebar/filter-idealUse/filter-idealUse";
import FilterRetailer from "../../src/components/filter-sidebar/filter-retailer/filter-retailer";
import FilterDeliveryType from "../../src/components/filter-sidebar/filter-delivery/filter-delivery-type";
import FilterRegion from "../../src/components/filter-sidebar/filter-region/filter-region";
import FilterState from "../../src/components/filter-sidebar/filter-state/filter-state";
import FilterMinValue from "../../src/components/filter-sidebar/filter-minValue";
import FilterCost from "../../src/components/filter-sidebar/filter-cost";
import FilterRadio from "../../src/components/filter-sidebar/filter-radio";
import FilterCheckboxes from "../../src/components/filter-sidebar/filter-checkboxes";

interface InventoryPageProps {
	isUserItems?: boolean;
	thirdParty?: boolean;
	seo?: boolean;
	retailerId?: string;
	seoCategories?: boolean;
	seoRetailers?: boolean;
	categoriesID?: string;
}

export default function InventoryPage({
	thirdParty = false,
	seo = false,
	retailerId,
	seoCategories = false,
	seoRetailers = false,
	categoriesID,
}: InventoryPageProps) {
	const [isMobile, setIsMobile] = useState(false);
	const [openForm, setOpenForm] = useState(false);
	const { width } = useWindowSize();

	useEffect(() => {
		const isMobileWidth = () => (width <= 600 ? true : false);
		setIsMobile(isMobileWidth);
	}, [setIsMobile, width]);

	const router = useRouter();
	const { categories } = useContext(StaticContext);
	const query = router.query;
	const permissionGrantKey = query.permissionGrantKey as string;
	const searchParameters = getParametersFromRouter(router);
	const [searchState, setSearchState] = useState<ISearchState>(
		searchParameters,
	);

	const [rand, setRand] = useState(0);
	const [minValue, setMinValue] = useState(0);
	const [searchTimeout, setSearchTimeout] = useState<
		NodeJS.Timeout | undefined
	>(undefined);

	const scheduleSearchTimeout = () => {
		removeSearchTimeout();
		const timeoutID = setTimeout(() => {
			if (JSON.stringify(searchState) !== JSON.stringify(searchParameters))
				onFormSubmit({
					preventDefault: () => {},
				});
		}, 750);

		setSearchTimeout(timeoutID);
	};

	const removeSearchTimeout = () => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
			setSearchTimeout(undefined);
		}
	};

	const updateSearchState = (p: Partial<ISearchState>) => {
		setSearchState({
			...searchState,
			...p,
		});
	};

	useEffect(() => {
		scheduleSearchTimeout();
	}, [JSON.stringify(searchState)]);

	const handleInventoryLoadCategoryChange = (value: string) => {
		let newVal = searchState.category || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			category: Array.from(new Set(newVal)),
		});
	};

	const handleDeleteCategory = (values: string[]) => {
		const newVal = searchState.category.filter((category: string) => {
			return values.indexOf(category) == -1;
		});
		updateSearchState({
			category: [...newVal],
		});
	};

	const handleRetailerChange = (value: string) => {
		let newVal = searchState.retailer || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			retailer: Array.from(new Set(newVal)),
		});
	};

	const handleDeleteOfRetailer = (value: string) => {
		const newVal = searchState.retailer.filter((retailer: string) => {
			return retailer != value;
		});
		updateSearchState({
			retailer: [...newVal],
		});
	};

	const handleConditionChange = (value: string) => {
		let newVal = searchState.loadCondition || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			loadCondition: Array.from(new Set(newVal)),
		});
	};
	const handleDeleteOfCondition = (values: string[]) => {
		const newVal = searchState.loadCondition.filter((condition: string) => {
			return values.indexOf(condition) == -1;
		});
		updateSearchState({
			loadCondition: [...newVal],
		});
	};

	const handleIdealUseChange = (value: any) => {
		let newVal = searchState.listingIdealUse || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			listingIdealUse: Array.from(new Set(newVal)),
		});
	};
	const handleIdealUse = (values: string[]) => {
		const newVal = searchState.listingIdealUse.filter((condition: string) => {
			return values.indexOf(condition) == -1;
		});
		updateSearchState({
			listingIdealUse: [...newVal],
		});
	};

	const handleSizeChange = (value: string) => {
		let newVal = searchState.size || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			size: Array.from(new Set(newVal)),
		});
	};
	const handleDeleteOfSize = (values: string[]) => {
		const newVal = searchState.size.filter((condition: string) => {
			return values.indexOf(condition) == -1;
		});
		updateSearchState({
			size: [...newVal],
		});
	};

	const handleRegionAdd = (value: string) => {
		let newVal = searchState.regions || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			regions: Array.from(new Set(newVal)),
		});
	};

	const handleRegionDelete = (value: string) => {
		const newVal = searchState.regions.filter((region: string) => {
			return region !== value;
		});
		updateSearchState({
			regions: [...newVal],
		});
	};

	const handleStateAdd = (value: string) => {
		let newVal = searchState.states || [];

		if (value !== "") {
			newVal.push(value);
		}

		updateSearchState({
			states: Array.from(new Set(newVal)),
		});
	};

	const handleStateDelete = (value: string) => {
		const newVal = searchState.states.filter((state: string) => {
			return state !== value;
		});
		updateSearchState({
			states: [...newVal],
		});
	};

	const listingStatusChangeSelection = (
		checkedStatues: ListingStatusEnum[],
	) => {
		updateSearchState({
			listingStatus: [...checkedStatues],
		});
	};

	useEffect(() => {
		const queryParameters = getParametersFromRouter(router);
		setSearchState((s) => queryParameters);
	}, [router, router.asPath]);

	const onFormSubmit = (e: any) => {
		e.preventDefault();

		const searchIsEqual = lodash.isEqual(searchParameters, searchState);

		const newQuery: any = {
			...query,
			...searchState,
			skip: searchIsEqual ? searchParameters.skip : 0,
			take: searchParameters.take,
		};

		for (let key in newQuery) {
			if (newQuery[key] === undefined || newQuery[key] === null) {
				delete newQuery[key];
			} else if (newQuery[key].length === 0) {
				delete newQuery[key];
			} else if (newQuery[key] === "") {
				delete newQuery[key];
			}
		}

		router.push(
			{
				query: newQuery,
			},
			undefined,
			{ scroll: false },
		);

		// router.push({
		//  query: {
		//      ...query,
		//      ...searchState,
		//      skip: searchParameters.skip,
		//      take: searchParameters.take,
		//  },
		// });
	};

	const cleanSearch = () => {
		let result = {
			availableForExport: undefined,
			canBeSoldByThirdParty: undefined,
			category: [],
			currentPriceFrom: undefined,
			currentPriceTo: undefined,
			deliveryType: "",
			domainName: "",
			fullTextSearch: "",
			loadCondition: [],
			minValue: "",
			regions: [],
			retailer: [],
			size: [],
			skip: 0,
			states: [],
			take: 9,
			listingStatus: [],
			ListingIdealUse: [],
		};

		return result;
	};

	const onFormClean = (e: any) => {
		e.preventDefault();

		const newQuery: any = {
			...query,
			...cleanSearch(),
			skip: searchParameters.skip,
			take: searchParameters.take,
		};

		for (let key in newQuery) {
			if (newQuery[key] === undefined || newQuery[key] === null) {
				delete newQuery[key];
			} else if (newQuery[key].length === 0) {
				delete newQuery[key];
			} else if (newQuery[key] === "") {
				delete newQuery[key];
			}
		}

		router.push(
			{
				query: newQuery,
			},
			undefined,
			{ scroll: false },
		);
	};

	const closeForm = () => {
		setOpenForm(false);
		setRand(Math.random());
	};

	if (width <= 576) {
		searchParameters.take = 8;
	}

	return (
		<div>
			<>
				{!seo && (
					<>
						{thirdParty ? (
							<Banner
								header="Third Party Broker Loads"
								type="third_party"
								text="Use the interfaces below to search your loads for curation or create new loads for BuyLow Warehouse LLC to broker on your behalf.  Thank you for partnering with Buylow Warehouse LLC."
							/>
						) : (
							<Banner
								header="Inventory"
								inventory={isMobile}
								type="inventory"
								text="The BuyLow Warehouse team focuses on offering a diverse selection of truckloads, pallets, and box lots at competitive pricing in an easy-to-search-format so that you can always find what you are looking for."
							/>
						)}
					</>
				)}

				<div className={`container ${styles.inventoryContainer}`}>
					{/* {!thirdParty  */}
					{false && (
						<div className={styles.searchBarWrapper}>
							<SearchBar
								isCompact={false}
								onFormSubmit={onFormSubmit}
								updateSearchState={updateSearchState}
								onFormClean={onFormClean}
							/>
						</div>
					)}

					{isMobile && thirdParty && (
						<div style={{ margin: "0 auto" }}>
							<button
								className={styles.inventoryContentButton}
								onClick={() => setOpenForm(true)}
							>
								+ New Load
							</button>
							{openForm && <NewLoadForm closeForm={closeForm} />}
						</div>
					)}

					<div className={styles.inventoryContent}>
						<div className={styles.filterSidebarWrapper}>
							{/* {thirdParty */}
							{true && (
								<div>
									{thirdParty && (
										<button
											className={styles.inventoryContentButton}
											onClick={() => setOpenForm(true)}
										>
											+ New Load
										</button>
									)}

									<SearchBar
										isCompact={true}
										onFormSubmit={onFormSubmit}
										updateSearchState={updateSearchState}
										onFormClean={onFormClean}
									/>
									{openForm && <NewLoadForm closeForm={closeForm} />}
								</div>
							)}
							<div className={styles.filterTitle}>
								<div className={styles.filterTitleBox}>
									<p>Filter By:</p>
								</div>
								<div className={styles.filterTitleLine}></div>
							</div>
							{!seoCategories && (
								<FilterCategories
									title="Categories"
									mapArray={categories}
									category={true}
									categoriesSearch={true}
									handleInventoryLoadCategoryChange={
										handleInventoryLoadCategoryChange
									}
									handleDeleteCategory={handleDeleteCategory}
									onFormSubmit={onFormSubmit}
								/>
							)}
							<FilterLoadCondition
								title="Load Condition"
								handleConditionChange={handleConditionChange}
								handleDeleteOfCondition={handleDeleteOfCondition}
								onFormSubmit={onFormSubmit}
							/>
							<FilterIdealUse
								title="Ideal Use"
								handleConditionChange={handleIdealUseChange}
								handleDeleteOfCondition={handleIdealUse}
								onFormSubmit={onFormSubmit}
							/>

							{/* Load Size Filter */}
							{/* <FilterLoadSize
								title="Load Size"
								handleSizeChange={handleSizeChange}
								handleDeleteOfSize={handleDeleteOfSize}
								onFormSubmit={onFormSubmit}
							/> */}

							{!seoRetailers && (
								<FilterRetailer
									title="Retailer"
									handleRetailerChange={handleRetailerChange}
									handleDeleteOfRetailer={handleDeleteOfRetailer}
									onFormSubmit={onFormSubmit}
								/>
							)}

							<FilterDeliveryType
								title="Delivery Type"
								handleChange={(value: string) => {
									updateSearchState({
										deliveryType: value,
									});
								}}
								handleValueDeleteState={() => {
									updateSearchState({
										deliveryType: "",
									});
								}}
								onFormSubmit={onFormSubmit}
							/>
							<FilterRegion
								title="Region"
								handleRegionAdd={handleRegionAdd}
								handleRegionDelete={handleRegionDelete}
								onFormSubmit={onFormSubmit}
							/>
							<FilterState
								title="State"
								handleStateAdd={handleStateAdd}
								handleValueDeleteState={handleStateDelete}
								onFormSubmit={onFormSubmit}
							/>
							<FilterMinValue
								onFormSubmit={onFormSubmit}
								minValue={minValue}
								handleChange={(value: number) => {
									updateSearchState({
										minValue: value,
									});
								}}
							/>
							<FilterCost
								onFormSubmit={onFormSubmit}
								onChangeFrom={(value: string) => {
									updateSearchState({
										currentPriceFrom: value,
									});
								}}
								onChangeTo={(value: string) => {
									updateSearchState({
										currentPriceTo: value,
									});
								}}
							/>
							<FilterRadio
								isFirstRadioChecked={searchState.availableForExport}
								title="Available For Export"
								onChangeValue={(value: boolean | undefined) => {
									updateSearchState({
										availableForExport: value,
									});
								}}
							/>
							<FilterRadio
								isFirstRadioChecked={searchState.canBeSoldByThirdParty}
								title="Available for Brokering"
								onChangeValue={(value: boolean | undefined) => {
									updateSearchState({
										canBeSoldByThirdParty: value,
									});
								}}
							/>
							{/* thirdParty */}
							{thirdParty ? (
								<FilterCheckboxes
									title="Listing Status"
									selectedValues={searchState.listingStatus}
									onChangeValue={listingStatusChangeSelection}
									values={[
										{
											title: "Draft",
											value: ListingStatusEnum.Draft,
										},
										{
											title: "Published",
											value: ListingStatusEnum.Active,
										},
										{
											title: "Sold",
											value: ListingStatusEnum.Sold,
										},
									]}
								/>
							) : null}
						</div>
						{!thirdParty ? (
							<div className={styles.cardsWrapper}>
								<AllItemsList
									searchParameters={searchParameters}
									setMinValue={setMinValue}
									rand={rand}
								/>
							</div>
						) : (
							<div className={styles.cardsWrapperThir}>
								{permissionGrantKey && (
									<ThirdPartyItemsList
										searchParameters={searchParameters}
										permissionGrantKey={permissionGrantKey}
										rand={rand}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</>
		</div>
	);
}

const getBooleanFromParameter = (
	router: NextRouter,
	parameterName: string,
): boolean | undefined => {
	const val = getParameterValue(router, parameterName);
	if (val === "true") {
		return true;
	} else if (val === "false") {
		return false;
	}
	return undefined;
};

const getArrayFromParameter = (
	router: NextRouter,
	parameterName: string,
): string[] => {
	const val = getParameterValue(router, parameterName);
	if (!val) {
		return [];
	}
	return typeof val === "string" ? [val] : val || [];
};

const getStringFromParameter = (
	router: NextRouter,
	parameterName: string,
): string => {
	const val = getParameterValue(router, parameterName);
	if (!val || typeof val !== "string") {
		return "";
	}
	return val;
};

function getNumberFromParameter(
	router: NextRouter,
	parameterName: string,
	defaultValue: number,
): number;
function getNumberFromParameter(
	router: NextRouter,
	parameterName: string,
): number | undefined;
function getNumberFromParameter(
	router: NextRouter,
	parameterName: string,
	defaultValue: number | undefined = undefined,
): number | undefined {
	const val = getParameterValue(router, parameterName);
	return parseInt(val as string) || defaultValue;
}

const getParameterValue = (
	router: NextRouter,
	parameterName: string,
): string | string[] | undefined => {
	const query = router.query;
	return query[parameterName];
};

export interface ISearchState {
	// maxRetailValue: number | undefined;

	minValue: number | undefined;
	currentPriceTo: string;
	currentPriceFrom: string;
	fullTextSearch: string;
	deliveryType: string;
	canBeSoldByThirdParty: boolean | undefined;
	availableForExport: boolean | undefined;
	size: string[];
	states: string[];
	regions: string[];
	category: string[];
	retailer: string[];
	loadCondition: string[];
	domainName: string;
	permissionGrantKey: string;
	listingStatus: ListingStatusEnum[];
	listingIdealUse: ListingIdealUseEnum[];
}

export interface IQueryParameters extends ISearchState {
	skip: number;
	take: number;
}

export const getParametersFromRouter = (
	router: NextRouter,
): IQueryParameters => {
	let itemsCounter = 9;
	if (router.pathname.includes("third-party")) itemsCounter = 5;

	const skip = getNumberFromParameter(router, "skip", 0);
	const take = getNumberFromParameter(router, "take", itemsCounter);
	const currentPriceFrom = getStringFromParameter(router, "currentPriceFrom");
	const currentPriceTo = getStringFromParameter(router, "currentPriceTo");
	// const maxRetailValue = getNumberFromParameter(router, "maxRetailValue");
	const minValue = getNumberFromParameter(router, "minValue", 0);

	const canBeSoldByThirdParty = getBooleanFromParameter(
		router,
		"canBeSoldByThirdParty",
	);
	const availableForExport = getBooleanFromParameter(
		router,
		"availableForExport",
	);

	const category = getArrayFromParameter(router, "category");
	const retailer = getArrayFromParameter(router, "retailer");
	const loadCondition = getArrayFromParameter(router, "loadCondition");
	const size = getArrayFromParameter(router, "size");
	const regions = getArrayFromParameter(router, "regions");
	const states = getArrayFromParameter(router, "states");
	const listingStatus = getArrayFromParameter(router, "listingStatus");
	const listingIdealUse = getArrayFromParameter(router, "listingIdealUse");

	const fullTextSearch = getStringFromParameter(router, "fullTextSearch");
	const deliveryType = getStringFromParameter(router, "deliveryType");
	const domainName = "";
	const permissionGrantKey = router.query.permissionGrantKey as string;

	return {
		skip,
		take,
		fullTextSearch,
		canBeSoldByThirdParty,
		availableForExport,
		deliveryType,
		currentPriceFrom,
		// maxRetailValue,
		minValue,
		size,
		states,
		regions,
		category,
		retailer,
		currentPriceTo,
		loadCondition,
		domainName,

		permissionGrantKey,
		listingStatus: listingStatus
			.filter((statusString) => {
				return Object.values(ListingStatusEnum).includes(
					statusString as ListingStatusEnum,
				);
			})
			.map((statusString: string) => {
				return statusString as ListingStatusEnum;
			}),

		listingIdealUse: listingIdealUse
			.filter((idealUseString) => {
				return Object.values(ListingIdealUseEnum).includes(
					idealUseString as ListingIdealUseEnum,
				);
			})
			.map((idealUseString: string) => {
				return idealUseString as ListingIdealUseEnum;
			}),
	};
};

export const parametersToSearchData = (
	parameters: IQueryParameters,
):
	| GetUserItemsQueryVariables["searchData"]
	| GetItemsListQueryVariables["searchData"] => {
	return {
		canBeSoldByThirdParty:
			typeof parameters.canBeSoldByThirdParty == "boolean"
				? parameters.canBeSoldByThirdParty
				: undefined,
		availableForExport:
			typeof parameters.availableForExport == "boolean"
				? parameters.availableForExport
				: undefined,
		currentPriceFrom: parameters.currentPriceFrom
			? parameters.currentPriceFrom.toString()
			: undefined,
		currentPriceTo: parameters.currentPriceTo
			? parameters.currentPriceTo.toString()
			: undefined,
		// maxRetailValue: parameters.maxRetailValue
		//  ? parameters.maxRetailValue.toString()
		//  : undefined,
		minValue: parameters.minValue ? parameters.minValue : undefined,
		deliveryType: parameters.deliveryType || undefined,
		fullTextSearch: parameters.fullTextSearch || undefined,
		skip: parameters.skip,
		take: parameters.take,
		inventoryLoadSize: parameters.size.length ? parameters.size : undefined,
		inventoryLoadCategory: parameters.category?.length
			? parameters.category
			: undefined,
		inventoryLoadCondition: parameters.loadCondition.length
			? parameters.loadCondition
			: undefined,
		region: parameters.regions.length ? parameters.regions : undefined,
		retailer: parameters.retailer.length ? parameters.retailer : undefined,
		state: parameters.states.length ? parameters.states : undefined,

		listingStatus: parameters.listingStatus.length
			? parameters.listingStatus
			: undefined,

		listingIdealUse: parameters.listingIdealUse.length
			? parameters.listingIdealUse
			: undefined,
	};
};
