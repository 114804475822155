import { DeliveryTypeEnum } from "..//..//graphql/types";

export const deliveryTypes = [
	{
		value: DeliveryTypeEnum.BuyerPickUpOnly,
		title: "Buyer Pickup Only",
		id: "BuyerPickUpOnly",
	},
	{
		value: DeliveryTypeEnum.IWillDeliver,
		title: "Seller Controls Freight",
		id: "IWillDeliver",
	},
	{
		value: DeliveryTypeEnum.IWillNotDeliver,
		title: "Buyer Controls Freight",
		id: "IWillNotDeliver",
	},
	{
		value: DeliveryTypeEnum.SupplierDeliver,
		title: "Seller Controls Freight",
		id: "SupplierDeliver",
	},
	{
		value: DeliveryTypeEnum.Either,
		title: "Either",
		id: "Either",
	},
];
