import React from "react";
import Input from "../input/input";
import styles from "./radio-group.module.scss";

interface Option {
	key?: string;
	text: string;
	value: string;
}

interface RadioGroupProps {
	// TODO: extend props - onChange name, cuurent value, selected...
	options: Option[];
	name: string;
	onChange?: any;
	value?: string;
	invalidFeedback?: string[];
}

function RadioGroup({
	options,
	name,
	onChange,
	value,
	invalidFeedback,
}: RadioGroupProps): JSX.Element {
	return (
		<div>
			<div className={styles.radioGroup}>
				{options.map((option) => (
					<Input
						key={option.text}
						id={(name + option.value) as string}
						value={option.value}
						checked={value === option.value}
						name={name}
						label={option.text}
						type="radio"
						direction="row"
						labelFirst={false}
						onChange={onChange}
					/>
				))}
			</div>
			{invalidFeedback?.length ? (
				<div
					className={styles.invalidFeedback}
					style={{ marginTop: "-1.25rem", marginBottom: "1.25rem" }}
				>
					{invalidFeedback?.join(" ")}
				</div>
			) : null}
		</div>
	);
}

export default RadioGroup;
