import React, { ReactNode, useContext, useEffect, useState } from "react";
import styles from "./filter-modal-categories.module.scss";
import { useScrollLock } from "../../../hooks/useScroll";
import { StaticContext } from "../../../context/static-context/static-context";
import BadgeMobile from "../../common/badge-mobile/badge-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars-2";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FilterMobileCategorieChildrens from "../../filter-mobile/filter-mobile-categories-children";
import IconComponent from "../../common/icon/icon";
import { IconData } from "../../common/icon/icon-data";

interface FilterModalCategoriesProps {
	checkedToggleState?: any;
	statesOpen?: any;
	addArray?: any;
	deleteArray?: any;
	array?: any;
	onFormSubmit?: any;
}

export default function FilterModalCategories({
	checkedToggleState,
	statesOpen,
	addArray,
	deleteArray,
	array,
	onFormSubmit,
}: FilterModalCategoriesProps): JSX.Element {
	const { lockScroll, unlockScroll } = useScrollLock();
	const contextData = useContext(StaticContext);

	const renderCategories = () =>
		contextData.categories
			.filter((cat) => !cat.retailerId?.length)
			.filter((c) => contextData.categoryIsParent(c.id))
			.filter((c) => c.id !== "2bb9dedc-17de-4092-b60b-f023216f8d03");

	useEffect(() => {
		statesOpen ? lockScroll() : unlockScroll();
	}, [statesOpen, lockScroll, unlockScroll]);

	const Name = (id: any) => {
		let result = [...contextData.categories];
		let name = result.filter((ele) => ele.id === id)[0];
		if (name) {
			return name.title;
		} else {
			return "N/A";
		}
	};

	const Name2 = (id: any) => {
		let result = [...contextData.retailers];
		let name = result.filter((ele) => ele.id === id)[0];
		if (name) {
			return name.name;
		} else {
			return "N/A";
		}
	};

	let result = [...contextData.categories];
	result.map((ele: any) => {
		ele.retailerName = Name2(ele.retailerId);
		ele.parentName = Name(ele.parent?.id);
	});

	return (
		<div className={styles.statesWindowsConteiner}>
			<div className={styles.statesWindowsModal}>
				<div className={styles.statesWindowsModalHeader}>
					<p>Select Categories</p>
					<div onClick={() => (checkedToggleState(), unlockScroll())}>
						<FontAwesomeIcon className={styles.closeBtn} icon={faTimes} />
					</div>
				</div>
				<Scrollbars>
					<div>
						{renderCategories().map((ele) => {
							return (
								<div key={ele.id}>
									<AcordionMy arreyLength={array} id={ele.id} ele={ele}>
										<AccordionSummary
											expandIcon={<div className={styles.arrow}></div>}
											id={ele.id}
											className={styles.titleContainer}
										>
											<div>
												<div className={styles.title}>{ele.title}</div>
											</div>
										</AccordionSummary>
										<AccordionDetails>
											<FilterMobileCategorieChildrens
												addArray={addArray}
												deleteArray={deleteArray}
												array={array}
												ele={ele}
											/>
										</AccordionDetails>
									</AcordionMy>
								</div>
							);
						})}
					</div>
				</Scrollbars>
				<div
					className={styles.statesWindowsModalFooter}
					onClick={() => (checkedToggleState(), unlockScroll())}
				>
					<div onClick={onFormSubmit} className={styles.onFormSubmit}>
						<IconComponent
							className={styles.iconSearch}
							name={IconData.SEARCH}
						/>
						<div>Show Results</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function AcordionMy({
	children,
	arreyLength,
	id,
	ele,
}: any): JSX.Element {
	const checked = arreyLength.filter((ele: any) => ele?.parent.id === id);
	const allName = arreyLength.map((ele: any) => (ele = ele?.title));
	const parentCategories = allName.join(" ").includes(ele?.title);

	return (
		<>
			<Accordion
				style={{ boxShadow: "none" }}
				defaultExpanded={!!checked.length || parentCategories}
			>
				{children}
			</Accordion>
		</>
	);
}
