import React, { useEffect, useState, useContext } from "react";
import styles from "./search-bar.module.scss";
import useWindowSize from "../../hooks/useWindowSize";
import Button from "../common/button/button";
import { StaticContext } from "../../context/static-context/static-context";
import { useRouter, NextRouter } from "next/router";

interface SearchBarSmallDoubleProps {
	isCompact?: boolean;
	values?: any;
	onChangeValue?: any;
	max?: any;
	min?: any;
	onFormSubmit?: any;
	onChangeFrom?: any;
	onChangeTo?: any;
}

function SearchBarSmallDouble({
	isCompact = false,
	values,
	onChangeValue,
	max,
	min,
	onFormSubmit,
	onChangeFrom,
}: SearchBarSmallDoubleProps): JSX.Element {
	const [stateValueFirst, setStateValueFirst] = useState<any>();
	const [stateValueSecond, setStateValueSecond] = useState<any>();
	const [isMobile, setIsMobile] = useState(false);
	const { width } = useWindowSize();

	useEffect(() => {
		setStateValueFirst(values[0]);
		setStateValueSecond(values[1]);
	}, [values]);

	const firstArgument = (e: any) => {
		if (e.target.value > max) return;
		if (e.target.value < 0) return;
		setStateValueFirst(e.target.value);
		onChangeValue(e.target.value, stateValueSecond);
	};

	const secondArgument = (e: any) => {
		if (e.target.value > max) return;
		if (e.target.value < 0) return;
		setStateValueSecond(e.target.value);
		onChangeValue(stateValueFirst, e.target.value);
	};

	const handleKeyPress = (event: React.KeyboardEvent): any => {
		if (event.key === "Enter") {
			onFormSubmit(event);
		}
	};

	return (
		<>
			{!isCompact ? (
				<div className={styles.searchBarSmallContainer}>
					<div className={styles.searchBarSmallDouble}>
						<input
							className={styles.input}
							type="number"
							value={values[0]}
							onKeyPress={handleKeyPress}
							onChange={(e) => firstArgument(e)}
							inputMode="numeric"
							pattern="[0-9]*"
							placeholder={"Cost From"}
						/>
					</div>

					<div className={styles.searchBarSmallDouble}>
						<input
							className={styles.input}
							type="number"
							value={values[1]}
							onKeyPress={handleKeyPress}
							inputMode="numeric"
							pattern="[0-9]*"
							onChange={(e) => secondArgument(e)}
							placeholder={"Cost To"}
						/>
					</div>
				</div>
			) : (
				<div className={styles.searchBarCompact}>
					<input type="number" placeholder="" />
				</div>
			)}
		</>
	);
}

export default SearchBarSmallDouble;
