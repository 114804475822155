import { DeliveryTypeEnum, ListingIdealUseEnum } from "../../../graphql/types";

export const TRUCK_LOAD_INITIAL_FORM_VALUES = {
	category: "",
	conditon: "",
	cost: "",
	count: "",
	depth: "",
	description: "",
	discount: "",
	images: [],
	loadRetailValue: "",
	loadSize: "truck",
	manifest: {},
	orientation: "",
	quantity: "",
	retailer: "",
	sellingRestrictions: "",
	title: "",
	wholesaleValue: "",
	width: "",
};

export const BOX_LOAD_INITIAL_FORM_VALUES = {
	category: "",
	conditon: "",
	cost: "",
	description: "",
	discount: "",
	height: "",
	images: [],
	length: "",
	loadRetailValue: "",
	loadSize: "box",
	manifest: {},
	quantity: "",
	retailer: "",
	sellingRestrictions: "",
	title: "",
	weight: "",
	wholesaleValue: "",
	width: "",
};

export const PALLET_LOAD_INITIAL_FORM_VALUES = {
	category: "",
	conditon: "",
	cost: "",
	description: "",
	discount: "",
	height: "",
	images: [],
	length: "",
	loadRetailValue: "",
	loadSize: "pallet",
	manifest: {},
	quantity: "",
	retailer: "",
	sellingRestrictions: "",
	title: "",
	weight: "",
	wholesaleValue: "",
	width: "",
};

export const initialValues = {
	truck: TRUCK_LOAD_INITIAL_FORM_VALUES,
	box: BOX_LOAD_INITIAL_FORM_VALUES,
	pallet: PALLET_LOAD_INITIAL_FORM_VALUES,
};

export const dimensionsTitle = {
	SIZE_TRUCKLOAD: "Count and Piece Count",
	SIZE_BOX_LOT: "Box Lot Dimensions",
	SIZE_PALLET: "Pallet Dimensions",
};

export const loadSizeOptions = [
	{ text: "Truck Load", value: "SIZE_TRUCKLOAD" },
	{ text: "Box Lot", value: "SIZE_BOX_LOT" },
	{ text: "Pallet", value: "SIZE_PALLET" },
];

export const uploadManifestOptions = [
	{ text: "Yes", value: "yes" },
	{ text: "No", value: "no" },
];

export const availableForExportOptions = [
	{ text: "Available for Export", value: "true", checked: false },
];

export const deliveryTypeOptions = [
	{ key: DeliveryTypeEnum.BuyerPickUpOnly, value: "Buyer Pickup Only" },
	{ key: DeliveryTypeEnum.IWillDeliver, value: "Seller Controls Freight" },
	{ key: DeliveryTypeEnum.IWillNotDeliver, value: "Buyer Controls Freight" },
	{ key: DeliveryTypeEnum.Either, value: "Either" },
];

export const palletHeightAverageEnum = [
	{ key: "", value: "none" },
	{ key: "1-3ft", value: "1-3ft" },
	{ key: "4-5ft", value: "4-5ft" },
	{ key: "5-6ft", value: "5-6ft" },
	{ key: "6-7ft", value: "6-7ft" },
	{ key: "7-8ft", value: "7-8ft" },
];
