import React, { useState } from "react";
import { omit } from "lodash";

interface Validation {
	errors: Record<string, string> | {};
	validate: (name: string, value: string, inputType: string) => void;
}

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TEL_REG = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

export const UseValidation = (): Validation => {
	const [errors, setErrors] = useState({});

	const validate = (name: string, value: string, inputType: string) => {
		switch (inputType) {
			case "text":
				if (value.length <= 1) {
					setErrors({
						...errors,
						[name]: "The value is too short",
					});
				} else {
					let newObj = omit(errors, name);
					setErrors(newObj);
				}
				break;

			case "textarea":
				if (value.length <= 1) {
					setErrors({
						...errors,
						[name]: "The value is too short",
					});
				} else {
					let newObj = omit(errors, name);
					setErrors(newObj);
				}
				break;

			case "email":
				if (!new RegExp(EMAIL_REG).test(value)) {
					setErrors({
						...errors,
						[name]: "Enter a valid email address",
					});
				} else {
					let newObj = omit(errors, name);
					setErrors(newObj);
				}
				break;

			case "tel":
				if (!new RegExp(TEL_REG).test(value)) {
					setErrors({
						...errors,
						[name]: "Enter a phone number in valid format",
					});
				} else {
					let newObj = omit(errors, name);
					setErrors(newObj);
				}
				break;

			default:
				// if (value.toString().trim() === "") {
				// 	setErrors({
				// 		...errors,
				// 		[name]: "The value is empty",
				// 	});
				// }
				break;
		}
	};

	return {
		errors,
		validate,
	};
};
