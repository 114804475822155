import React, { useEffect, useState, useContext } from "react";
import styles from ".././filter-item.module.scss";
import Badge from "../../common/badge/badge";
import { StaticContext } from "../../../context/static-context/static-context";
import { useRouter } from "next/router";

import nextId from "react-id-generator";
import FilterModalState from "./filter-modal-state";
import { getParametersFromRouter } from "../../../../pages/inventory/InventoryPage";

export interface FilterStateProps extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	handleStateAdd?: (value: string) => void;
	handleValueDeleteState?: (value: string) => void;
	onFormSubmit?: any;
}

function FilterState({
	title,
	handleStateAdd,
	handleValueDeleteState,
	onFormSubmit,
}: FilterStateProps) {
	const [searchArray, setSearchArray] = useState<string[]>([]);
	const [statesOpen, setStatesOpen] = useState<boolean>(false);

	const contextData = useContext(StaticContext);
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	const addArray = (e: any) => {
		handleStateAdd && handleStateAdd(e.abbreviation);
		const arrayID = searchArray.map((ele: any) => (ele = ele.id));
		if (arrayID.includes(e.id)) return;
		setSearchArray((prev) => [...prev, e]);
	};

	const deleteArray = (e: any) => {
		handleValueDeleteState && handleValueDeleteState(e.abbreviation);
		let filter = searchArray.filter((ele: any) => ele.id !== e.id);
		setSearchArray(filter);
	};

	const chekedToggelState = () => {
		setStatesOpen(false);
	};

	useEffect(() => {
		let result: any = [];
		for (let i in queryParameters.states) {
			let target = contextData.states.filter(
				(ele) => ele.abbreviation === queryParameters.states[i],
			);
			result.push(target[0]);
		}

		setSearchArray(result);
	}, [router, contextData.states]);

	return (
		<>
			<div className={styles.filterItem}>
				<div className={styles.title} onClick={() => setStatesOpen(true)}>
					{title}
				</div>

				<div className={styles.chekedCategoti}>
					{searchArray.map((ele: any) => (
						<Badge
							key={nextId()}
							title={ele?.name || ""}
							selfClosing={true}
							onClick={() => deleteArray(ele)}
						/>
					))}
				</div>

				{statesOpen && (
					<FilterModalState
						chekedToggelState={chekedToggelState}
						statesOpen={statesOpen}
						addArrayState={addArray}
						deleteArrayState={deleteArray}
						arrayState={searchArray}
						onFormSubmit={onFormSubmit}
					/>
				)}
			</div>
		</>
	);
}

export default FilterState;
