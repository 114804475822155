import React, { ReactNode } from "react";
import Underlayer from "../underlayer/underlayer";
import styles from "./popup.module.scss";

interface PopupProps {
	children: ReactNode;
	className?: string;
	isScrollLock?: boolean;
}

export default function Popup({
	children,
	className,
	isScrollLock,
}: PopupProps): JSX.Element {
	return (
		<div className={styles.popup}>
			<Underlayer
				isScrollLock={isScrollLock}
				className={styles.popupUnderlayer}
				isHidden={false}
			/>
			<div className={`${styles.popupContent} ${className ? className : ""}`}>
				{children}
			</div>
		</div>
	);
}
