import React, { ReactNode } from "react";
import styles from "./form-group.module.scss";
import Divider from "../../divider/divider";

interface FormGroupProps {
	children: ReactNode;
	title?: string;
	singleColumn?: boolean;
	className?: string;
	childrenClassName?: string;
}

function FormGroup({
	title,
	children,
	singleColumn = false,
	className,
	childrenClassName,
}: FormGroupProps): JSX.Element {
	return (
		<div className={`${styles.formGroup} ${className ? className : ""}`}>
			{title && (
				<div className={styles.titleWrapepr}>
					<h4>{title}</h4>
					<Divider className={styles.divider} />
				</div>
			)}
			<div
				className={`${childrenClassName ? childrenClassName : ""} ${
					singleColumn ? styles.singleColumnChildren : ""
				}`}
			>
				{children}
			</div>
		</div>
	);
}

export default FormGroup;
