import React, { useContext, useEffect, useState } from "react";
import styles from "./badge.module.scss";
import { StaticContext } from "../../../context/static-context/static-context";

export enum BadgeColour {
	RED = "red",
	BLUE = "blue",
	LIGHT = "light",
	GREY = "grey",
	GREEN = "green",
	YELLOW = "yellow",
	DARK_GREY = "darkGrey",
}

export interface BadgeProps {
	title: string;
	selfClosing?: boolean;
	color?: BadgeColour;
	className?: string;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	ele?: any;
}

function Badge({
	title,
	className,
	color = BadgeColour.BLUE,
	selfClosing = false,
	onClick,
	ele,
}: BadgeProps): JSX.Element {
	const contextData = useContext(StaticContext);
	const [titleEle, setTitleEle] = useState<string>("");

	const nameCategoriesSpecifik = (id: any) => {
		const result = contextData.retailers.filter((ele) => ele.id === id);
		return result[0]?.name;
	};

	useEffect(() => {
		if (ele?.parent.id === "0e46c6e5-b0cc-4cfe-b3ff-08e7b9af7aa3") {
			setTitleEle("All");
		}
	}, [ele]);

	return (
		<div
			className={`
      ${styles.badgeBase}
      ${className ? className : ""}
      ${color === BadgeColour.BLUE ? styles.badgeBlue : ""}
      ${color === BadgeColour.RED ? styles.badgeRed : ""}
      ${color === BadgeColour.LIGHT ? styles.badgeLight : ""}
      ${color === BadgeColour.GREY ? styles.badgeGrey : ""}
      ${color === BadgeColour.DARK_GREY ? styles.badgeDarkGrey : ""}
      ${color === BadgeColour.GREEN ? styles.badgeGreen : ""}
      ${color === BadgeColour.YELLOW ? styles.badgeYellow : ""}
      ${selfClosing ? styles.selfClosing : ""}
    `}
		>
			{selfClosing && (
				<div className={styles.closeBadge} onClick={onClick}></div>
			)}
			{`${titleEle} ${title || "Categories"}`}
		</div>
	);
}

export default Badge;
