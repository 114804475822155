import React, { ReactNode, useContext, useEffect, useState } from "react";
import styles from "./filter-modal-condition.module.scss";
import { useScrollLock } from "../../../hooks/useScroll";
import { StaticContext } from "../../../context/static-context/static-context";
import BadgeMobile from "../../common/badge-mobile/badge-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IconComponent from "../../common/icon/icon";
import { IconData } from "../../common/icon/icon-data";

interface FilterConditionProps {
	chekedToggelState?: any;
	statesOpen?: any;
	addArrayState?: any;
	deleteArrayState?: any;
	arrayState?: any;
	onFormSubmit?: any;
}

export default function FilterModalCondition({
	chekedToggelState,
	statesOpen,
	addArrayState,
	deleteArrayState,
	arrayState,
	onFormSubmit,
}: FilterConditionProps): JSX.Element {
	const { lockScroll, unlockScroll } = useScrollLock();
	const [searchArrayFilter, setSearchArrayFilter] = useState<any[]>([]);
	const contextData = useContext(StaticContext);

	useEffect(() => {
		let result: any = [];
		result = contextData.vocabularyEntries.filter(
			(ele) => ele.parent?.id === "74bde057-97f0-40c7-9718-ccd970b8337d",
		);

		setSearchArrayFilter(result);
	}, [contextData]);

	useEffect(() => {
		statesOpen ? lockScroll() : unlockScroll();
	}, [statesOpen, lockScroll, unlockScroll]);

	const addArray = (e: any) => {
		const arrayID = arrayState.map((ele: any) => (ele = ele.id));
		if (arrayID.includes(e.id)) return;
		addArrayState(e);
	};

	const deleteArray = (e: any) => {
		deleteArrayState(e);
	};

	const listCondition: any = [
		searchArrayFilter[3],
		searchArrayFilter[4],
		searchArrayFilter[5],
		searchArrayFilter[0],
		searchArrayFilter[2],
		searchArrayFilter[6],
		searchArrayFilter[7],
		searchArrayFilter[1],
	];

	return (
		<div className={styles.statesWindowsConteiner}>
			<div className={styles.statesWindowsModal}>
				<div className={styles.statesWindowsModalHeader}>
					<p>Select Load Condition</p>
					<div onClick={() => (chekedToggelState(), unlockScroll())}>
						<FontAwesomeIcon className={styles.closeBtn} icon={faTimes} />
					</div>
				</div>
				<div className={styles.badgeContainer}>
					{listCondition.map((ele: any) => (
						<BadgeMobile
							key={ele?.id}
							title={`${ele?.name || ele?.title}`}
							isVisible={true}
							addArray={addArray}
							deleteArray={deleteArray}
							ele={ele}
							id={ele?.id}
							array={arrayState}
						/>
					))}
				</div>
				<div
					className={styles.statesWindowsModalFooter}
					onClick={() => (chekedToggelState(), unlockScroll())}
				>
					<div onClick={onFormSubmit} className={styles.onFormSubmit}>
						<IconComponent
							className={styles.iconSearch}
							name={IconData.SEARCH}
						/>
						<div>Show Results</div>
					</div>
				</div>
			</div>
		</div>
	);
}
