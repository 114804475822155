import React, { useEffect, useState } from "react";
import styles from "./filter-minValue.module.scss";
import { Range, getTrackBackground } from "react-range";
import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../pages/inventory/InventoryPage";
import SearchBarSmall from "../search-bar/search-bar-small";
import Divider from "../common/divider/divider";

export interface FilterMinValueProps
	extends React.HTMLAttributes<HTMLDivElement> {
	minValue?: number;
	handleChange?: any;
	onFormSubmit?: any;
}

function FilterMinValue({
	minValue,
	handleChange,
	onFormSubmit,
}: FilterMinValueProps) {
	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);
	const [values, setValues] = useState<any>([
		queryParameters.minValue || undefined,
	]);

	let STEP = 1;
	let MIN = 0;
	let MAX = 1_000_000;

	const logStep = 9 / ((MAX - MIN) / STEP);

	// useEffect(() => {
	// 	const logStep = 9 / ((MAX - MIN) / STEP);
	// 	console.log(logStep)

	// 	STEP = values[0] / 10

	// 	console.log(STEP, 'step')
	// }, [values[0]]);

	useEffect(() => {
		handleChange(values[0]);
	}, [values]);

	useEffect(() => {
		setValues([queryParameters.minValue || MIN]);
	}, [queryParameters.minValue]);

	const onChangeValue = (e: number) => {
		setValues([e]);
		// onFormSubmit()
	};

	return (
		<>
			<div className={styles.conteiner}>
				<div className={styles.title}>Minimum Value</div>
				<Range
					values={values}
					step={STEP}
					min={MIN}
					max={MAX}
					onChange={(values) => setValues(values)}
					renderTrack={({ props, children }) => (
						<div className={styles.button}>
							<div
								ref={props.ref}
								style={{
									height: "3px",
									width: "100%",
									borderRadius: "4px",
									background: getTrackBackground({
										values: values,
										colors: ["#4b9ad4", "#ccc"],
										min: MIN,
										max: MAX,
									}),
									alignSelf: "center",
								}}
							>
								{children}
							</div>
						</div>
					)}
					renderThumb={({ props, isDragged }) => (
						<div
							{...props}
							style={{
								height: "1.5rem",
								width: "1.5rem",
								borderRadius: "50%",
								backgroundColor: "#FFF",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div
								style={{
									height: "1.5rem",
									width: "1.5rem",
									borderRadius: "50%",
									backgroundColor: isDragged ? "#4b9ad4" : "#CCC",
								}}
							/>
						</div>
					)}
				/>
				<output className={styles.number} id="output">
					{values[0]}
				</output>
				<SearchBarSmall
					value={values[0]}
					onChangeValue={onChangeValue}
					onFormSubmit={onFormSubmit}
					max={MAX}
				/>
			</div>
		</>
	);
}

export default FilterMinValue;
