import React, { Component } from "react";
import Button from "../button/button";
import IconComponent from "../icon/icon";
import { IconData } from "../icon/icon-data";
import Modal from "./Modal";
import Skeleton from "@mui/material/Skeleton";

type ImagesProps = {
	images: string[];
	hideOverlay: boolean;
	renderOverlay: () => string;
	overlayBackgroundColor: string;
	onClickEach: ((_: { src: string; index: number }) => void) | null;
	countFrom: number;
};

type ImagesState = {
	modal: boolean;
	countFrom: number;
	conditionalRender: boolean;
	url: string;
	index: number;
};

class Images extends Component<ImagesProps, ImagesState> {
	static defaultProps = {
		images: [],
		hideOverlay: false,
		renderOverlay: () => "Preview Image",
		overlayBackgroundColor: "#222222",
		onClickEach: null,
		countFrom: 5,
	};

	constructor(props: ImagesProps) {
		super(props);

		this.state = {
			modal: false,
			countFrom:
				props.countFrom > 0 && props.countFrom < 5 ? props.countFrom : 5,
			conditionalRender: false,
			url: props.images[0],
			index: 0,
		};

		this.openModal = this.openModal.bind(this);
		this.onClose = this.onClose.bind(this);

		if (props.countFrom <= 0 || props.countFrom > 5) {
			console.warn("countFrom is limited to 5!");
		}
	}

	openModal(index: number) {
		const { onClickEach, images } = this.props;

		if (onClickEach) {
			return onClickEach({ src: images[index], index });
		}

		this.setState({ modal: true, url: images[index], index });
	}

	onClose() {
		this.setState({ modal: false });
	}

	renderOne() {
		const { images } = this.props;

		const { countFrom } = this.state;

		const result0 = images[0].split(" ");

		const imgUrl0 = result0[0] + "";

		const overlay =
			images.length > countFrom && countFrom == 1
				? this.renderCountOverlay(true)
				: this.renderOverlay();

		return (
			<div className="containerImg">
				{images.length ? (
					<>
						<div className="row">
							<div
								className={`col-xs-12 col-md-12 border height-one background`}
								onClick={this.openModal.bind(this, 0)}
								style={{ background: `url(${imgUrl0})` }}
							>
								{result0[1] === "false" && (
									<Button className="infoBtn">
										<IconComponent name={IconData.INFO} />
										<div className="tooltiptext tooltiptext-One">
											<div>
												This is a sample image from a comparable truckload.
											</div>
										</div>
									</Button>
								)}
								{overlay}
							</div>
						</div>
					</>
				) : (
					<>
						<Skeleton variant="rectangular" height={300} />
					</>
				)}
			</div>
		);
	}

	renderTwo() {
		const { images } = this.props;
		const { countFrom } = this.state;

		const result0 = images[0].split(" ");
		const result1 = images[1].split(" ");
		let result2: any = "";

		const imgUrl0 = result0[0] + "";
		const imgUrl1 = result1[0] + "";
		let imgUrl2;

		if (images.length === 3) {
			result2 = images[2].split(" ");
			imgUrl2 = result2[0] + "";
		}

		const overlay =
			images.length > countFrom && [2, 3].includes(+countFrom)
				? this.renderCountOverlay(true)
				: this.renderOverlay();
		const conditionalRender =
			[3, 4].includes(images.length) ||
			(images.length > +countFrom && [3, 4].includes(+countFrom));

		return (
			<div className="containerImg">
				<div className="row">
					{images.length ? (
						<>
							<div
								className="col-xs-6 col-md-6 border height-two background"
								onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
								style={{
									background: `url(${conditionalRender ? imgUrl1 : imgUrl0})`,
								}}
							>
								{result0[1] === "false" && (
									<Button className="infoBtn infoBtn-two">
										<IconComponent name={IconData.INFO} />
										<div className="tooltiptext tooltiptext-two">
											<div>
												This is a sample image from a comparable truckload.
											</div>
										</div>
									</Button>
								)}
								{this.renderOverlay()}
							</div>
						</>
					) : (
						<>
							<Skeleton variant="rectangular" height={100} />
						</>
					)}
					{images.length ? (
						<>
							<div
								className="col-xs-6 col-md-6 border height-two background"
								onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
								style={{
									background: `url(${conditionalRender ? imgUrl2 : imgUrl1})`,
								}}
							>
								{result1[1] === "false" && (
									<Button className="infoBtn infoBtn-two">
										<IconComponent name={IconData.INFO} />
										<div className="tooltiptext tooltiptext-two">
											<div>
												This is a sample image from a comparable truckload.
											</div>
										</div>
									</Button>
								)}
								{overlay}
							</div>
						</>
					) : (
						<>
							<Skeleton variant="rectangular" height={100} />
						</>
					)}
				</div>
			</div>
		);
	}

	renderThree() {
		const { images } = this.props;
		const { countFrom } = this.state;

		const result1 = images[0].split(" ");
		const result2 = images[1].split(" ");
		const result3 = images[2].split(" ");
		const result4 = images[3].split(" ");
		let result5: any = "";

		const imgUrl1 = result1[0] + "";
		const imgUrl2 = result2[0] + "";
		const imgUrl3 = result3[0] + "";
		const imgUrl4 = result4[0] + "";
		let imgUrl5;

		if (images.length === 5) {
			result5 = images[4].split(" ");
			imgUrl5 = result5[0] + "";
		}

		if (images.length === 4) {
			result5 = images[3].split(" ");
		}

		const conditionalRender =
			images.length == 4 || (images.length > +countFrom && +countFrom == 4);
		const overlay =
			!countFrom ||
			countFrom > 5 ||
			(images.length > countFrom && [4, 5].includes(+countFrom))
				? this.renderCountOverlay(true)
				: this.renderOverlay(conditionalRender ? 3 : 4);

		return (
			<div className="containerImg">
				<div className="row">
					<div
						className="col-xs-6 col-md-4 border height-three background"
						onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
						style={{
							background: `url(${conditionalRender ? imgUrl2 : imgUrl3})`,
						}}
					>
						{/* {result3[1] === "false" && (
							<Button className="infoBtn">
								<IconComponent name={IconData.INFO} />
							</Button>
						)} */}
						{result3[1] === "false" && (
							<Button className="infoBtn infoBtn-three">
								<IconComponent name={IconData.INFO} />
								<div className="tooltiptext tooltiptext-three">
									<div>This is a sample image from a comparable truckload.</div>
								</div>
							</Button>
						)}
						{this.renderOverlay(conditionalRender ? 1 : 2)}
					</div>
					<div
						className="col-xs-6 col-md-4 border height-three background"
						onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
						style={{
							background: `url(${conditionalRender ? imgUrl3 : imgUrl4})`,
						}}
					>
						{result4[1] === "false" && (
							<Button className="infoBtn infoBtn-three">
								<IconComponent name={IconData.INFO} />
								<div className="tooltiptext tooltiptext-three">
									<div>This is a sample image from a comparable truckload.</div>
								</div>
							</Button>
						)}
						{this.renderOverlay(conditionalRender ? 2 : 3)}
					</div>
					<div
						className="col-xs-6 col-md-4 border height-three background"
						onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
						style={{
							background: `url(${conditionalRender ? imgUrl4 : imgUrl5})`,
						}}
					>
						{result5[1] === "false" && (
							<Button className="infoBtn infoBtn-three">
								<IconComponent name={IconData.INFO} />
								<div className="tooltiptext tooltiptext-three ">
									<div>This is a sample image from a comparable truckload.</div>
								</div>
							</Button>
						)}
						{overlay}
					</div>
				</div>
			</div>
		);
	}

	renderOverlay(id?: number) {
		const { hideOverlay, renderOverlay, overlayBackgroundColor } = this.props;

		if (hideOverlay) {
			return false;
		}

		return [
			<div
				key={`cover-${id}`}
				className="cover slide"
				style={{ backgroundColor: overlayBackgroundColor }}
			/>,
			<div
				key={`cover-text-${id}`}
				className="cover-text slide animate-text"
				style={{ fontSize: "100%" }}
			>
				{renderOverlay()}
			</div>,
		];
	}

	renderCountOverlay(more: boolean) {
		const { images } = this.props;
		const { countFrom } = this.state;
		const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);

		return [
			more && <div key="count" className="cover"></div>,
			more && (
				<div
					key="count-sub"
					className="cover-text"
					style={{ fontSize: "200%" }}
				>
					<p>+{extra}</p>
				</div>
			),
		];
	}

	render() {
		const { modal, index, countFrom } = this.state;
		const { images } = this.props;
		const imagesToShow = [...images];

		if (countFrom && images.length > countFrom) {
			imagesToShow.length = countFrom;
		}

		return (
			<div className="fb-image-grid">
				<div className="grid-containerImg">
					{[1, 3, 4].includes(imagesToShow.length) && this.renderOne()}
					{imagesToShow.length >= 2 &&
						imagesToShow.length != 4 &&
						this.renderTwo()}
					{imagesToShow.length >= 4 && this.renderThree()}

					{modal && (
						<Modal onClose={this.onClose} index={index} images={images} />
					)}
				</div>
			</div>
		);
	}
}

export default Images;
