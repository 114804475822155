import React, { ReactNode } from "react";
import styles from "./form-field.module.scss";
import InputProps from "../input/input";

interface FormFieldProps {
	children: ReactNode;
	siblings?: ReactNode;
	id?: string;
	label?: string;
	invalidFeedback?: string[];
	className?: string;
	direction?: "row" | "column";
	labelFirst?: boolean;
	type?: string;
}

function FormField({
	children,
	id,
	label,
	invalidFeedback,
	className,
	type,
	direction = "column",
	labelFirst,
	siblings,
}: FormFieldProps) {
	const directionStyles =
		direction === "column" ? styles.columnDirection : styles.rowDirection;

	const labelPosition = labelFirst
		? styles.labelOrderFirst
		: styles.labelOrderLast;

	return (
		<div
			className={`${styles.formField} ${
				className ? className : ""
			} ${directionStyles} ${labelPosition}`}
		>
			{children}
			{siblings}
			{label && (
				<label className={`${styles.label}`} htmlFor={id}>
					{label}
				</label>
			)}
			{invalidFeedback?.length ? (
				<div className={styles.invalidFeedback}>
					{invalidFeedback?.join(" ")}
				</div>
			) : null}
		</div>
	);
}

export default FormField;
